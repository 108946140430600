import React, { useEffect,useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";
import moment from "moment";
export default function ListadoPedidos() {
  const {loadPedidos,pedidosStore} = useStore();
  const data = useMemo(() => pedidosStore, [pedidosStore]);

  const columns = useMemo(
    () => [
      { Header: "Id", accessor: "id" },
      { Header: "Nombre proveedor", accessor: "proveedor.nombre" },
      { Header: "Fecha del evento", accessor: d => {
        return moment(d.fecha?.toDate())
          .local()
          .format("DD-MM-YYYY")
      } },
      { Header: "Fecha del pedido", accessor: d => {
        return moment(d.fecha?.toDate())
          .local()
          .format("DD-MM-YYYY")
      } },
      { Header: "Tipo de evento", accessor: "tipoBoda.nombre" },
      { Header: "Comensales", accessor: "comensales" },
      { Header: "Total", accessor: d => {
        return `${d.total?.toFixed(2).toString().replace('.', ',')} €`
      } },
    ],
    []
  );
  useEffect(() => {
    loadPedidos()
  }, []);
  return <Table columns={columns} data={data} />;
}
