import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useStore } from "../../store/appStore";
import { Table } from "../table";


export default function ListadoTrabajadores() {
  const {loadTrabajadores,trabajadoresStore} = useStore();
  const data = useMemo(() => trabajadoresStore, [trabajadoresStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "CIF/DNI", accessor: "cifDni" },
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Telefono", accessor: "telefono" },
      { Header: "E-Mail", accessor: "mail" },
      { Header: "Acceso", accessor: "tieneAcceso"}
    ],
    []
  );
  useEffect(() => {
    loadTrabajadores()
  }, []);
  return <Table columns={columns} data={data} />;
}
