import React, { useEffect, useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";

export default function ListadoProveedores() {
  const { loadProveedores, proveedoresStore } = useStore();

  const data = useMemo(() => proveedoresStore, [proveedoresStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "CIF/DNI", accessor: "cifDni" },
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Nombre comercial", accessor: "nombreComercial" },
      { Header: "Proveedor", accessor: "proveedor" },
      { Header: "Telefono", accessor: "telefono" },
      { Header: "E-Mail", accessor: "mail" },
      { Header: "Tipo de proveedor", accessor: "tipo.nombre" },
    ],
    []
  );
  useEffect(() => {
    loadProveedores();
  }, []);
  return <Table columns={columns} data={data} />;
}
