import React, { useEffect, useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";
import moment from "moment";
export default function ListadoEventos() {
  const { loadEventos, eventosStore } = useStore();
  const data = useMemo(() => eventosStore, [eventosStore]);

  const columns = useMemo(
    () => [
      { Header: "Id", accessor: "id" },
      { Header: "Nombre evento", accessor: "nombre" },
      {
        Header: "Fecha del evento",
        accessor: (d) => {
          return moment(d.fecha?.toDate()).local().format("DD-MM-YYYY");
        },
        sortType: (rowA, rowB, columnId, desc) => {
          const dateA = moment(rowA.original.fecha?.toDate()).utc();
          const dateB = moment(rowB.original.fecha?.toDate()).utc();
    
          if (dateA.year() !== dateB.year()) {
            return dateA.year() - dateB.year();
          } else if (dateA.month() !== dateB.month()) {
            return dateA.month() - dateB.month();
          } else if (dateA.date() !== dateB.date()) {
            return dateA.date() - dateB.date();
          } else {
            return 0;
          }
        },
    
      },
      { Header: "Horario", accessor: "horario.nombre" },
      { Header: "Lugar del evento", accessor: "espacio.nombre" },
      { Header: "Tipo de evento", accessor: "tiposEvento.nombre" },
    ],
    []
  );
  useEffect(() => {
    loadEventos();
  }, [loadEventos]);
  return <Table columns={columns} data={data} />;
}
