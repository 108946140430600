import React, { useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es"; // Agrega el módulo de idioma español para moment
import "../../App.css";
import moment from "moment";
import es from "date-fns/locale/es"; // Importa el módulo de idioma español para date-fns
import { useStore } from "../../store/appStore";
import { useNavigate } from "react-router-dom";
import Cargando from "../cargando/cargando";

const localizer = momentLocalizer(moment);
moment.locale("es", es); // Configura el idioma español para moment

class CustomAgenda extends React.Component {
  render() {
    const { events } = this.props;
    return (
      <div>
        {events.map((event) => (
          <div key={event.id}>
            <p>Servicio: {event.title}</p>
            <p>Inicio: {event.start.toLocaleString()}</p>
            <p>Finaliza: {event.end.toLocaleString()}</p>
          </div>
        ))}
      </div>
    );
  }
}
function Calendario() {
  const { loadEventos, eventosStore, tiposEventosStore, loadTiposEventos } =
    useStore();
  const [eventos, setEventos] = useState();
  const navigate = useNavigate();
  const data = useMemo(() => eventosStore, [eventosStore]);
  useEffect(() => {
    loadTiposEventos();
    loadEventos().then(() => {
      const eventosRenombrados = [];
      data.map((evento) => {
        eventosRenombrados.push({
          title: `${evento.cliente.nombre}`,
          start: moment.unix(evento?.fecha?.seconds).toDate(),
          end: moment.unix(evento?.fecha?.seconds).toDate(),
          desc: "des",
          location: "location",
          id: evento.id,
          espacio: evento?.espacio?.nombre,
          pax: evento.pax,
          tipo: evento.tiposEvento,
        });
        console.log(evento);
        if (evento.fechaMenu != null) {
          eventosRenombrados.push({
            title: `${evento.cliente.nombre}`,
            start: moment.unix(evento?.fechaMenu?.seconds).toDate(),
            end: moment.unix(evento?.fechaMenu?.seconds).toDate(),
            desc: "des",
            location: "location",
            id: evento.id,
            espacio: evento?.espacio?.nombre,
            pax: evento.pax,
            tipo: {id:1,nombre:'Prueba de menu'},
          });
        }
      });
      setEventos(eventosRenombrados);
    });
  }, []);

  const eventStyleGetter = (event, start, end, isSelected) => {
    console.log(event)
    const objetosFiltrados = tiposEventosStore.filter(
      (objeto) => objeto.nombre === event.tipo.nombre
    );
    const style = {};
    style.backgroundColor = objetosFiltrados[0]?.fondo
      ? objetosFiltrados[0]?.fondo
      : "#d9bb72";
    style.color = objetosFiltrados[0]?.letra
      ? objetosFiltrados[0]?.letra
      : "#fff";
    return {
      style,
    };
  };

  if (eventos) {
    return (
      <div className="app-container">
        <div className="selector-container"></div>
        <div className="calendar-container">
          <Calendar
            localizer={localizer}
            events={eventos}
            components={{
              agenda: {
                event: ({ event }) => <CustomAgenda events={[event]} />,
              },
            }}
            eventPropGetter={eventStyleGetter}
            startAccessor="start"
            endAccessor="end"
            views={["month", "agenda", "week", "day"]}
            messages={{
              next: "Siguiente",
              previous: "Anterior",
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día",
              event: "Evento",
              time: "Horario",
              date: "Fecha",
              noEventsInRange:
                "No hay ni entregas ni recogidas en la fecha señalada",
            }}
            tooltipAccessor={(event) =>
              `Cliente: ${event.title}\nHorario: ${moment(event.start).format(
                "HH:MM"
              )}\nEspacio: ${event.espacio}\nComensales: ${event.pax}`
            }
            onDoubleClickEvent={(event) => {
              navigate(`../eventos/listado/${event.id}`);
            }}
          />
        </div>
      </div>
    );
  } else return <Cargando />;
}

export default Calendario;
