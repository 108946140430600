import React, { useEffect } from "react";
import { useMemo } from "react";
import { useStore } from "../../store/appStore";
import { Table } from "../table";


export default function ListadoCliente() {
  const {loadClientes,clientesStore} = useStore();
  const data = useMemo(() => clientesStore, [clientesStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "CIF/DNI", accessor: "cifDni" },
      {
        Header: "Nombre", accessor: d => {
          return d.nombre2  ? `${d.nombre} y ${d.nombre2}` : d.nombre
        }
      },
      { Header: "Nombre comercial", accessor: "nombreComercial" },
      { Header: "Telefono", accessor: "telefono" },
      { Header: "E-Mail", accessor: "mail" },
      { Header: "Tipo de cliente", accessor: "tipo.nombre" },
      { Header: "Descuento", accessor: d =>{
        return `${d.descuento.toString().replace('.',',')} %`
      } },
    ],
    []
  );
  useEffect(() => {
    loadClientes()
  }, []);
  return <Table columns={columns} data={data} />;
}
