import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select from "react-select";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Presupuesto from "../pdfs/imprimirPresupuesto";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Cargando from "../cargando/cargando";
import Modal from "react-modal";
import { FormClientes } from "../clientes";
import { FormEspacios } from "../espacios";
import { FormAgente } from "../agente";

export default function FormPresupuesto() {
  registerLocale("es", es);
  Modal.setAppElement("#root");
  const [enable, setEnable] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [articulos, setArticulosGlobales] = useState();
  const [modalIsOpenDoc, setIsOpenDoc] = useState(false);
  const [clienteIsOpen, setClienteIsOpen] = useState(false);
  const [espacioIsOpen, setEspacioIsOpen] = useState(false);
  const [almacenar, setAlmacenar] = useState(null);
  const [agenteIsOpen, setAgenteIsOpen] = useState(false);
  const [tabSelected, setTabSelected] = useState(0);
  const location = useLocation();
  function closeAgente() {
    setAgenteIsOpen(false);
  }
  function closeEspacio() {
    setEspacioIsOpen(false);
  }
  function closeClienteModal() {
    setClienteIsOpen(false);
  }
  function closeModalDoc() {
    setIsOpenDoc(false);
  }
  const { idPresupuesto } = useParams();
  const ivas = {};
  const total = {};
  const navigate = useNavigate();
  const { insert, delDato, updateDato } = useAuth();
  const {
    clientesStore,
    loadClientes,
    loadArticulos,
    loadPresupuestos,
    articulosStore,
    setPresupuesto,
    presupuestoCurrent,
    cargarPresupuesto,
    cargarCliente,
    cargarEspacio,
    cargarAgente,
    loadCombinados,
    combinadosStore,
    loadAgentes,
    agentesStore,
    loadEspacios,
    setVenta,
    setEspacio,
    setAgente,
    espaciosStore,
    setCliente,
    setUnsavedChanges
  } = useStore();

  const totalizar = async (comando) => {
    let totalPre = 0;
    presupuestoCurrent.articulos.map((articulo) => {
      totalPre +=
        articulo.total + articulo.total * (articulo.iva.porcentaje / 100);
    });
    console.log(totalPre);
    setPresupuesto({ ...presupuestoCurrent, total: totalPre });
    setAlmacenar(comando);
  };

  useEffect(() => {
    grabar();

    async function grabar() {
      if (almacenar === "Añadir") {
        if (presupuestoCurrent.cliente?.nombre) {
          setUnsavedChanges(false)
          const modifiedArticulos = presupuestoCurrent.articulos.map((articulo) => {
            const precioVenta = parseFloat(articulo.precioVenta.toString().replace(',', '.'));
            const cantidad = parseFloat(articulo.cantidad?.toString().replace(',', '.'));
            const descuento = parseFloat(articulo.descuento?.toString().replace(',', '.'));
            return {
              ...articulo,
              precioVenta: isNaN(precioVenta) ? 0 : precioVenta,
              cantidad: isNaN(cantidad) ? 0 : cantidad,
              descuento: isNaN(descuento) ? 0 : descuento,
            };
          });
          const modifiedData = {
            ...presupuestoCurrent,
            articulos: modifiedArticulos,
          };
          const id = await insert("presupuestos", modifiedData);
          navigate(`/presupuestos/listado/${id}`);
        } else {
          showToast("error", "Debes al menos seleccionar un cliente antes");
          console.log(presupuestoCurrent.cliente.nombre);
        }
      } else if (almacenar === "Modificar") {
        const confirmUpdate = window.confirm(
          `¿Seguro que desea modificar el presupuesto?`
        );
        if (confirmUpdate && presupuestoCurrent.cliente?.nombre) {
          setUnsavedChanges(false)
          const modifiedArticulos = presupuestoCurrent.articulos.map((articulo) => {
            const precioVenta = typeof articulo.precioVenta === 'string' ? parseFloat(articulo.precioVenta.toString().replace(',', '.')) : articulo.precioVenta;
            const cantidad = typeof articulo.cantidad === 'string' ? parseFloat(articulo.cantidad.toString().replace(',', '.')) : articulo.cantidad;
            const descuento = typeof articulo.descuento === 'string' ? parseFloat(articulo.descuento.toString().replace(',', '.')) : articulo.descuento;
            return {
              ...articulo,
              precioVenta: isNaN(precioVenta) ? 0 : precioVenta,
              cantidad: isNaN(cantidad) ? 0 : cantidad,
              descuento: isNaN(descuento) ? 0 : descuento,
            };
          });
          const modifiedData = {
            ...presupuestoCurrent,
            articulos: modifiedArticulos,
          };
          try {
            await updateDato("presupuestos", idPresupuesto, modifiedData);
            setEnable(false);
            navigate(`/presupuestos/listado`);
          } catch (err) {
            console.log(err);
          }
        } else if (confirmUpdate) {
          showToast("error", "Debes al menos seleccionar un cliente antes");
        }
      }
      setAlmacenar(null);
    }
  }, [almacenar]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      switch (e.target.value) {
        case "Añadir":
          await totalizar("Añadir");

          break;
        case "Eliminar":
          const confirmDelete = window.confirm(
            `¿Seguro que desea eliminar el presupuesto?`
          );
          if (confirmDelete) {
            setUnsavedChanges(false)
            await delDato("presupuestos", idPresupuesto);
            loadPresupuestos();
            navigate(`/presupuestos/listado`);
          }
          break;
        case "Modificar":
          await totalizar("Modificar");
          break;
        case "Editar":
        setUnsavedChanges(true)
          setEnable(true);
          break;
        case "Imprimir":
          setIsOpenDoc(true);
          break;
        case "Facturar":
          let articulos = presupuestoCurrent.articulos;
          const cantidad = presupuestoCurrent.pax;
          let total = 0;
          let totalConIva = 0;
          articulos.forEach((articulo) => {
            articulo.cantidad = cantidad;
            articulo.total =
              parseFloat(cantidad) * parseFloat(articulo.precioVenta);
            total += parseFloat(cantidad) * parseFloat(articulo.precioVenta);
            totalConIva +=
              (1 + articulo.iva.porcentaje / 100) *
              (parseFloat(cantidad) * parseFloat(articulo.precioVenta));
          });
          console.log(presupuestoCurrent.cliente);
          setVenta({
            cliente: presupuestoCurrent.cliente,
            articulos: articulos,
            pago: presupuestoCurrent.cliente.pago,
            serie: presupuestoCurrent.cliente.serie,
            total,
            totalConIva: Number(totalConIva.toFixed(2)),
          });
          navigate(`/facturas/nuevo`);
          break;
        default:
          break;
      }
    } catch (err) {}
  };
  const handleChange = ({ target: { value, name } }) => {
    console.log(name);
    if (name === "pax") {
      value = value.replace(/[^\d]/g, "");
    }
    setPresupuesto({ ...presupuestoCurrent, [name]: value });
  };
  const handleChangeArticulos = (e, i, campo) => {
    const valor = e.target.value.replace(/[^\d,]/g, "");
    const articulos = presupuestoCurrent.articulos;
    var total = 0;
    var totalConIva = 0;
    if (campo === "precioVenta") {
      articulos[i].precioVenta = valor;
    } else {
      articulos[i].descuento = valor;
    }
    const precio = parseFloat(articulos[i].precioVenta.toString().replace(',','.'));
    const descuento = (parseFloat(articulos[i].descuento.toString().replace(',','.')) / 100) * precio;
    articulos[i].total = parseFloat(precio - descuento)
    // eslint-disable-next-line array-callback-return
    articulos.map((articulo) => {
      total += parseFloat(articulo.total.toString().replace(',','.')).toFixed(2);
      totalConIva += parseFloat(
        (parseFloat(articulo.total.toString().replace(',','.')).toFixed(2) * (1 + articulo.iva.porcentaje / 100)).toFixed(2)
        
      );
    });
    console.log(total);
    setPresupuesto({ ...presupuestoCurrent, articulos, total:parseFloat(total), totalConIva });
  };
  const handleChangeOpcionales = (e, i, campo) => {
    const valor = e.target.value.replace(/[^\d,]/g, "");
    const opcionales = presupuestoCurrent.opcionales;
    if (campo === "precioVenta") {
      opcionales[i].precioVenta = valor;
    } else {
      opcionales[i].descuento = valor;
    }
    const precio = opcionales[i].precioVenta;
    const descuento = (opcionales[i].descuento / 100) * precio;
    opcionales[i].total = precio - descuento;
    setPresupuesto({ ...presupuestoCurrent, opcionales });
  };
  async function datos() {
    await Promise.all([
      loadClientes(),
      loadArticulos(),
      loadCombinados(),
      loadAgentes(),
      loadEspacios(),
    ]);

    if (idPresupuesto) {
      await cargarPresupuesto(idPresupuesto);
      if (presupuestoCurrent === null) {
        showToast("error", "El documento no existe");
        navigate("/articulos");
      }
    }
    setCargando(false);
  }

  useEffect(() => {
    return () => {
      setIsOpenDoc(false);
    };
  }, [location]);
  useEffect(() => {
    datos();
  }, []);
  useEffect(() => {
    setArticulosGlobales([...articulosStore, ...combinadosStore]);
  }, [articulosStore, combinadosStore]);

  useEffect(() => {
    const result = calculos();
    result.map((result) => {
      result.iva = 0;
      result.total = 0;
    });
  }, [tabSelected]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(presupuestoCurrent.articulos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPresupuesto({ ...presupuestoCurrent, articulos: items });
  };

  const handleDragEnd2 = (result) => {
    if (!result.destination) return;
    const items = Array.from(presupuestoCurrent.opcionales);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPresupuesto({ ...presupuestoCurrent, opcionales: items });
  };

  const handlerEliminar = (tipo, indice) => {
    // Crea una nueva lista sin el elemento en el índice dado
    if (tipo === 0) {
      var nuevaLista = presupuestoCurrent.articulos.filter(
        (_, i) => i !== indice
      );

      // Actualiza el estado de la lista con la nueva lista
      setPresupuesto({
        ...presupuestoCurrent,
        articulos: nuevaLista,
      });
    } else {
      nuevaLista = presupuestoCurrent.opcionales.filter((_, i) => i !== indice);

      // Actualiza el estado de la lista con la nueva lista
      setPresupuesto({
        ...presupuestoCurrent,
        opcionales: nuevaLista,
      });
    }
  };

  function comisiones() {
    let base = 0;
    let espacio = 0;
    let agente = 0;
    let canon = 0;
    presupuestoCurrent?.articulos?.map((articulo) => {
      base +=
        articulo.precioVenta -
        (articulo.precioVenta * articulo.descuento) / 100;
    });
    if (presupuestoCurrent?.espacio) {
      canon = Number(presupuestoCurrent?.espacio?.canon);
      if (presupuestoCurrent?.espacio?.tipoComision?.nombre === "Porcentaje") {
        espacio = (presupuestoCurrent?.espacio?.comision / 100) * base;
      } else espacio = presupuestoCurrent?.espacio?.comision;
    }
    if (presupuestoCurrent?.agente) {
      if (presupuestoCurrent?.agente?.tipoComision?.nombre === "Porcentaje")
        agente = (presupuestoCurrent?.agente?.comision / 100) * base;
      else agente = presupuestoCurrent?.agente?.comision;
    }
    return (
      <table style={{ textAlign: "center" }}>
        <tr>
          <th>Canon Espacio</th>
          <th>Comision Espacio</th>
          <th>Comision Comercial</th>
          <th>Total</th>
        </tr>
        <tr>
          <td>{canon ? `${canon.toFixed(2).toString().replace('.', ',')} €` : "0 €"}</td>
          <td>{espacio ? `${Number(espacio).toFixed(2).toString().replace('.', ',')} €` : "0 €"}</td>
          <td>{agente ? `${agente.toFixed(2).toString().replace('.', ',')} €` : "0 €"}</td>
          <td>
            {Number(espacio) + Number(agente) + Number(canon)
              ? `${(Number(espacio) + Number(agente) + Number(canon)).toFixed(
                  2
                ).toString().replace('.', ',')} €`
              : "0 €"}
          </td>
        </tr>
      </table>
    );
  }

  function funcionTotales() {
    const result = calculos();
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          margin: "20px",
        }}
      >
        {result.map((result) => {
          return (
            <div>
              <label htmlFor="" className="label">
                {result.iva}
              </label>
              <label htmlFor="" className="label">
                {Number(result.total).toFixed(2).toString().replace('.', ',')}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
  function calculos() {
    presupuestoCurrent?.articulos?.map((articulo) => {
      const iva = articulo.iva?.nombre;
      if (iva) {
        var precio = parseFloat(articulo.precioVenta?.toString().replace(',','.'))
        const descuento = precio * (parseFloat(articulo.descuento?.toString().replace(',','.') / 100));
        precio = precio - descuento;
        if (ivas[iva]) {
          ivas[iva] += (articulo.iva?.porcentaje * precio) / 100;
        } else {
          ivas[iva] = (articulo.iva?.porcentaje * precio) / 100;
        }
        if (total["Total sin iva"]) {
          total["Total sin iva"] += precio;
          total["Total con iva"] +=
            precio + (precio * articulo.iva?.porcentaje) / 100;
        } else {
          total["Total sin iva"] = precio;
          total["Total con iva"] =
            precio + (precio * articulo.iva?.porcentaje) / 100;
        }
      }
    });
    const result = [];
    for (const iva in ivas) {
      result.push({
        iva: iva,
        total: ivas[iva],
      });
    }
    for (const iva in total) {
      result.push({
        iva: iva,
        total: total[iva],
      });
    }
    return result;
  }

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">
            {idPresupuesto ? "Modificar " : "Añadir "}
            Presupuesto
          </label>
          <div className="buttonMain">
            {idPresupuesto ? (
              <>
                <input
                  className="button main"
                  type="submit"
                  value="Imprimir"
                  onClick={handleSubmit}
                />
                <input
                  className="button main"
                  type="submit"
                  value="Facturar"
                  onClick={handleSubmit}
                />
                {enable ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <input
                    className="button main"
                    type="submit"
                    value="Editar"
                    onClick={handleSubmit}
                  />
                )}
              </>
            ) : (
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
        <div
          className={
            idPresupuesto ? (enable ? "enabled" : "disabled") : "enabled"
          }
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Cliente
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={
                      presupuestoCurrent?.cliente?.id
                        ? clientesStore.find(
                            (cliente) =>
                              cliente.id === presupuestoCurrent?.cliente?.id
                          )
                        : null
                    }
                    options={clientesStore}
                    getOptionLabel={(cliente) => cliente.nombre}
                    getOptionValue={(cliente) => cliente.id}
                    onChange={(val) =>
                      setPresupuesto({
                        ...presupuestoCurrent,
                        cliente: {
                          id: val.id,
                          cifDni: val.cifDni,
                          nombre: val.nombre,
                          descuento: val.descuento,
                          pago: val.pago,
                          serie: val.serie,
                        },
                      })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      console.log(presupuestoCurrent);
                      if (
                        parseInt(
                          presupuestoCurrent.pax || presupuestoCurrent.pax
                        ) !== 0
                      ) {
                        setPresupuesto({
                          ...presupuestoCurrent,
                          cliente: null,
                        });
                        setCliente({});
                        setClienteIsOpen(true);
                      } else {
                        showToast(
                          "error",
                          "Debe introducir antes los comensales minimos"
                        );
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      if (presupuestoCurrent?.cliente?.id) {
                        cargarCliente(presupuestoCurrent.cliente?.id);
                        setClienteIsOpen(true);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="grupo">
                <label htmlFor="" className="label">
                  Espacio
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={
                      presupuestoCurrent?.espacio?.id
                        ? espaciosStore.find(
                            (espacio) =>
                              espacio.id === presupuestoCurrent?.espacio?.id
                          )
                        : null
                    }
                    options={espaciosStore}
                    getOptionLabel={(espacio) => espacio.nombre}
                    getOptionValue={(espacio) => espacio.id}
                    onChange={(val) => {
                      setPresupuesto({
                        ...presupuestoCurrent,
                        espacio: {
                          id: val.id,
                          nombre: val.nombre,
                          tipoComision: val.tipoComision
                            ? val.tipoComision
                            : null,
                          canon: val.canon ? val.canon : null,
                          comision: val.comision ? val.comision : null,
                        },
                      });
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      if (
                        presupuestoCurrent.pax ||
                        presupuestoCurrent.pax === 0
                      ) {
                        setPresupuesto({
                          ...presupuestoCurrent,
                          espacio: null,
                        });
                        setEspacio({});
                        setEspacioIsOpen(true);
                      } else {
                        showToast(
                          "error",
                          "Debe introducir antes los comensales minimos"
                        );
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      console.log(presupuestoCurrent);
                      if (presupuestoCurrent?.espacio?.id) {
                        cargarEspacio(presupuestoCurrent?.espacio?.id);
                        setEspacioIsOpen(true);
                        console.log("hecho");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Comercial
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={
                      presupuestoCurrent?.comercial?.id
                        ? agentesStore.find(
                            (comercial) =>
                              comercial.id === presupuestoCurrent?.comercial?.id
                          )
                        : null
                    }
                    options={agentesStore}
                    getOptionLabel={(comercial) => comercial.nombre}
                    getOptionValue={(comercial) => comercial.id}
                    onChange={(val) => {
                      setPresupuesto({
                        ...presupuestoCurrent,
                        comercial: {
                          id: val.id,
                          nombre: val.nombre,
                          tipoComision: val.tipoComision,
                          comision: val.comision,
                        },
                      });
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      if (
                        presupuestoCurrent.pax ||
                        presupuestoCurrent.pax === 0
                      ) {
                        setPresupuesto({
                          ...presupuestoCurrent,
                          comercial: null,
                        });
                        setAgente({});
                        setAgenteIsOpen(true);
                      } else {
                        showToast(
                          "error",
                          "Debe introducir antes los comensales minimos"
                        );
                      }
                    }}
                  />

                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      console.log(presupuestoCurrent);
                      if (presupuestoCurrent?.comercial?.id) {
                        cargarAgente(presupuestoCurrent?.comercial?.id);
                        setAgenteIsOpen(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label className="label">Comensales minimos</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Comensales minimos"
                  name="pax"
                  value={presupuestoCurrent?.pax ? presupuestoCurrent?.pax : 0}
                  id="pax"
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Validez
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Valido hasta ..."
                  locale="es"
                  selected={
                    presupuestoCurrent?.validez
                      ? typeof presupuestoCurrent?.validez === "string" ||
                        presupuestoCurrent?.validez instanceof Date
                        ? presupuestoCurrent?.validez
                        : presupuestoCurrent?.validez.toDate()
                      : null
                  }
                  onChange={(validez) =>
                    setPresupuesto({ ...presupuestoCurrent, validez })
                  }
                />
                <label htmlFor="" className="label">
                  Fecha
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  locale="es"
                  placeholderText="Fecha del evento"
                  selected={
                    presupuestoCurrent?.fecha
                      ? typeof presupuestoCurrent?.fecha === "string" ||
                        presupuestoCurrent?.fecha instanceof Date
                        ? presupuestoCurrent?.fecha
                        : presupuestoCurrent?.fecha.toDate()
                      : null
                  }
                  onChange={(fecha) =>
                    setPresupuesto({ ...presupuestoCurrent, fecha })
                  }
                />
              </div>
              {comisiones()}
            </div>
          </div>
        </div>
        <div>
          <div className="datos">
            <Tabs className="react-tabs">
              <TabList className="react-tabs__tab-list">
                <Tab className="react-tabs__tab">Lineas de presupuesto</Tab>
                <Tab className="react-tabs__tab">Productos opcionales</Tab>
              </TabList>

              <TabPanel className="react-tabs__tab-panel">
                <div
                  className={
                    idPresupuesto
                      ? enable
                        ? "enabled columna segunda"
                        : "disabled columna segunda"
                      : "enabled columna segunda"
                  }
                >
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <table className="table">
                      <thead className="thead">
                        <tr className="fila">
                          <th className="columna1">Producto</th>
                          <th className="columna2">Precio</th>
                          <th className="columna2">Descuento</th>
                          <th className="columna2">Subtotal</th>
                          <th style={{ marginRight: "20px" }}></th>
                        </tr>
                      </thead>
                      <Droppable droppableId="filas">
                        {(provided) => (
                          <tbody
                            className="tbody"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {presupuestoCurrent?.articulos?.map(
                              (articulo, i) => {
                                return (
                                  <Draggable
                                    key={articulo.id & i}
                                    draggableId={articulo.id}
                                    index={i}
                                  >
                                    {(provided) => (
                                      <tr
                                        className="fila2"
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <td className="columna1">
                                          <label
                                            className="label"
                                            style={{
                                              whiteSpace: "pre-line",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {articulo.nombre}
                                          </label>
                                        </td>
                                        <td className="columna2">
                                          <input
                                            key={i}
                                            onChange={(event) =>
                                              handleChangeArticulos(
                                                event,
                                                i,
                                                "precioVenta"
                                              )
                                            }
                                            className="text2"
                                            type="text"
                                            value={articulo.precioVenta
                                              ? articulo.precioVenta.toString().replace('.', ',')
                                              : (articulo.precioVenta = 0)}
                                          />
                                        </td>
                                        <td className="columna2">
                                          <input
                                            key={i}
                                            onChange={(event) =>
                                              handleChangeArticulos(
                                                event,
                                                i,
                                                "descuento"
                                              )
                                            }
                                            className="text2"
                                            type="text"
                                            value={articulo.descuento
                                              ? articulo.descuento.toString().replace('.', ',')
                                              : (articulo.descuento = 0)}
                                          />
                                        </td>
                                        <td
                                          className="columna2"
                                          style={{ textAlign: "end" }}
                                        >
                                          <label htmlFor="" className="label">
                                            {articulo.total?.toFixed(2).toString().replace('.', ',')}
                                          </label>
                                        </td>
                                        <td
                                          onClick={() => {
                                            handlerEliminar(0, i);
                                          }}
                                        >
                                          <AiOutlineCloseCircle
                                            style={{
                                              marginRight: "20px",
                                            }}
                                            size={"1.5em"}
                                            color="#CB950E"
                                          />
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              }
                            )}
                            {provided.placeholder}
                            <tr className="fila">
                              <td className="columna1">
                                <Select
                                  className="select"
                                  classNamePrefix="selectClass"
                                  isSearchable={true}
                                  options={articulos}
                                  getOptionLabel={(tipo) => tipo.nombre}
                                  getOptionValue={(tipo) => tipo.id}
                                  onChange={(val) => {
                                    if (val.iva?.nombre) {
                                      var articulo = {
                                        nombre: val.nombre,
                                        id: val.id,
                                        precioVenta: val.precioVenta,
                                        iva: val.iva,
                                        categoria: val.categoria
                                          ? val.categoria
                                          : null,
                                      };

                                      if (val.articulosSelected) {
                                        val.articulosSelected.map((arti) => {
                                          const nombre = `${articulo.nombre} \n \u00A0\u00A0\u00A0\u00A0 ${arti.nombre}`;
                                          articulo = { ...articulo, nombre };
                                        });
                                      }
                                      articulo = {
                                        ...articulo,
                                        descuento: presupuestoCurrent.cliente
                                          ?.descuento
                                          ? presupuestoCurrent.cliente.descuento
                                          : 0,
                                      };
                                      if (presupuestoCurrent.articulos)
                                        var artiSelect =
                                          presupuestoCurrent.articulos;
                                      else artiSelect = [];
                                      console.log(articulo)
                                      const precio = articulo.precioVenta;
                                      const descuento =
                                        (articulo.descuento / 100) * precio;
                                      const total = precio - descuento;
                                      articulo = {
                                        ...articulo,
                                        total,
                                      };
                                      artiSelect.push(articulo);
                                      setPresupuesto({
                                        ...presupuestoCurrent,
                                        articulos: artiSelect,
                                      });
                                    } else {
                                      showToast(
                                        "error",
                                        "Articulo con datos erroneos o incompleto"
                                      );
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                            <tr className="iva">
                              {funcionTotales()}
                              {setTabSelected(1)}
                            </tr>
                          </tbody>
                        )}
                      </Droppable>
                    </table>
                  </DragDropContext>
                </div>
              </TabPanel>
              <TabPanel className="react-tabs__tab-panel">
                <div
                  className={
                    idPresupuesto
                      ? enable
                        ? "enabled columna segunda"
                        : "disabled columna segunda"
                      : "enabled columna segunda"
                  }
                >
                  <DragDropContext onDragEnd={handleDragEnd2}>
                    <table className="table">
                      <thead className="thead">
                        <tr className="fila">
                          <th className="columna1">Producto</th>
                          <th className="columna2">Precio</th>
                          <th className="columna2">Impuestos</th>
                          <th className="columna2">Descuentos</th>
                          <th style={{ marginRight: "20px" }}></th>
                        </tr>
                      </thead>
                      <Droppable droppableId="filas">
                        {(provided) => (
                          <tbody
                            className="tbody"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {presupuestoCurrent?.opcionales?.map(
                              (articulo, i) => {
                                return (
                                  <>
                                    <Draggable
                                      key={articulo.id & i}
                                      draggableId={articulo.id}
                                      index={i}
                                    >
                                      {(provided) => (
                                        <tr
                                          className="fila2"
                                          key={i}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                        >
                                          <td className="columna1">
                                            <label className="label">
                                              {articulo.nombre}
                                            </label>
                                          </td>
                                          <td className="columna2">
                                            <input
                                              key={i}
                                              onChange={(event) =>
                                                handleChangeOpcionales(
                                                  event,
                                                  i,
                                                  "precioVenta"
                                                )
                                              }
                                              className="text2"
                                              type="text"
                                              value={articulo.precioVenta
                                                ? articulo.precioVenta.toString().replace('.', ',')
                                                : (articulo.precioVenta = 0)}
                                            />
                                          </td>
                                          <td
                                            className="columna2"
                                            style={{ textAlign: "end" }}
                                          >
                                            <label htmlFor="" className="label">
                                              {articulo.iva.nombre}
                                            </label>
                                          </td>
                                          <td className="columna2">
                                            <input
                                              key={i}
                                              onChange={(event) =>
                                                handleChangeOpcionales(
                                                  event,
                                                  i,
                                                  "descuento"
                                                )
                                              }
                                              className="text2"
                                              style={{ textAlign: "end" }}
                                              type="text"
                                              value={articulo.descuento
                                                ? articulo.descuento.toString().replace('.', ',')
                                                : (articulo.descuento = 0)}
                                            />
                                          </td>
                                          <td
                                            onClick={() =>
                                              handlerEliminar(1, i)
                                            }
                                          >
                                            <AiOutlineCloseCircle
                                              style={{
                                                marginRight: "20px",
                                              }}
                                              size={"1.5em"}
                                              color="#CB950E"
                                            />
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  </>
                                );
                              }
                            )}
                            {provided.placeholder}
                            <tr
                              className="fila"
                              style={{ marginBottom: "20px" }}
                            >
                              <td className="columna1">
                                <Select
                                  className="select"
                                  classNamePrefix="selectClass"
                                  isSearchable={true}
                                  options={articulos}
                                  getOptionLabel={(tipo) => tipo.nombre}
                                  getOptionValue={(tipo) => tipo.id}
                                  onChange={(val) => {
                                    if (val.iva?.nombre) {
                                      console.log(val);
                                      var articulo = val;
                                      articulo = {
                                        ...articulo,
                                        descuento: presupuestoCurrent.cliente
                                          ?.descuento
                                          ? presupuestoCurrent.cliente.descuento
                                          : 0,
                                      };
                                      if (presupuestoCurrent.opcionales)
                                        var artiSelect =
                                          presupuestoCurrent.opcionales;
                                      else artiSelect = [];
                                      artiSelect.push(articulo);
                                      setPresupuesto({
                                        ...presupuestoCurrent,
                                        opcionales: artiSelect,
                                      });
                                    } else {
                                      showToast(
                                        "error",
                                        "Articulo con datos erroneos o incompleto"
                                      );
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                            {/*<tr id="columna2-prod-opcionales"></tr>
                      <tr className="iva">{funcionTotales2()}{setTabSelected(2)}</tr>*/}
                          </tbody>
                        )}
                      </Droppable>
                    </table>
                  </DragDropContext>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </form>

      <Modal
        isOpen={agenteIsOpen}
        onRequestClose={closeAgente}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",

            position: "absolute",
            right: "20px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeAgente}
        />
        <FormAgente
          cerrar={closeAgente}
          idAgenteSelected={presupuestoCurrent?.comercial?.id}
          llamadoDesde={"presupuestos"}
        />
      </Modal>

      <Modal
        isOpen={espacioIsOpen}
        onRequestClose={closeEspacio}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",

            position: "absolute",
            right: "20px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeEspacio}
        />
        <FormEspacios
          cerrar={closeEspacio}
          idEspacioSelected={presupuestoCurrent?.espacio?.id}
          llamadoDesde={"presupuestos"}
        />
      </Modal>

      <Modal
        isOpen={clienteIsOpen}
        onRequestClose={closeClienteModal}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",

            position: "absolute",
            right: "20px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeClienteModal}
        />
        <FormClientes
          cerrar={closeClienteModal}
          llamadoDesde={"presupuestos"}
          idClienteSelected={presupuestoCurrent?.cliente?.id}
        />
      </Modal>

      <Modal
        isOpen={modalIsOpenDoc}
        onRequestClose={closeModalDoc}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "30px",

            position: "absolute",
            right: "30px",
            color: "red",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalDoc}
        />
        <Presupuesto doc={presupuestoCurrent} cerra={closeModalDoc} />
      </Modal>
      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
    </div>
  );
}
