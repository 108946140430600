import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { CardContactos } from "../contactos";
import { CardCompra } from "../facturasCompra/cardCompra";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Cargando from "../cargando/cargando";
import Modal from "react-modal";
import Select from "react-select";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Pago from "../pago/pago";
import { AiOutlineCloseCircle } from "react-icons/ai";
export default function FormProveedores(props) {
  Modal.setAppElement("#root");
  const [enableProveedor, setEnableProveedor] = useState(false);
  const { delDato, updateDato, insert } = useAuth();
  const [cargando, setCargando] = useState(true);
  let { idProveedor } = useParams();
  const navigate = useNavigate();
  const {
    cargarProveedor,
    setProveedor,
    proveedorCurrent,
    agenteCurrent,
    espacioCurrent,
    compraCurrent,
    setAgente,
    loadProveedores,
    tiposIdentificacionesStore,
    loadTiposIdentificaciones,
    tiposProveedoresStore,
    loadTiposProveedores,
    pagosStore,
    loadPagos,
    articuloCurrent,
    setArticulo,
    setEspacio,
    cargarPago,
    setPago,
    setCompra,
    setUnsavedChanges,
  } = useStore();
  const [modalIsOpenPago, setIsOpenPago] = useState(false);
  function closeModalPago() {
    setIsOpenPago(false);
  }
  const { cerrar, idProveedorSelected, llamadoDesde } = props;
  async function datos() {
    await Promise.all([
      loadTiposProveedores(),
      loadPagos(),
      loadTiposIdentificaciones(),
    ]);
    if (idProveedor) {
      await cargarProveedor(idProveedor);
      if (proveedorCurrent === null) {
        showToast("error", "El Proveedor no existe");
        navigate("/proveedores");
      }
    }

    setCargando(false);
  }
  useEffect(() => {
    datos();
  }, []);
  const handleChange = ({ target: { value, name } }) => {
    if (name === "cp" || name === "telefono")
      value = value.replace(/[^\d,.]/g, "");
    setProveedor({ ...proveedorCurrent, [name]: value });
  };
  const handleSubmit = async (e) => {
    if (!idProveedor) idProveedor = proveedorCurrent?.id;
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (proveedorCurrent?.nombre && proveedorCurrent?.pago) {
          try {
            setUnsavedChanges(false);
            const id = await insert("proveedores", proveedorCurrent);
            setProveedor({
              ...proveedorCurrent,
              id,
            });
            console.log(articuloCurrent);
            if (articuloCurrent?.nombre) {
              setArticulo({
                ...articuloCurrent,
                proveedor: { ...proveedorCurrent, id },
              });
              loadProveedores();
              cerrar();
            } else if (agenteCurrent?.nombre) {
              setAgente({
                ...agenteCurrent,
                proveedor: { ...proveedorCurrent, id },
              });
              loadProveedores();
              cerrar();
            } else if (compraCurrent?.ref) {
              setCompra({
                ...compraCurrent,
                proveedor: { ...proveedorCurrent, id },
                pago: proveedorCurrent.pago,
              });
              loadProveedores();
              cerrar();
            } else if (espacioCurrent?.nombre) {
              setEspacio({
                ...espacioCurrent,
                proveedor: { ...proveedorCurrent, id },
              });
              loadProveedores();
              cerrar();
            } else navigate(`/proveedores/listado/${id}`);
          } catch (err) {
            console.log(err);
          }
        } else {
          showToast(
            "error",
            "Al menos debe rellenar el nombre fiscal y seleccionar una forma de pago"
          );
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al proveedor?"
        );
        if (confirmDelete) {
          setProveedor({});
          try {
            setUnsavedChanges(false);
            switch (llamadoDesde) {
              case "articulos":
                await delDato("proveedores", idProveedorSelected);
                setArticulo({ ...articuloCurrent, proveedor: null });
                loadProveedores();
                cerrar();
                break;
              case "agentes":
                await delDato("proveedores", idProveedorSelected);
                setAgente({ ...agenteCurrent, proveedor: null });
                loadProveedores();
                cerrar();
                break;
              case "facturaCompra":
                await delDato("proveedores", idProveedorSelected);
                setCompra({ ...compraCurrent, proveedor: null });
                loadProveedores();
                cerrar();
                break;
              case "espacios":
                await delDato("proveedores", idProveedorSelected);
                setEspacio({ ...espacioCurrent, proveedor: null });
                loadProveedores();
                cerrar();
                break;
              default:
                await delDato("proveedores", idProveedor);
                navigate(`/proveedores/listado`);
                break;
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al proveedor?"
        );
        if (
          confirmUpdate &&
          proveedorCurrent?.nombre &&
          proveedorCurrent?.pago
        ) {
          setUnsavedChanges(false);
          console.log(compraCurrent);
          try {
            switch (llamadoDesde) {
              case "articulos":
                await updateDato(
                  "proveedores",
                  articuloCurrent.proveedor?.id,
                  proveedorCurrent
                );
                loadProveedores();
                setArticulo({
                  ...articuloCurrent,
                  proveedor: {
                    proveedorCurrent,
                    id: articuloCurrent.proveedor.id,
                  },
                });
                cerrar();
                break;
              case "agentes":
                console.log(agenteCurrent.proveedor);
                await updateDato(
                  "proveedores",
                  agenteCurrent.proveedor.id,
                  proveedorCurrent
                );
                loadProveedores();
                setAgente({
                  ...agenteCurrent,
                  proveedor: {
                    proveedorCurrent,
                    id: agenteCurrent.proveedor.id,
                  },
                });
                cerrar();
                break;
              case "facturaCompra":
                await updateDato(
                  "proveedores",
                  compraCurrent.proveedor?.id,
                  proveedorCurrent
                );
                loadProveedores();
                setCompra({
                  ...compraCurrent,
                  proveedor: {
                    proveedorCurrent,
                    id: compraCurrent.proveedor.id,
                  },
                });
                cerrar();
                break;
              case "espacios":
                await updateDato(
                  "proveedores",
                  espacioCurrent.proveedor?.id,
                  proveedorCurrent
                );
                loadProveedores();
                setEspacio({
                  ...espacioCurrent,
                  proveedor: {
                    proveedorCurrent,
                    id: espacioCurrent.proveedor.id,
                  },
                });
                cerrar();
                break;
              default:
                await updateDato("proveedores", idProveedor, proveedorCurrent);
                navigate("/proveedores/listado");
                break;
            }
          } catch (err) {
            console.log(agenteCurrent, llamadoDesde, idProveedor);
          }
        } else if (confirmUpdate) {
          showToast(
            "error",
            "Al menos debe rellenar el nombre fiscal y seleccionar una forma de pago"
          );
        }
        break;
      case "Editar":
        setUnsavedChanges(true);
        setEnableProveedor(true);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="superior">
            <label className="tituloVentana">
              {idProveedor ? "Modificar " : "Añadir "}Proveedor
            </label>
            <div className="buttonMain">
              {idProveedor || idProveedorSelected ? (
                enableProveedor ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <input
                    className="button main"
                    type="submit"
                    value="Editar"
                    onClick={handleSubmit}
                  />
                )
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Añadir"
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
          <div
            className={
              idProveedor || proveedorCurrent?.id
                ? enableProveedor
                  ? "enabled"
                  : "disabled"
                : "enabled"
            }
          >
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label className="label">Tipo de Identificación</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={
                        proveedorCurrent?.tiposIdentificacion
                          ? proveedorCurrent?.tiposIdentificacion
                          : ""
                      }
                      options={tiposIdentificacionesStore}
                      getOptionLabel={(tipo) => tipo.nombre}
                      getOptionValue={(tipo) => tipo.id}
                      onChange={(val) =>
                        setProveedor({
                          ...proveedorCurrent,
                          tiposIdentificacion: val,
                        })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={async () => {
                        const nombre = prompt(
                          "Inserte el nuevo tipo de identificación"
                        );
                        if (nombre !== null && nombre !== "") {
                          const id = await insert("tiposIdentificaciones", {
                            nombre,
                          });
                          setProveedor({
                            ...proveedorCurrent,
                            tiposIdentificacion: { id, nombre },
                          });
                          loadTiposIdentificaciones();
                        }
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={async () => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar el tipo de identificacion?"
                        );
                        if (confirmDelete) {
                          await delDato(
                            "tiposIdentificaciones",
                            proveedorCurrent?.tiposIdentificacion.id
                          );
                        }
                        loadTiposIdentificaciones();
                        setProveedor({
                          ...proveedorCurrent,
                          tiposIdentificacion: null,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="grupo">
                  <label className="label">Identificación Fiscal</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="Identificación fiscal"
                    name="cifDni"
                    id="cifDni"
                    onChange={handleChange}
                    value={
                      proveedorCurrent?.cifDni ? proveedorCurrent?.cifDni : ""
                    }
                  />
                </div>

                <div className="grupo">
                  <label className="label">Tipo de proveedor</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={
                        proveedorCurrent?.tipo ? proveedorCurrent?.tipo : ""
                      }
                      options={tiposProveedoresStore}
                      getOptionLabel={(tipo) => tipo.nombre}
                      getOptionValue={(tipo) => tipo.id}
                      onChange={(val) =>
                        setProveedor({ ...proveedorCurrent, tipo: val })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={async () => {
                        const nombre = prompt(
                          "Inserte el nuevo tipo de proveedor"
                        );
                        if (nombre !== null && nombre !== "") {
                          const id = await insert("tiposProveedores", {
                            nombre,
                          });
                          setProveedor({
                            ...proveedorCurrent,
                            tipo: { id, nombre },
                          });
                          loadTiposProveedores();
                        }
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={async () => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar el tipo de proveedor?"
                        );
                        if (confirmDelete) {
                          await delDato(
                            "tiposProveedores",
                            proveedorCurrent?.tipo.id
                          );
                        }
                        loadTiposProveedores();
                        setProveedor({
                          ...proveedorCurrent,
                          tipo: null,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="grupo">
                  <label className="label">Proveedor</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="Proveedor"
                    name="proveedor"
                    id="proveedor"
                    onChange={handleChange}
                    value={
                      proveedorCurrent?.proveedor
                        ? proveedorCurrent?.proveedor
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label className="label">Nombre Fiscal</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Nombre fiscal"
                    name="nombre"
                    id="nombre"
                    onChange={handleChange}
                    value={
                      proveedorCurrent?.nombre ? proveedorCurrent?.nombre : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">Nombre Comercial</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Nombre comercial"
                    name="nombreComercial"
                    id="nombreComercial"
                    onChange={handleChange}
                    value={
                      proveedorCurrent?.nombreComercial
                        ? proveedorCurrent?.nombreComercial
                        : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">telefono</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Telefono"
                    name="telefono"
                    id="telefono"
                    onChange={handleChange}
                    value={
                      proveedorCurrent?.telefono
                        ? proveedorCurrent?.telefono
                        : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">E-Mail</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Email"
                    name="mail"
                    id="mail"
                    onChange={handleChange}
                    value={proveedorCurrent?.mail ? proveedorCurrent?.mail : ""}
                  />
                </div>
              </div>
            </div>
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label className="label">Dirección</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Dirección"
                    name="direccion"
                    id="direccion"
                    value={
                      proveedorCurrent?.direccion
                        ? proveedorCurrent?.direccion
                        : ""
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="grupo">
                  <label className="label">Código Postal</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="CP"
                    name="cp"
                    id="cp"
                    value={proveedorCurrent?.cp ? proveedorCurrent?.cp : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label className="label">Localidad</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="Localidad"
                    name="localidad"
                    id="localidad"
                    value={
                      proveedorCurrent?.localidad
                        ? proveedorCurrent?.localidad
                        : ""
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="grupo">
                  <label className="label">Provincia</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="Provincia"
                    name="provincia"
                    id="provincia"
                    value={
                      proveedorCurrent?.provincia
                        ? proveedorCurrent?.provincia
                        : ""
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="grupo">
                  <label className="label">País</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="País"
                    name="pais"
                    id="pais"
                    value={proveedorCurrent?.pais ? proveedorCurrent?.pais : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label className="label">Banco</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Banco"
                    name="banco"
                    id="banco"
                    value={
                      proveedorCurrent?.banco ? proveedorCurrent?.banco : ""
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label htmlFor="" className="label">
                    Forma de pago
                  </label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="select"
                      isSearchable={true}
                      value={
                        proveedorCurrent?.pago?.id
                          ? pagosStore.find(
                              (pago) => pago.id === proveedorCurrent?.pago?.id
                            )
                          : null
                      }
                      options={pagosStore}
                      getOptionLabel={(pago) => pago.nombre}
                      getOptionValue={(pago) => pago.id}
                      onChange={(val) =>
                        setProveedor({ ...proveedorCurrent, pago: val })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={() => {
                        setProveedor({ ...proveedorCurrent, pago: {} });
                        setPago({});
                        setIsOpenPago(true);
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Ver"
                      onClick={() => {
                        cargarPago(proveedorCurrent?.pago?.id);
                        setIsOpenPago(true);
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={() => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar la forma de pago?"
                        );
                        if (confirmDelete) {
                          delDato("pagos", proveedorCurrent?.pago.id);
                          setProveedor({ ...proveedorCurrent, pago: {} });
                          loadPagos();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <Modal isOpen={cargando}>
          <Cargando />
        </Modal>
        <Modal
          isOpen={modalIsOpenPago}
          onRequestClose={closeModalPago}
          style={{
            overlay: {
              border: "8px",
            },
            content: {
              border: "0px",
              background: 0,
            },
          }}
        >
          <AiOutlineCloseCircle
            style={{
              marginTop: "25px",

              position: "absolute",
              right: "20px",
            }}
            size={"1.5em"}
            color="#CB950E"
            onClick={closeModalPago}
          />
          <Pago cerrar={closeModalPago} llamadoDesde={"proveedor"} />
        </Modal>
      </div>
      <Tabs className="react-tabs">
        <TabList className="react-tabs__tab-list">
          <Tab className="react-tabs__tab">Contactos</Tab>
          <Tab className="react-tabs__tab">Facturas</Tab>
          <Tab className="react-tabs__tab">Ficheros</Tab>
        </TabList>
        <TabPanel className="react-tabs__tab-panel">
          <CardContactos
            llamado={"proveedor"}
            contactos={proveedorCurrent?.contactos}
          />
        </TabPanel>
        <TabPanel className="react-tabs__tab-panel">
          <CardCompra id={idProveedor} />
        </TabPanel>
        <TabPanel className="react-tabs__tab-panel">
          <h1>Ficheros</h1>
        </TabPanel>
      </Tabs>
    </>
  );
}
