import { useStore } from "../../store/appStore";
import { useAuth } from "../../context/authContext";
import { showToast } from "../../utils/utils";

export default function Pago(props) {
  const {
    clienteCurrent,
    ventaCurrent,
    compraCurrent,
    setProveedor,
    proveedorCurrent,
    setCliente,
    loadPagos,
    setPago,
    setVenta,
    setCompra,
    pagoCurrent,
  } = useStore();
  const { insert, updateDato } = useAuth();

  const handleChange = ({ target: { value, name } }) => {
    //controlamos que en estos camos solo se escriba numeros
    if (name === "dias") {
      value = value.replace(/[^\d,.]/g, "");
    }
    //modifcamos el valor en articulo
    setPago({ ...pagoCurrent, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    console.log(accion);
    switch (accion) {
      case "Añadir":
        if (
          pagoCurrent.nombre &&
          pagoCurrent.dias &&
          pagoCurrent.nombre !== "" &&
          pagoCurrent.nombre !== null &&
          pagoCurrent.dias !== "" &&
          pagoCurrent.dias !== null
        ) {
          try {
            pagoCurrent.dias= parseFloat(pagoCurrent.dias?.toString().replace(',', '.'));
            const id = await insert("pagos", pagoCurrent);
            switch (props.llamadoDesde) {
              case "proveedor":
                setProveedor({
                  ...proveedorCurrent,
                  pago: { ...pagoCurrent, id },
                });
                break;
              case "cliente":
                setCliente({ ...clienteCurrent, pago: { ...pagoCurrent, id } });
                break;
              case "venta":
                setVenta({ ...ventaCurrent, pago: { ...pagoCurrent, id } });
                break;
              case "compra":
                setCompra({ ...compraCurrent, pago: { ...pagoCurrent, id } });
                break;
              default:
                break;
            }
            loadPagos();
            props.cerrar();
          } catch (err) {}
        } else {
          showToast("error", "Debe rellenar todos los datos");
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar la forma de pago?"
        );
        if (confirmUpdate) {
          pagoCurrent.dias= parseFloat(pagoCurrent.dias?.toString().replace(',', '.'));
          try {
            switch (props.llamadoDesde) {
              case "proveedor":
                await updateDato(
                  "pagos",
                  proveedorCurrent.pago?.id,
                  pagoCurrent
                );
                setProveedor({
                  ...proveedorCurrent,
                  pago: { pagoCurrent, id: proveedorCurrent.pago?.id },
                });
                break;
              case "cliente":
                await updateDato("pagos", clienteCurrent.pago?.id, pagoCurrent);
                console.log(pagoCurrent);
                setCliente({
                  ...clienteCurrent,
                  pago: { pagoCurrent, id: clienteCurrent.pago?.id },
                });
                console.log(clienteCurrent);
                break;
              case "venta":
                await updateDato("pagos", ventaCurrent.pago?.id, pagoCurrent);
                setVenta({
                  ...ventaCurrent,
                  pago: { pagoCurrent, id: ventaCurrent.pago?.id },
                });
                break;
              case "compra":
                await updateDato("pagos", compraCurrent.pago?.id, pagoCurrent);
                setCompra({
                  ...compraCurrent,
                  pago: { pagoCurrent, id: compraCurrent.pago?.id },
                });
                break;
              default:
                break;
            }
            loadPagos();
            props.cerrar();
          } catch (err) {
            console.log(err);
          }
        }
        break;
      default:
        break;
    }
  };
  return (
    <div className="form" style={{ width: "500px", marginTop: "20vh" }}>
      <form>
        {clienteCurrent?.pago?.id ||
        proveedorCurrent?.pago?.id ||
        ventaCurrent?.pago?.id ||
        compraCurrent?.pago?.id ? (
          <div className="superior">
            <label className="tituloVentana">Modificar forma de pago</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Modificar"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : (
          <div className="superior">
            <label className="tituloVentana">Añadir forma de pago</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
        <div className="datos">
          <div className="columna">
            <div className="grupo">
              <label className="label">Nombre</label>
              <input
                type="text"
                className="text"
                placeholder="Nombre"
                name="nombre"
                id="nombre"
                onChange={handleChange}
                value={pagoCurrent?.nombre}
              />
            </div>
            <div className="grupo">
              <label className="label">Dias</label>
              <input
                type="text"
                className="text"
                placeholder="Dias"
                name="dias"
                id="dias"
                onChange={handleChange}
                value={pagoCurrent?.dias
                  ? pagoCurrent?.dias.toString().replace('.', ',')
                  : (pagoCurrent.dias = 0)}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
