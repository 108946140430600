import React, { useEffect,useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";
import moment from "moment";
export default function ListadoPresupuestos() {
  const {loadPresupuestos,presupuestosStore} = useStore();
  const data = useMemo(() => presupuestosStore, [presupuestosStore]);

  const columns = useMemo(
    () => [
      { Header: "Id", accessor: "id" },
      { Header: "Nombre cliente", accessor: "cliente.nombre" },
      { Header: "Fecha del evento", accessor: d => {
        return moment(d.fecha?.toDate())
          .local()
          .format("DD-MM-YYYY")
      } },
      { Header: "Lugar del evento", accessor: "espacio.nombre" },
      { Header: "Comensales", accessor: "pax" },
      { Header: "Total", accessor: d => {
        return `${d.total?.toFixed(2).toString().replace('.', ',')} €`
      } },
    ],
    []
  );
  useEffect(() => {
    loadPresupuestos()
  }, []);
  return <Table columns={columns} data={data} />;
}
