import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import moment from "moment/moment";

const Seguimiento = ({ evento, setEvento, persona, enable, idEvento }) => {
  registerLocale("es", es);
  const [fecha, setFecha] = useState(new Date());
  const [anotaciones, setAnotaciones] = useState("");

  const agregarFila = async () => {
    const nuevaFila = {
      id: new Date().getTime(),
      fecha,
      persona,
      anotaciones,
    };
    const nuevosSeguimientos = evento.seguimientos
      ? [...evento.seguimientos, nuevaFila]
      : [nuevaFila];
      console.log(nuevosSeguimientos)
    setEvento({ ...evento, seguimientos: nuevosSeguimientos });
    setFecha(new Date());
    setAnotaciones("");
  };

  return (
    <div className={idEvento ? (enable ? "enabled" : "disabled") : "enabled"}>
      <form>
        <div className="datos" style={{ border: "none" }}>
          <div className="columna" style={{ flex: 3 }}>
            <div className="grupo">
              <label className="label">Fecha:</label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                locale="es"
                placeholderText="Fecha"
                selected={fecha}
                onChange={(date) => setFecha(date)}
                showTimeSelect={false}
              />
            </div>
          </div>
          {/*<div className='columna' >
          <div className='grupo'>
          <label className='label'>Persona:</label>
          <input type="text"  className='text' value={persona} onChange={(e) => setPersona(e.target.value)} />
        </div>
      </div>
  */}
          <div className="columna " style={{ flex: 12 }}>
            <div className="grupo">
              <label className="label">Anotación:</label>
              <textarea
                className="text"
                value={anotaciones}
                onChange={(e) => setAnotaciones(e.target.value)}
              />
            </div>
          </div>
          <button type="button" onClick={agregarFila}>
            <AiOutlinePlusCircle size={"1.5em"} color="#CB950E" />
          </button>
        </div>
      </form>
      {evento.seguimientos?.length > 0 ? (
        <div className="datos">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              margin: "20px",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  display: "flex",
                  marginRight: "25px",
                }}
              >
                <th style={{ textAlign: "left", flex: 3 }}>Fecha</th>
                <th style={{ textAlign: "left", flex: 3 }}>Persona</th>
                <th style={{ textAlign: "left", flex: 9 }}>Anotación</th>
                <th style={{ textAlign: "left", flex: 1 }}></th>
              </tr>
            </thead>
            <tbody>
              {evento.seguimientos?.map((dato, index) => (
                <tr
                  key={dato.id}
                  style={{
                    display: "flex",
                    marginRight: "25px",
                    backgroundColor: index % 2 === 0 ? "white" : "#f2f2f2",
                  }}
                >
                  <td style={{ flex: 3 }}>{moment(dato?.fecha).format("DD/MM/YYYY")}</td>
                  <td style={{ flex: 3 }}>{dato.persona}</td>
                  <td style={{ flex: 12, whiteSpace: "pre-line" }}>
                    {dato.anotaciones}
                  </td>
                  <td style={{ flex: 1 }}>
                    {
                      <AiOutlineCloseCircle
                        size={"1.5em"}
                        color="#CB950E"
                        onClick={() => {
                          // Crea una nueva lista sin el elemento en el índice dado
                          var nuevaLista = evento.seguimientos.filter(
                            (_, i) => i !== index
                          );
                          // Actualiza el estado de la lista con la nueva lista
                          setEvento({
                            ...evento,
                            seguimientos: nuevaLista,
                          });
                        }}
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default Seguimiento;
