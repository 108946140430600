import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Cargando from "../cargando/cargando";
import Modal from "react-modal";
import FormProveedores from "../proveedores/FormProveedores";
import FamilaCompra from "../familiaCompra/familiaCompra";

export default function FormArticuloCompra() {
  Modal.setAppElement("#root");
  const {
    articuloCompraCurrent,
    setArticuloCompra,
    cargarArticuloCompra,
    loadProveedores,
    loadTiposIvas,
    loadMedidas,
    loadAlmacenes,
    loadModelos,
    modelosStore,
    loadFamiliasCompras,
    familiasCompraStore,
    articulosCompraStore,
    setFamiliaCompra,
    setUnsavedChanges,
  } = useStore();
  const [modalIsOpenFamilia, setIsOpenFamilia] = useState(false);
  function closeModalFamilia() {
    setIsOpenFamilia(false);
  }
  const [modalIsOpenProveedores, setIsOpenProveedores] = useState(false);
  function closeModalProveedores() {
    setIsOpenProveedores(false);
  }
  const [cargando, setCargando] = useState(true);
  const { insert, delDato, updateDato } = useAuth();

  const [enable, setEnable] = useState(false);
  const { idArticuloCompra } = useParams();
  const navigate = useNavigate();

  const handleChange = ({ target: { value, name } }) => {
    //controlamos que en estos camos solo se escriba numeros
    if (name === "unidades") {
      value = value.replace(/[^\d]/g, "");
    }
    if (
      name === "tamano" ||
      name === "precio" ||
      name === "cantidadClasico" ||
      name === "cantidadCoctel"
    ) {
      value = value.replace(/[^\d,]/g, "");
    }
    //modifcamos el valor en articulo
    setArticuloCompra({ ...articuloCompraCurrent, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (
          articuloCompraCurrent.nombre &&
          articuloCompraCurrent.nombre !== "" &&
          articuloCompraCurrent.nombre !== null &&
          //articuloCompraCurrent.iva &&
          articuloCompraCurrent.familia &&
          articuloCompraCurrent.modelo &&
          articuloCompraCurrent.precio &&
          articuloCompraCurrent.precio !== null &&
          //articuloCompraCurrent.categoria &&
          articuloCompraCurrent.precio !== ""
        ) {
          try {
            setUnsavedChanges(false);
            articuloCompraCurrent.precio = 
              typeof articuloCompraCurrent.precio === "string" ? parseFloat(articuloCompraCurrent.precio?.replace(",", ".")) : articuloCompraCurrent.precio
            articuloCompraCurrent.tamano =
            typeof articuloCompraCurrent.tamano === "string" ? parseFloat(
              articuloCompraCurrent.tamano?.replace(",", ".")
            ) : articuloCompraCurrent.tamano
            articuloCompraCurrent.cantidadClasico = typeof articuloCompraCurrent.cantidadClasico === "string" ? parseFloat(
              articuloCompraCurrent.cantidadClasico?.replace(",", ".")
            ) :articuloCompraCurrent.cantidadClasico
            articuloCompraCurrent.cantidadCoctel = typeof articuloCompraCurrent.cantidadCoctel === "string" ? parseFloat(
              articuloCompraCurrent.cantidadCoctel?.replace(",", ".")
            ) : articuloCompraCurrent.cantidadCoctel
            const id = await insert("articulosCompra", articuloCompraCurrent);
            navigate(`/articulosCompra/listado/${id}`);
          } catch (err) {console.log(err)}
        } else {
          showToast(
            "error",
            "Debes rellenar al menos el nombre, familia, modelo, grupo de impresión, precio de venta e iva del articulo"
          );
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al cliente?"
        );
        if (confirmDelete) {
          await delDato("articulosCompra", idArticuloCompra);
          setUnsavedChanges(false);
          navigate(`/articulosCompra/listado`);
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al cliente?"
        );
        if (confirmUpdate && articuloCompraCurrent.nombre) {
          try {
            setUnsavedChanges(false);
            await updateDato(
              "articulosCompra",
              idArticuloCompra,
              articuloCompraCurrent
            );
            navigate("/articulosCompra/listado");
          } catch (err) {
            showToast("Error", err);
          }
        } else if (confirmUpdate) {
          showToast("error", "Debes rellenar al menos el nombre del articulo");
        }
        break;
      case "Editar":
        console.log(articuloCompraCurrent);
        setUnsavedChanges(true);
        setEnable(true);
        break;
      default:
        break;
    }
  };
  async function datos() {
    await Promise.all([
      loadAlmacenes(),
      loadFamiliasCompras(),
      loadModelos(),
      loadMedidas(),
      loadProveedores(),
      loadTiposIvas(),
    ]);
    if (idArticuloCompra) {
      await cargarArticuloCompra(idArticuloCompra);
      if (articuloCompraCurrent === null) {
        showToast("error", "El articulo no existe");
        navigate("/articulosCompra");
      }
    }
    setCargando(false);
  }
  useEffect(() => {
    datos();
  }, []);

  useEffect(() => {
    console.log(articuloCompraCurrent);
  }, [articuloCompraCurrent]);

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">
            {idArticuloCompra ? "Modificar Articulo" : "Añadir Articulo"}
          </label>
          <div className="buttonMain">
            {idArticuloCompra || articuloCompraCurrent.id ? (
              enable ? (
                <>
                  <input
                    className="button main"
                    type="submit"
                    value="Modificar"
                    onClick={handleSubmit}
                  />
                  <input
                    type="submit"
                    className="button main"
                    value="Eliminar"
                    onClick={handleSubmit}
                  />
                </>
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Editar"
                  onClick={handleSubmit}
                />
              )
            ) : (
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
        <div
          className={
            idArticuloCompra || articuloCompraCurrent.id
              ? enable
                ? "enabled"
                : "disabled"
              : "enabled"
          }
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Nombre
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Nombre"
                  name="nombre"
                  id="nombre"
                  value={
                    articuloCompraCurrent.nombre
                      ? articuloCompraCurrent.nombre
                      : ""
                  }
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Familia
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      articuloCompraCurrent?.familia
                        ? articuloCompraCurrent?.familia
                        : ""
                    }
                    options={familiasCompraStore}
                    getOptionLabel={(familia) => familia.nombre}
                    getOptionValue={(familia) => familia.id}
                    onChange={(val) => {
                      setArticuloCompra({
                        ...articuloCompraCurrent,
                        familia: val,
                        modelo: null,
                        incluido: val.nombre==='KIT' ? null : articuloCompraCurrent.incluido
                      });
                      loadModelos(val.id);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      setArticuloCompra({
                        ...articuloCompraCurrent,
                        familia: {},
                      });
                      setFamiliaCompra({});
                      setIsOpenFamilia(true);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      setIsOpenFamilia(true);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={() => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar la familia?"
                      );
                      if (confirmDelete) {
                        console.log(articuloCompraCurrent);
                        delDato(
                          "familiasCompra",
                          articuloCompraCurrent.familia.id
                        );
                        setArticuloCompra({
                          ...articuloCompraCurrent,
                          familia: null,
                          modelo: null,
                        });
                        loadFamiliasCompras();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Modelo
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      articuloCompraCurrent.modelo
                        ? articuloCompraCurrent.modelo
                        : ""
                    }
                    options={modelosStore}
                    getOptionLabel={(modelo) => modelo.nombre}
                    getOptionValue={(modelo) => modelo.id}
                    onChange={(val) =>
                      setArticuloCompra({
                        ...articuloCompraCurrent,
                        modelo: { id: val.id, nombre: val.nombre },
                      })
                    }
                  />

                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      if (articuloCompraCurrent.familia) {
                        const nombre = prompt("Inserte el nuevo moodelo");
                        if (nombre !== "" && nombre !== null) {
                          const id = await insert("modelos", {
                            nombre,
                            familia: articuloCompraCurrent.familia,
                          });
                          setArticuloCompra({
                            ...articuloCompraCurrent,
                            modelo: { id, nombre },
                          });
                          loadModelos(articuloCompraCurrent.familia.id);
                        } else {
                          showToast(
                            "error",
                            "Debe selecionar una familia primero"
                          );
                        }
                      } else {
                        showToast(
                          "error",
                          "Primero debes de selecionar una familia"
                        );
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el modelo?"
                      );
                      if (confirmDelete) {
                        await delDato(
                          "modelos",
                          articuloCompraCurrent.modelo.id
                        );
                      }
                      loadModelos(articuloCompraCurrent.familia.id);
                      setArticuloCompra({
                        ...articuloCompraCurrent,
                        modelo: null,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna">
            <div className="grupo">
                <label htmlFor="" className="label">
                  Tamaño
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Tamaño"
                  name="tamano"
                  id="tamano"
                  value={
                    articuloCompraCurrent?.tamano
                      ? articuloCompraCurrent?.tamano
                          .toString()
                          .replace(".", ",")
                      : (articuloCompraCurrent.tamano = 0)
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Unidad de venta
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Unidad de venta"
                  name="unidades"
                  id="unidades"
                  value={
                    articuloCompraCurrent?.unidades
                      ? articuloCompraCurrent?.unidades
                          .toString()
                          .replace(".", ",")
                      : (articuloCompraCurrent.unidades = 0)
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Precio
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Precio de compra"
                  name="precio"
                  id="precio"
                  value={
                    articuloCompraCurrent?.precio
                      ? articuloCompraCurrent?.precio
                          .toString()
                          .replace(".", ",")
                      : (articuloCompraCurrent.precio = 0)
                  }
                  onChange={handleChange}
                />
              </div>
              {articuloCompraCurrent?.familia?.nombre==='KIT' ? <div className="grupo">
                <label htmlFor="" className="label">
                  Precio con transporte
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Precio de compra"
                  name="precioTransporte"
                  id="precioTransporte"
                  value={
                    articuloCompraCurrent?.precioTransporte
                      ? articuloCompraCurrent?.precioTransporte
                          .toString()
                          .replace(".", ",")
                      : (articuloCompraCurrent.precioTransporte = 0)
                  }
                  onChange={handleChange}
                />
              </div> : null}
              <div className="grupo">
                <label className="label">Calcular automaticamente</label>
                <div className="text switchText">
                  <label className="switch">
                    <input
                      type="checkbox"
                      defaultChecked={articuloCompraCurrent.automatico}
                      checked={articuloCompraCurrent.automatico}
                      onClick={() => {
                        articuloCompraCurrent.automatico =
                          !articuloCompraCurrent.automatico;
                        setArticuloCompra({
                          ...articuloCompraCurrent,
                          automatico: articuloCompraCurrent.automatico,
                        });
                      }}
                    />
                    <div className="slider round"></div>
                  </label>
                </div>
              </div>
              
             
            </div>
            <div className="columna">
            <div className="grupo">
                <label htmlFor="" className="label">
                  Cantidad por plaza Clasico
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Cantidad por plaza Coctel"
                  name="cantidadClasico"
                  id="cantidadClasico"
                  value={
                    articuloCompraCurrent?.cantidadClasico
                      ? articuloCompraCurrent?.cantidadClasico
                          .toString()
                          .replace(".", ",")
                      : (articuloCompraCurrent.cantidadClasico = 0)
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Cantidad por plaza Coctel
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Cantidad por plaza Coctel"
                  name="cantidadCoctel"
                  id="cantidadCoctel"
                  value={
                    articuloCompraCurrent?.cantidadCoctel
                      ? articuloCompraCurrent?.cantidadCoctel
                          .toString()
                          .replace(".", ",")
                      : (articuloCompraCurrent.cantidadCoctel = 0)
                  }
                  onChange={handleChange}
                />
              </div>
              
              
              <div className="grupo">
                <label className="label">Producto Asociado</label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={articulosCompraStore.find(
                      (proveedor) =>
                        proveedor.id === articuloCompraCurrent?.proveedor?.id
                    )}
                    options={articulosCompraStore.filter(
                      (articulo) => articulo.id !== idArticuloCompra
                    )}
                    getOptionLabel={(proveedor) => proveedor.nombre}
                    getOptionValue={(proveedor) => proveedor.id}
                    onChange={(val) => 
                      setArticuloCompra({
                        ...articuloCompraCurrent,
                        proveedor: { id: val.id, nombre: val.nombre },
                      })
                    }
                  />
                </div>
              </div>
              {articuloCompraCurrent?.familia?.nombre==='KIT' ? null :<div className="grupo">
                <label className="label">Incluido en la plaza</label>
                <div className="text switchText">
                  <label className="switch">
                    <input
                      type="checkbox"
                      defaultChecked={articuloCompraCurrent.incluido}
                      checked={articuloCompraCurrent.incluido}
                      onClick={() => {
                        articuloCompraCurrent.incluido =
                          !articuloCompraCurrent.incluido;
                        setArticuloCompra({
                          ...articuloCompraCurrent,
                          incluido: articuloCompraCurrent.incluido,
                        });
                      }}
                    />
                    <div className="slider round"></div>
                  </label>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={modalIsOpenFamilia}
        onRequestClose={closeModalFamilia}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",

            position: "absolute",
            right: "20px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalFamilia}
        />
        <FamilaCompra cerrar={closeModalFamilia} />
      </Modal>
      <Modal
        isOpen={modalIsOpenProveedores}
        onRequestClose={closeModalProveedores}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",
            height: "30px",
            width: "30px",
            position: "absolute",
            right: "20px",
          }}
          onClick={closeModalProveedores}
        />
        <FormProveedores
          cerrar={closeModalProveedores}
          idProveedorSelected={articuloCompraCurrent.proveedor?.id}
          llamadoDesde={"articulos"}
        />
      </Modal>
      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
    </div>
  );
}
