import { StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: {
    padding: 50,
    paddingTop: 0,
    fontSize: 8,
  },
  container: {
    width:"100%",
    height:"90%",
    flexDirection: "column",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  image: {
    width: 177,
    height: 76.2,
    marginBottom:20
  },
  encabezado: {
    width: "100%",
    height: 150,
    flexDirection: "row",
  },
  calibril9:{
    fontFamily:'Calibril',
    fontSize:9
  },
  calibril10:{
    fontFamily:'Calibril',
    fontSize:10
  },
  calibril12:{
    fontFamily:'Calibril',
    fontSize:12
  },
  calibril13:{
    fontFamily:'Calibril',
    fontSize:13
  },
  encabezadoIzquierdo: {
    display: "flex",
    justifyContent: "center",
    width: "45%",
    fontSize: 12,
  },
  encabezadoDerecho: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "55%",
    padding: 15,
  },
  contenedorCabeceraPresupuesto: {
    fontSize: 12,
    color: "#fff",
    backgroundColor: "#d1920e",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 7,
  },
  contenedorClientes: {
    height: 35,
    width: "100%",
    marginTop: 20,
  },
  cabeceraClientes: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    color: "#d1920e",
    borderBottom: 1,
    borderColor: "#d1920e",
    fontSize: 10,
    paddingBottom:5,
  },
  datosClientes: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    fontSize: 10,
    marginVertical: 5,
  },
  cabeceraContenedorMenu: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#d1920e",
    color: "#fff",
    fontSize: 10,
    padding: 5,
    marginTop:5,
  },
  textoIzquierdaCabeceraMenu: {
    width: "10%",
  },
  textoCentroCabeceraMenu:{
    width:"75%",
  },
  textoDerechaCabeceraMenu: {
    display: "flex",
    alignItems: "flex-end",
    width: "15%",
  },
  datosContenedorMenu: {
    display:"flex",
    flexDirection:"row",
    fontSize:10,
    padding:5,
    borderTop:1,
    borderColor:"#ededed",
  },
  datosMenu:{
    width:"60%",
    marginHorizontal:"12%",
    marginVertical:5,
  },
  lineaDivisoria:{
    width:"100%",
    borderBottom:1,
    marginVertical:5,
    borderColor:'#dedede'
  },
  contenedorTotal:{
    display:"flex",
    width:"100%",
    alignItems: "flex-end",
  },
  subcontenedorTotal:{
    display:"flex",
    flexDirection:"column",
    width:"30%",
  },
  datosTotal:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    width:"100%",
    fontSize:12,
    marginVertical:3,
    borderBottom:1,
    borderBottomColor:"#ededed",
  },
  pieDePagina:{
    width:"100%",
    height:"10%",
    display:"flex",
    justifyContent:"flex-end",
  },
  textoPieDePagina:{
    width:"100%",
    height:"100%",
    borderTop:1,
    borderColor:"#b2c1ce",
    padding:10,
  },
  textoCentroCabeceraMenu2:{
    width:"45%",
    marginRight:20,
  },
  textoDerechaCabeceraMenu2:{
    width:"45%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
  },
  contenedorInluye:{
    marginVertical:10,
    fontSize:9,
  },
  textoIncluye:{
    color:"#d1920e",
    marginBottom:3,
  },
});
export default styles;
