import { useNavigate } from "react-router-dom";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { GlobalFilter } from "./globalFilter";
import "../css/table.scss"

export const Table = ({ columns, data }) => {

  const navigate = useNavigate();

  const handleClick = (e,id) => {
    if (e.detail===2){
      navigate(`${id}`);
    }
    
  };
  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;
  

  return (
    <div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={state.globalFilter}
      />
      <table id="customers" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, id) => {
                if (id !== 0)
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▽"
                            : " △"
                          : ""}
                      </span>
                    </th>
                  );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={(e)=>handleClick(e,row.original.id)}
              >
                {row.cells.map((cell, idx) => {
                  if (idx !== 0)
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
