import React, { useEffect,useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";


export default function ListadoDocumentos() {
  const {loadDocumentos,documentosStore}=useStore()
  const data = useMemo(() => documentosStore, [documentosStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Tipo", accessor: "tipos.nombre" },
    ],
    []
  );

  useEffect(() => {
    loadDocumentos()
  }, []);
  return <Table columns={columns} data={data} />;
}
