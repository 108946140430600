import { createContext, useContext, useEffect, useState } from "react";
import {
  //createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  //sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  query,
  updateDoc,
  where,
  orderBy,
  getDocs,
  limit,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { auth, db, storage } from "../firebase";

const authContext = createContext();

//////crar Contexto
export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

///////////////Funciones de Auth///////////////////

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  /*const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };*/
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  const logout = () => signOut(auth);
  //const resetPassword = async (email) => sendPasswordResetEmail(auth, email);

  /////////////////////////// Actualizacion los datos de perfil
  const updateCurrentProfile = (displayName,photoURL) =>
    updateProfile(auth.currentUser, {
      displayName,
      photoURL
    });
  ///////////////////////Funciones de DB

  //crea un documanto en la coleccion

  const insert = async (tabla, data) => {
    try {
      const docRef = await addDoc(collection(db, tabla), data);
      console.log(tabla, "Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const authUser = async () => {
    return auth.currentUser.displayName;
  };

  const obtenerRegistroMaximo = async (serie) => {
    console.log(serie);
    let registroMaximo = 0;
    try {
      const citiesRef = collection(db, "ventas");
      const q = query(
        citiesRef,
        where("serie.id", "==", serie.id),
        orderBy("numero", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data().numero);
        registroMaximo = parseInt(doc.data().numero);
      });
    } catch (error) {
      console.log(error);
    }
    console.log(parseInt(registroMaximo + 1));
    return parseInt(registroMaximo + 1);
  };

  //eliminar un documento
  const delDato = (tabla, documento) => deleteDoc(doc(db, tabla, documento));

  //actualiza documento
  const updateDato = (tabla, documento, data) =>
    updateDoc(doc(db, tabla, documento), data);

  //subir file
  const uploadFile = async (file, nombre) => {
    console.log(file,nombre)
    try {
      const storageRef = ref(storage, nombre);
      await uploadBytes(storageRef, file);
      const url=await getDownloadURL(storageRef);
      alert("Condiciones actualizadas")
      console.log(url)
      return url;
    } catch (error) {
      console.log(error);
    }
  };
  const deleteFile = async (file) => {
    try {
      const archivoRef = ref(storage, file);

      // Elimina el archivo
      await deleteObject(archivoRef);

      console.log("Archivo eliminado exitosamente.");
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
    }
  };
  const readFile = async (nombre) => {
    const storageRef = ref(storage, nombre);
    try {
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      if (error.code === "404") {
        // The file does not exist.
        return null;
      } else {
        // An unexpected error occurred.
        throw error;
      }
    }
  };

  const listFiles = async (evento) => {
    let ficheros = [];
    const storageRef = ref(storage, `documentos/${evento}`);
    const listRef = ref(storage, storageRef);

    try {
      const res = await listAll(listRef);

      const fileData = await Promise.all(
        res.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return { name: itemRef.name, path: itemRef.fullPath, url };
        })
      );

      ficheros = fileData;
      return ficheros;
    } catch (error) {
      console.error("Error al listar archivos:", error);
      return [];
    }
  };

  useEffect(() => {
    const unsubuscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubuscribe();
  }, []);

  return (
    <authContext.Provider
      value={{
        //signup,
        login,
        user,
        logout,
        loading,
        //resetPassword,
        updateCurrentProfile,
        deleteFile,
        insert,
        readFile,
        obtenerRegistroMaximo,
        delDato,
        listFiles,
        updateDato,
        uploadFile,
        authUser,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
