import { CrearContacto, eliminarContactos } from "./crearContacto";
import Modal from "react-modal";
import { useState } from "react";
import { useStore } from "../../store/appStore";
import { BsFillEraserFill, BsFillPencilFill } from "react-icons/bs";
export const CardContactos = (props) => {
  const {
    agenteCurrent,
    setAgente,
    clienteCurrent,
    setCliente,
    proveedorCurrent,
    setProveedor,
  } = useStore();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [contactoSeleccionado, setContactoSeleccionado] = useState(null);

  function closeModal() {
    setIsOpen(false);
    setContactoSeleccionado(null);
  }

  return (
    <div className="tarjetas">
      {props.contactos?.map((contacto,i) => {
        console.log(contacto.nombre);
        return (
          <div key={contacto.cifDni} className="card">
            <div className="contactName-Edit">
              <label className="label">{contacto.nombre}</label>
              <div className="iconosDiv">
                <div
                  className="icono" onClick={()=>{setIsOpen(true); setContactoSeleccionado(contacto);}}
                >
                  <BsFillPencilFill/>
                </div>
                <div className="icono" onClick={()=>eliminarContactos(contacto.id, props.contactos, setCliente, clienteCurrent, setAgente, agenteCurrent, setProveedor, proveedorCurrent, props.llamado)}>
                  <BsFillEraserFill/>
                </div>
              </div>
            </div>
            <div className="cardContent">

              <label>Cif/Dni: {contacto.cifDni}</label>
              <label>Telefono: {contacto.telefono}</label>
              <label>E-Mail: {contacto.mail}</label>
              <label>Dirección: {contacto.direccion}</label>
              <label>
                Localidad: {contacto.localidad}
              </label>
              <label>CP: {contacto.cp}</label>
              <label>
                Provincia: {contacto.provincia} Pais: {contacto.pais}
              </label>
            </div>
          </div>
        );
      })}
      <div className="card">
        <div className="cardContent signo" onClick={() => setIsOpen(true)}>
          +
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <CrearContacto 
        llamado={props.llamado} 
        contactos={props.contactos} 
        cerrar={closeModal} 
        contactoSeleccionado={contactoSeleccionado}
        />
      </Modal>
    </div>
  );
};
