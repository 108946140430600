import React, { useEffect, useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";

export default function ListadoCombinado() {
  const { loadCombinados, combinadosStore } = useStore();

  const data = useMemo(() => combinadosStore, [combinadosStore]);

  const columns = useMemo(
    () => [
      { Header: "Id", accessor: "id" },
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Tipo de articulo", accessor: "tipo.nombre" },
      { Header: "Precio", accessor: d => { 
        return `${d.precioVenta.toString().replace('.', ',')} €`;
        
      } },
    ],
    []
  );
  useEffect(() => {
    loadCombinados();
  }, []);
  return <Table columns={columns} data={data} />;
}
