import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { showToast } from "../../utils/utils";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Cargando from "../cargando/cargando";

export default function FormDocumentos() {
  Modal.setAppElement("#root");
  const { uploadFile, readFile } = useAuth();
  const [presupuestoPdf, setPresupuestoPdf] = useState();
  const [facturaPdf, setFacturaPdf] = useState();
  const [esDocumentoPdf, setEsDocumentoPdf] = useState();
  const [esPresupuestoPdf, setEsPresupuestoPdf] = useState();
  const [modalIsOpenDoc, setIsOpenDoc] = useState(false);
  const [cargando, setCargando] = useState(true);

  function closeModalDoc() {
    setIsOpenDoc(false);
  }
  const [filePresupuesto, setFilePresupuesto] = useState();
  const [fileFactura, setFileFactura] = useState();
  const [fileAbrir, setFileAbrir] = useState("parte");
  useEffect(() => {
    cargarUrls();
    async function cargarUrls() {
      const urlPresupuesto = await readFile("documentos/presupuesto");
      const urlFactura = await readFile("documentos/factura");
      setFilePresupuesto(urlPresupuesto);
      setFileFactura(urlFactura);
      setCargando(false);
    }
  }, []);

  return (
    <div className="form">
      <div className={"enabled"}>
        <h1>Presupuesto</h1>
        <div className="datos">
          <div className="columna">
            <div className="grupo">
              <input
                type="file"
                accept=".pdf"
                name="documentos"
                id="documentos"
                onChange={(e) => {
                  if (
                    e.target.files[0].name.substring(
                      e.target.files[0].name.length - 4
                    ) === ".pdf"
                  ) {
                    setPresupuestoPdf(e.target.files[0]);
                    setEsPresupuestoPdf(true);
                  } else {
                    showToast("error", "Solo se pueden subir documentos pdf");
                  }
                }}
              />
              <button
                type="submit"
                className="button"
                onClick={async () => {
                  if (esPresupuestoPdf) {
                    await uploadFile(
                      presupuestoPdf,
                      "documentos/presupuesto"
                    );
                  } else {
                    showToast("error", "Solo se pueden subir documentos pdf");
                  }
                }}
              >
                Subir
              </button>
            </div>
          </div>
          <div className="columna">
            <div className="grupo">
              <label htmlFor="" style={{ flex: 3 }} className="label">
                Ver condiciones actuales de presupuestos
              </label>
              <button
                type="submit"
                style={{ flex: 1 }}
                className="button"
                onClick={async () => {
                  setCargando(true);
                  setFileAbrir(filePresupuesto);
                  setIsOpenDoc(true);
                }}
              >
                Ver
              </button>
            </div>
          </div>
        </div>
        <h1>Factura</h1>
        <div className="datos">
          <div className="columna">
            <div className="grupo">
              <input
                type="file"
                accept=".pdf"
                name="documentos"
                id="documentos"
                onChange={(e) => {
                  if (
                    e.target.files[0].name.substring(
                      e.target.files[0].name.length - 4
                    ) === ".pdf"
                  ) {
                    setFacturaPdf(e.target.files[0]);
                    setEsDocumentoPdf(true);
                  } else {
                    showToast("error", "Solo se pueden subir documentos pdf");
                  }
                }}
              />
              <button
                type="submit"
                className="button"
                onClick={async () => {
                  if (esDocumentoPdf) {
                    const subido = await uploadFile(
                      facturaPdf,
                      "documentos/factura"
                    );
                    if (subido) {
                      setFacturaPdf(null);
                      setEsDocumentoPdf(false);
                    }
                  } else {
                    showToast("error", "Solo se pueden subir documentos pdf");
                  }
                }}
              >
                Subir
              </button>
            </div>
          </div>
          <div className="columna">
            <div className="grupo">
              <label htmlFor="" className="label" style={{ flex: 3 }}>
                Ver condiciones actuales de facturas
              </label>
              <button
                type="submit"
                style={{ flex: 1 }}
                className="button"
                onClick={async () => {
                  setCargando(true);
                  setFileAbrir(fileFactura);
                  setIsOpenDoc(true);
                }}
              >
                Ver
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpenDoc}
        onRequestClose={closeModalDoc}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "30px",

            position: "absolute",
            right: "30px",
            color: "red",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalDoc}
        />
        <object
          data={fileAbrir}
          style={{ height: "100%", width: "100%" }}
          onLoad={() => setCargando(false)}
          alt="mensaje"
        >
          Fichero PDF no cargado correctamente
        </object>
      </Modal>
      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
    </div>
  );
}
