import React, { useEffect, useState} from 'react';
import { useStore } from "../../store/appStore";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const CardVentas = (props) => {
  const [datos, setDatos] = useState();

  const navigate = useNavigate();
  const { clienteCurrent, loadVentasFiltradas } = useStore();
  

  useEffect(() => {
    console.log(clienteCurrent.cifDni, "id");
    loadVentasFiltradas(clienteCurrent.cifDni)
      .then((ventas) => {
        setDatos(ventas);
        console.log("Ventas filtradas:", ventas);
      })
      .catch((error) => {
        console.error("Error al cargar Ventas:", error);
      });
    console.log(datos);
  }, []);
  if (datos)
    return (
      <div className="cardPresupuestos">
        <div className="divCardPresupuestos">
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
  <thead>
    <tr style={{ backgroundColor: '#f2f2f2' }}>
      <th style={{ textAlign: "left" }}>Fecha</th>
      <th style={{ textAlign: "left" }}>Número</th>
      <th style={{ textAlign: "left" }}>Referencia</th>
      <th style={{ textAlign: "left" }}>Total</th>
      <th style={{ textAlign: "left" }}>Total con Iva</th>
    </tr>
  </thead>
  <tbody>
    {datos.map((dato, index) => (
      <tr
      key={dato.id}
      style={{
        backgroundColor: index % 2 === 0 ? "white" : "#f2f2f2",
      }}
      onDoubleClick={() =>
        navigate(`/facturas/listado/${dato.id}`)
      }
    >
        <td>{moment(dato?.fecha?.toDate()).format("DD/MM/YYYY")}</td>
        <td>{dato.numero}</td>
        <td>{dato.ref}</td>
        <td>{dato.total}</td>
        <td>{dato.totalConIva}</td>
      </tr>
    ))}
  </tbody>
</table>
        </div>
      </div>
    )
};