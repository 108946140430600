import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import "../css/sidebar.scss";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/appStore";
export const Sidebar = (props) => {
  const { limpiarCurrent, unsavedChanges } = useStore();
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const [expander, setExpander] = useState(true);

  //expander o contraer el menu
  const expanderAction = () => {
    setExpander((current) => !current);
  };

  const confirmNavigation = (e) => {
    console.log(e, unsavedChanges);
    if (unsavedChanges)
      if (
        !window.confirm(
          "¿Estás seguro de que deseas continuar sin guardar los cambios?"
        )
      ) {
        return;
      }
    limpiarCurrent();
    setSelected(e);
    enviar(e);
  };

  //enviamos a home el menu selecionado
  const enviar = (menu) => {
    navigate("/" + menu + "/listado");
    props.sendMenu(menu);
  };
  return (
    <div
      //onClick={() => limpiarCurrent()}
      style={{
        height: props.data === "" ? "calc(100vh - 45px)" : "calc(100vh - 69px)",
      }}
      className={expander ? "sidebar" : "Sidebar collapsed"}
    >
      <div className="menu">
        <div className="item hamburger-menu" onClick={expanderAction}>
          <div className="itemoicon">
            <GiHamburgerMenu className="icons" />
          </div>
          <div className="itemtext">Menu</div>
        </div>
        <div
          className={selected === "clientes" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("clientes")}
        >
          <div className="itemoicon">
            <div className="icons icon-clientes"></div>
          </div>
          <div className="itemtext">Clientes</div>
        </div>
        <div
          className={selected === "proveedores" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("proveedores")}
        >
          <div className="itemoicon">
            <div className="icons icon-proveedores"></div>
          </div>
          <div className="itemtext">Proveedores</div>
        </div>
        <div
          className={selected === "agentes" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("agentes")}
        >
          <div className="itemoicon">
            <div className="icons icon-agentes"></div>
          </div>
          <div className="itemtext">Comerciales</div>
        </div>
        <div
          className={selected === "articulos" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("articulos")}
        >
          <div className="itemoicon">
            <div className="icons icon-articulos"></div>
          </div>
          <div className="itemtext">Articulos</div>
        </div>
        <div
          className={selected === "combinados" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("combinados")}
        >
          <div className="itemoicon">
            <div className="icons icon-combinados"></div>
          </div>
          <div className="itemtext">Articulos Combinados</div>
        </div>
        <div
          className={selected === "presupuestos" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("presupuestos")}
        >
          <div className="itemoicon">
            <div className="icons icon-presupuestos"></div>
          </div>
          <div className="itemtext">Presupuestos</div>
        </div>
        <div
          className={selected === "facturas" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("facturas")}
        >
          <div className="itemoicon">
            <div className="icons icon-facturas"></div>
          </div>
          <div className="itemtext">Facturas de venta</div>
        </div>
        <div
          className={selected === "articulosCompra" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("articulosCompra")}
        >
          <div className="itemoicon">
            <div className="icons icon-articulosCompra"></div>
          </div>
          <div className="itemtext">Articulos de compra</div>
        </div>
        <div
          className={selected === "pedido" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("pedido")}
        >
          <div className="itemoicon">
            <div className="icons icon-pedido"></div>
          </div>
          <div className="itemtext">Pedidos a proveedores</div>
        </div>
        <div
          className={
            selected === "facturasCompras" ? "item menuActive" : "item "
          }
          onClick={() => confirmNavigation("facturasCompras")}
        >
          <div className="itemoicon">
            <div className="icons icon-facturas-compras"></div>
          </div>
          <div className="itemtext">Facturas de compra</div>
        </div>
        <div
          className={selected === "espacios" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("espacios")}
        >
          <div className="itemoicon">
            <div className="icons icon-espacios"></div>
          </div>
          <div className="itemtext">Espacios</div>
        </div>
        {/*<div
          className={selected === "trabajadores" ? "item menuActive" : "item "}
          onClick={() => 
            confirmNavigation("trabajadores")
          }
        >
          <div className="itemoicon">
            <div className="icons icon-trabajadores"></div>
          </div>
          <div className="itemtext">Trabajadores</div>
        </div>*/}
        <div
          className={selected === "documentos" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("documentos")}
        >
          <div className="itemoicon">
            <div className="icons icon-documentos"></div>
          </div>
          <div className="itemtext">Documentos</div>
        </div>
        <div
          className={selected === "eventos" ? "item menuActive" : "item "}
          onClick={() => confirmNavigation("eventos")}
        >
          <div className="itemoicon">
            <div className="icons icon-calendario"></div>
          </div>
          <div className="itemtext">Eventos</div>
        </div>
      </div>
    </div>
  );
};
