import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import Select from "react-select";
import { useStore } from "../../store/appStore";
import Cargando from "../cargando/cargando";
import FormProveedores from "../proveedores/FormProveedores";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
export default function FormEspacios(props) {
  const {
    espacioCurrent,
    presupuestoCurrent,
    setEspacio,
    cargarEspacio,
    cargarProveedor,
    loadTiposComisiones,
    tiposComisionesStore,
    proveedoresStore,
    setProveedor,
    setPresupuesto,
    loadProveedores,
    loadEspacios,
    setUnsavedChanges,
  } = useStore();
  const { cerrar, idEspacioSelected, llamadoDesde } = props;
  const [ubicacion, setUbicacion] = useState();
  const { insert, delDato, updateDato } = useAuth();
  const { id } = useParams();
  let { idEspacio } = useParams();
  const [cargando, setCargando] = useState(true);
  const navigate = useNavigate();
  const [enable, setEnable] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const handlerEliminar = (indice) => {
    console.log(espacioCurrent);
    // Crea una nueva lista sin el elemento en el índice dado
    var nuevaLista = espacioCurrent.ubicaciones.filter((_, i) => i !== indice);

    // Actualiza el estado de la lista con la nueva lista
    setEspacio({
      ...espacioCurrent,
      ubicaciones: nuevaLista,
    });
  };

  async function datos() {
    await Promise.all([loadTiposComisiones(), loadProveedores()]);
    if (idEspacio) {
      await cargarEspacio(idEspacio);
      if (espacioCurrent === null) {
        showToast("error", "El articulo no existe");
        navigate("/articulos");
      }
    }
    setCargando(false);
  }
  useEffect(() => {
    datos();
  }, []);
  const handleChange = ({ target: { value, name } }) => {
    console.log(value,name)
    if (name === "telefono") {
      value = value.replace(/[^\d]/g, "");
    }
    if (name === "canon" || name === "comision"|| name === "km") {
      value = value.replace(/[^\d,]/g, "");
    }
    if (name === "localizacion") {
      setUbicacion(value);
    }
    setEspacio({ ...espacioCurrent, [name]: value });
  };

  const handleSubmit = async (e) => {
    if (!idEspacio) idEspacio = espacioCurrent.id;
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (espacioCurrent.nombre) {
          try {
            setUnsavedChanges(false);
            espacioCurrent.canon = parseFloat(
              espacioCurrent.canon?.toString().replace(",", ".")
            );
            espacioCurrent.km = parseFloat(espacioCurrent.km?.toString().replace(",", "."));
            espacioCurrent.comision = parseFloat(
              espacioCurrent.comision?.toString().replace(",", ".")
            );
            const id = await insert("espacios", espacioCurrent);
            setEspacio({ ...espacioCurrent, id });
            if (presupuestoCurrent.pax) {
              setPresupuesto({
                ...presupuestoCurrent,
                espacio: { espacioCurrent, id },
              });
              loadEspacios();
              cerrar();
            } else navigate(`/espacios/listado/${id}`);
          } catch (err) {}
        } else {
          showToast("error", "Al menos debes de rellenar el nombre");
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar el espacio?"
        );
        if (confirmDelete) {
          try {
            setUnsavedChanges(false);
            switch (llamadoDesde) {
              case "presupuestos":
                console.log(espacioCurrent);
                await delDato("espacios", idEspacioSelected);
                setPresupuesto({ ...presupuestoCurrent, espacio: null });
                loadEspacios();
                cerrar();
                break;
              default:
                await delDato("espacios", idEspacio);
                navigate(`/espacios/listado`);
                break;
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar el espacio?"
        );
        if (confirmUpdate && espacioCurrent.nombre) {
          espacioCurrent.canon = parseFloat(
            espacioCurrent.canon?.toString().replace(",", ".")
          );
          espacioCurrent.km = parseFloat(espacioCurrent.km?.toString().replace(",", "."));
          espacioCurrent.comision = parseFloat(
            espacioCurrent.comision?.toString().replace(",", ".")
          );
          try {
            setUnsavedChanges(false);
            switch (llamadoDesde) {
              case "presupuestos":
                console.log(espacioCurrent.nombre);
                await updateDato(
                  "espacios",
                  presupuestoCurrent.espacio.id,
                  espacioCurrent
                );
                loadEspacios();
                setPresupuesto({
                  ...presupuestoCurrent,
                  espacio: {
                    espacioCurrent,
                    id: presupuestoCurrent.espacio.id,
                  },
                });
                cerrar();
                break;
              default:
                if (!espacioCurrent.comisiona) {
                  espacioCurrent.comision = 0;
                  espacioCurrent.tipoComision = "";
                }
                console.log("espacios", idEspacio, espacioCurrent.id);

                await updateDato("espacios", idEspacio, espacioCurrent);
                navigate("/espacios/listado");
            }
          } catch (err) {
            console.log(err);
          }
        } else if (confirmUpdate) {
          showToast("error", "Al menos debes de rellenar el nombre");
        }
        break;
      case "Editar":
        setUnsavedChanges(true);
        setEnable(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">
            {idEspacio ? "Modificar" : "Añadir"} Espacio
          </label>
          <div className="buttonMain">
            {idEspacio || idEspacioSelected ? (
              enable ? (
                <>
                  <input
                    className="button main"
                    type="submit"
                    value="Modificar"
                    onClick={handleSubmit}
                  />
                  <input
                    type="submit"
                    className="button main"
                    value="Eliminar"
                    onClick={handleSubmit}
                  />
                </>
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Editar"
                  onClick={handleSubmit}
                />
              )
            ) : (
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
        <div
          className={idEspacio ? (enable ? "enabled" : "disabled") : "enabled"}
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label className="label">Nombre</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Nombre"
                  name="nombre"
                  id="nombre"
                  onChange={handleChange}
                  value={espacioCurrent?.nombre ? espacioCurrent?.nombre : ""}
                />
              </div>
              <div className="grupo">
                <label className="label">Persona de contacto</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Persona de contacto"
                  name="contacto"
                  id="contacto"
                  onChange={handleChange}
                  value={
                    espacioCurrent?.contacto ? espacioCurrent?.contacto : ""
                  }
                />
              </div>

              <div className="grupo">
                <label className="label">telefono</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Telefono"
                  name="telefono"
                  id="telefono"
                  onChange={handleChange}
                  value={
                    espacioCurrent?.telefono ? espacioCurrent?.telefono : ""
                  }
                />
              </div>
              <div className="grupo">
                <label className="label">E-Mail</label>
                <input
                  className="text"
                  type="text"
                  placeholder="E-Mail"
                  name="mail"
                  id="mail"
                  onChange={handleChange}
                  value={espacioCurrent?.mail ? espacioCurrent?.mail : ""}
                />
              </div>
              <div className="grupo">
                <label className="label">Proveedor Asociado</label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={
                      espacioCurrent?.proveedor?.id
                        ? proveedoresStore.find(
                            (proveedor) =>
                              proveedor.id === espacioCurrent?.proveedor?.id
                          )
                        : null
                    }
                    options={proveedoresStore}
                    getOptionLabel={(proveedor) => proveedor.nombre}
                    getOptionValue={(proveedor) => proveedor.id}
                    onChange={(val) =>
                      setEspacio({ ...espacioCurrent, proveedor: val })
                    }
                  />
                </div>

                <input
                  className="button"
                  type="submit"
                  value="Añadir"
                  onClick={() => {
                    if (espacioCurrent.nombre) {
                      setEspacio({ ...espacioCurrent, proveedor: null });
                      setProveedor({});
                      setIsOpen(true);
                    } else {
                      showToast(
                        "error",
                        "Al menos debes de rellenar el nombre del Espacio"
                      );
                    }
                  }}
                />

                <input
                  className="button"
                  type="submit"
                  value="Ver"
                  onClick={() => {
                    cargarProveedor(espacioCurrent?.proveedor?.id);
                    setIsOpen(true);
                  }}
                />
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label className="label">Dirección</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Dirección"
                  name="direccion"
                  id="direccion"
                  onChange={handleChange}
                  value={
                    espacioCurrent?.direccion ? espacioCurrent?.direccion : ""
                  }
                />
              </div>
              <div className="grupo">
                <label className="label">Localidad</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Localidad"
                  name="localidad"
                  id="localidad"
                  onChange={handleChange}
                  value={
                    espacioCurrent?.localidad ? espacioCurrent?.localidad : ""
                  }
                />
              </div>

              <div className="grupo">
                <label className="label">Provincia</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Provincia"
                  name="provincia"
                  id="provincia"
                  onChange={handleChange}
                  value={
                    espacioCurrent?.provincia ? espacioCurrent?.provincia : ""
                  }
                />
              </div>
              <div className="grupo">
                <label className="label">Enlace a map</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Enlace google Map"
                  name="enlace"
                  id="enlace"
                  onChange={handleChange}
                  value={espacioCurrent?.enlace ? espacioCurrent?.enlace : ""}
                />
              </div>

              <div className="grupo">
                <label className="label">Kilometros</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Distancia"
                  name="km"
                  id="km"
                  onChange={handleChange}
                  value={espacioCurrent?.km ? espacioCurrent?.km : ""}
                />
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label className="label">Canon</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Canon"
                  name="canon"
                  id="canon"
                  onChange={handleChange}
                  value={
                    espacioCurrent?.canon
                      ? espacioCurrent?.canon.toString().replace(".", ",")
                      : (espacioCurrent.canon = "0")
                  }
                />
              </div>
              <div className="grupo">
                <label className="label">Comisiona</label>
                <div className="text switchText">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={espacioCurrent?.comisiona}
                      defaultChecked={espacioCurrent?.comisiona}
                      onClick={() => {
                        espacioCurrent.comisiona = !espacioCurrent.comisiona;
                        setEspacio({
                          ...espacioCurrent,
                          comisiona: espacioCurrent?.comisiona,
                        });
                        console.log(espacioCurrent);
                      }}
                    />
                    <div className="slider round"></div>
                  </label>
                </div>
              </div>
              <div
                className={
                  espacioCurrent?.comisiona ? "grupo visible" : "grupo hidden"
                }
              >
                <label className="label">Tipo de Comisión</label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={espacioCurrent?.tipoComision}
                    options={tiposComisionesStore}
                    getOptionLabel={(tipo) => tipo.nombre}
                    getOptionValue={(tipo) => tipo.id}
                    onChange={(val) =>
                      setEspacio({ ...espacioCurrent, tipoComision: val })
                    }
                  />
                </div>
              </div>
              <div
                className={
                  espacioCurrent?.comisiona ? "grupo visible" : "grupo hidden"
                }
              >
                <label className="label">Comisión</label>
                <input
                  type="text"
                  className="text"
                  placeholder="  Comisión"
                  name="comision"
                  id="comision"
                  onChange={handleChange}
                  value={
                    espacioCurrent?.comision
                      ? espacioCurrent?.comision.toString().replace(".", ",")
                      : (espacioCurrent.comision = 0)
                  }
                />
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label className="label">Localizaciones</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Localización a añadir"
                  name="localizacion"
                  id="localizacion"
                  onChange={handleChange}
                  value={ubicacion}
                />
                <input
                  className="button"
                  type="submit"
                  value="Añadir"
                  onClick={() => {
                    var newUbicaciones = espacioCurrent?.ubicaciones;
                    if (!newUbicaciones) newUbicaciones = [];
                    if (ubicacion) newUbicaciones.push(ubicacion);
                    setEspacio({
                      ...espacioCurrent,
                      ubicaciones: newUbicaciones,
                    });
                    setUbicacion("");
                  }}
                />
              </div>
              <div className="grupo">
                <ul className="list">
                  {espacioCurrent?.ubicaciones?.map((ubi, i) => {
                    return (
                      <li className="itemList" key={i}>
                        <label className="label">{ubi}</label>
                        <input
                          className="button"
                          type="submit"
                          value="Eliminar"
                          onClick={() => handlerEliminar(i)}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",

            position: "absolute",
            right: "20px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModal}
        />
        <FormProveedores
          cerrar={closeModal}
          llamadoDesde={"espacios"}
          idProveedorSelected={espacioCurrent?.proveedor?.id}
        />
      </Modal>
      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
    </div>
  );
}
