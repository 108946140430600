import { create } from "zustand";
import { db } from "../firebase";
import {
  collection,
  getDoc,
  doc,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";

async function dato(table, document) {
  const documento = await getDoc(doc(db, table, document));
  if (documento.exists()) {
    return documento.data();
  } else {
    console.log("Note doesn't exist");
  }
}

export const useStore = create((set, get) => ({
  //Array con los datos del firestore
  clientesStore: [],
  proveedoresStore: [],
  agentesStore: [],
  articulosStore: [],
  articulosCompraStore: [],
  combinadosStore: [],
  presupuestosStore: [],
  eventosStore: [],
  ventasStore: [],
  comprasStore: [],
  espaciosStore: [],
  trabajadoresStore: [],
  modelosStore: [],
  familiasComprasStore: [],

  familiasStore: [],
  categoriasStore: [],
  seriesStore: [],
  tiposArticulosStore: [],
  tiposCombinadosStore: [],
  tiposIvasStore: [],
  tiposClientesStore: [],
  tiposPresupuestosStore: [],
  tiposProveedoresStore: [],
  tiposIdentificacionesStore: [],
  pagosStore: [],
  tiposComisionesStore: [],
  tiposComercialesStore: [],
  almacenesStore: [],
  pedidosStore: [],
  medidaStore: [],
  subFamiliasStore: [],
  contactosStore: [],
  timing: [],
  familiasCompraStore:[],

  //datos del objeto actual
  clienteCurrent: {},
  proveedorCurrent: {},
  agenteCurrent: {},
  articuloCurrent: {},
  presupuestoCurrent: {},
  eventoCurrent: {},
  espaciosCurrent: {},
  ivaCurrent: {},
  combinadoCurrent: {},
  espacioCurrent: {},
  trabajadoresCurrent: {},
  familaCompraCurrent:{},
  contactosCurrent: {},
  pedidoCurrent: {},
  articuloCompraCurrent:{},
  //limpiar Current
  limpiarCurrent: () => {
    set((state) => ({
      ...state,
      articuloCompraCurrent:{},
      clienteCurrent: {},
      agenteCurrent: {},
      proveedorCurrent: {},
      articuloCurrent: {},
      familaCompraCurrent:{},
      combinadoCurrent: {},
      espacioCurrent: {},
      presupuestoCurrent: {},
      eventoCurrent: {},
      ventaCurrent: {},
      compraCurrent: {},
      pagoCurrent: {},
      ivaCurrent: {},
      tipoEventoCurrent:{},
      pedidoCurrent:{},
      trabajadoresCurrent: {},
      contactosCurrent: {},
      unsavedChanges: false,
    }));
  },
  unsavedChanges: false,
  setUnsavedChanges: (value) => set({ unsavedChanges: value }),
  //ModificaStore
  setCliente: (clienteCurrent) => set({ clienteCurrent }),
  setProveedor: (proveedorCurrent) => set({ proveedorCurrent }),
  setAgente: (agenteCurrent) => set({ agenteCurrent }),
  setArticulo: (articuloCurrent) => set({ articuloCurrent }),
  setArticuloCompra: (articuloCompraCurrent) => set({ articuloCompraCurrent }),
  setFamiliaCompra: (familaCompraCurrent) => set({ familaCompraCurrent }),
  setIva: (ivaCurrent) => set({ ivaCurrent }),
  setCombinado: (combinadoCurrent) => set({ combinadoCurrent }),
  setEspacio: (espacioCurrent) => set({ espacioCurrent }),
  setPresupuesto: (presupuestoCurrent) => set({ presupuestoCurrent }),
  setPedido: (pedidoCurrent) => set({ pedidoCurrent }),
  setEvento: (eventoCurrent) => set({ eventoCurrent }),
  setVenta: (ventaCurrent) => set({ ventaCurrent }),
  setCompra: (compraCurrent) => set({ compraCurrent }),
  setPago: (pagoCurrent) => set({ pagoCurrent }),
  setTipoEvento:(tipoEventoCurrent)=>set({tipoEventoCurrent}),
  setTrabajadores: (trabajadoresCurrent) => set({ trabajadoresCurrent }),
  setContactos: (contactosCurrent) => set({ contactosCurrent }),

  //Funciones cargan colecciones
  loadClientes: async () => {
    const unsubscribe = onSnapshot(collection(db, "clientes"), (snapshot) => {
      const clientesStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ clientesStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadProveedores: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "proveedores"),
      (snapshot) => {
        const proveedoresStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ proveedoresStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadPedidos: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "pedidos"),
      (snapshot) => {
        const pedidosStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ pedidosStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadAgentes: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "comerciales"),
      (snapshot) => {
        const agentesStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ agentesStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadTiposEventos: async () => {
    const unsubscribe = onSnapshot(
      collection(db, " tiposEventos "),
      (snapshot) => {
        const tiposEventosStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposEventosStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadTiming: async (evento) => {
    const q = query(collection(db, "timing"), where("evento", "==", evento));
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    const timing = [];
    querySnapshot.forEach((doc) => {
      timing.push({ id: doc.id, ...doc.data() });
    });
    return timing;
  },
  loadCategorias: async () => {
    const unsubscribe = onSnapshot(collection(db, "categorias"), (snapshot) => {
      const categoriasStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ categoriasStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadArticulos: async () => {
    const unsubscribe = onSnapshot(collection(db, "articulos"), (snapshot) => {
      const articulosStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ articulosStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadArticulosCompra: async () => {
    const unsubscribe = onSnapshot(collection(db, "articulosCompra"), (snapshot) => {
      const articulosCompraStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ articulosCompraStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadCombinados: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "articulosCombinados"),
      (snapshot) => {
        const combinadosStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ combinadosStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadPresupuestos: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "presupuestos"),
      (snapshot) => {
        const presupuestosStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ presupuestosStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadPresupuestosFiltrados: async (clienteId, fecha,lugar) => {
    let q
    if (fecha) {
       q = query(
        collection(db, "presupuestos"),
        where("cliente.cifDni", "==", clienteId),
        where("fecha","==",fecha),
        where("espacio.id","==",lugar)
      );
    }else{
       q = query(
        collection(db, "presupuestos"),
        where("cliente.cifDni", "==", clienteId)
      );
    }
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    const presupuestos = [];
    querySnapshot.forEach((doc) => {
      presupuestos.push({ id: doc.id, ...doc.data() });
    });
    return presupuestos;
  },

  loadVentasFiltradas: async (clienteId) => {
    console.log("id", clienteId);
    const q = query(
      collection(db, "ventas"),
      where("cliente.cifDni", "==", clienteId)
    );
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    const ventas = [];
    querySnapshot.forEach((doc) => {
      ventas.push({ id: doc.id, ...doc.data() });
    });
    return ventas;
  },

  loadEventosFiltrados: async (clienteId) => {
    console.log("id", clienteId);
    const q = query(
      collection(db, "eventos"),
      where("cliente.cifDni", "==", clienteId)
    );
    const querySnapshot = await getDocs(q);
    const eventos = [];
    querySnapshot.forEach((doc) => {
      eventos.push({ id: doc.id, ...doc.data() });
    });
    return eventos;
  },

  loadEventos: async () => {
    const unsubscribe = onSnapshot(collection(db, "eventos"), (snapshot) => {
      const eventosStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ eventosStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadVentas: async () => {
    const unsubscribe = onSnapshot(collection(db, "ventas"), (snapshot) => {
      const ventasStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ ventasStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadCompras: async () => {
    const unsubscribe = onSnapshot(collection(db, "compras"), (snapshot) => {
      const comprasStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ comprasStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadEspacios: async () => {
    const unsubscribe = onSnapshot(collection(db, "espacios"), (snapshot) => {
      const espaciosStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ espaciosStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposClientes: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposClientes"),
      (snapshot) => {
        const tiposClientesStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposClientesStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadSeries: async () => {
    const unsubscribe = onSnapshot(collection(db, "series"), (snapshot) => {
      const seriesStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ seriesStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposProveedores: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposProveedores"),
      (snapshot) => {
        const tiposProveedoresStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposProveedoresStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposEventos: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposEventos"),
      (snapshot) => {
        const tiposEventosStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposEventosStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposMenus: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposMenus"),
      (snapshot) => {
        const tiposMenusStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposMenusStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposMontajes: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposMontajes"),
      (snapshot) => {
        const tiposMontajesStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposMontajesStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposCeremonias: async () => {
    console.log("ceremonias")
    const unsubscribe = onSnapshot(
      collection(db, "tiposCeremonias"),
      (snapshot) => {
        const tiposCeremoniasStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposCeremoniasStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposComensales: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposComensales"),
      (snapshot) => {
        const tiposComensalesStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposComensalesStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadTiposIdentificaciones: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposIdentificaciones"),
      (snapshot) => {
        const tiposIdentificacionesStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposIdentificacionesStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadPagos: async () => {
    const unsubscribe = onSnapshot(collection(db, "pagos"), (snapshot) => {
      const pagosStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ pagosStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadHorarios: async () => {
    const unsubscribe = onSnapshot(collection(db, "horario"), (snapshot) => {
      const horariosStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ horariosStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadTiposAgentes: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposComerciales"),
      (snapshot) => {
        const tiposComercialesStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposComercialesStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposComisiones: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposComiciones"),
      (snapshot) => {
        const tiposComisionesStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposComisionesStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposArticulos: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposArticulos"),
      (snapshot) => {
        const tiposArticulosStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposArticulosStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposCombinados: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposCombinados"),
      (snapshot) => {
        const tiposCombinadosStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposCombinadosStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadAlmacenes: async () => {
    const unsubscribe = onSnapshot(collection(db, "almacenes"), (snapshot) => {
      const almacenesStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ almacenesStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadFamiliasCompras: async () => {
    const unsubscribe = onSnapshot(collection(db, "familiasCompra"), (snapshot) => {
      const familiasCompraStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ familiasCompraStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadFamilias: async () => {
    const unsubscribe = onSnapshot(collection(db, "familias"), (snapshot) => {
      const familiasStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ familiasStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadSubFamilias: async (familia) => {
    if (familia) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, "subFamilias"),
          where("familia.id", "==", familia)
        ),
        (snapshot) => {
          const subFamiliasStore = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          set({ subFamiliasStore });
        }
      );
      return unsubscribe;
    }
  },
  loadModelos: async (familia) => {
    if (familia) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, "modelos"),
          where("familia.id", "==", familia)
        ),
        (snapshot) => {
          const modelosStore = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          set({ modelosStore });
        }
      );
      return unsubscribe;
    }
  },

  loadAllModelos: async () => {
    const unsubscribe = onSnapshot(collection(db, "modelos"), (snapshot) => {
      const modelosStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ modelosStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposIvas: async () => {
    const unsubscribe = onSnapshot(collection(db, "tiposIvas"), (snapshot) => {
      const tiposIvasStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ tiposIvasStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTiposPresupuestos: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "tiposPresupuestos"),
      (snapshot) => {
        const tiposPresupuestosStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ tiposPresupuestosStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadMedidas: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "unidadesMedida"),
      (snapshot) => {
        const medidasStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ medidasStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadTrabajadores: async () => {
    const unsubscribe = onSnapshot(
      collection(db, "trabajadores"),
      (snapshot) => {
        const trabajadoresStore = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ trabajadoresStore });
      }
    );

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadContactos: async () => {
    const unsubscribe = onSnapshot(collection(db, "contactos"), (snapshot) => {
      const contactosStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ contactosStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  //funcionaes cargan documentos
  cargarCliente: async (id) => {
    const clienteCurrent = await dato("clientes", id);
    if (clienteCurrent) {
      set((state) => ({
        ...state,
        clienteCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        clienteCurrent: null,
      }));
    }
  },
  cargarProveedor: async (id) => {
    const proveedorCurrent = await dato("proveedores", id);
    if (proveedorCurrent) {
      set((state) => ({
        ...state,
        proveedorCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        proveedorCurrent: null,
      }));
    }
  },
  cargarAgente: async (id) => {
    const agenteCurrent = await dato("comerciales", id);
    if (agenteCurrent) {
      set((state) => ({
        ...state,
        agenteCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        agenteCurrent: null,
      }));
    }
  },
  cargarArticulo: async (id) => {
    const articuloCurrent = await dato("articulos", id);
    if (articuloCurrent) {
      set((state) => ({
        ...state,
        articuloCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        articuloCurrent: null,
      }));
    }
  },
  cargarArticuloCompra: async (id) => {
    const articuloCompraCurrent = await dato("articulosCompra", id);
    if (articuloCompraCurrent) {
      set((state) => ({
        ...state,
        articuloCompraCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        articuloCompraCurrent: null,
      }));
    }
  },
  cargarCombinado: async (id) => {
    const combinadoCurrent = await dato("articulosCombinados", id);
    if (combinadoCurrent) {
      set((state) => ({
        ...state,
        combinadoCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        combinadoCurrent: null,
      }));
    }
  },
  cargarEspacio: async (id) => {
    const espacioCurrent = await dato("espacios", id);
    if (espacioCurrent) {
      set((state) => ({
        ...state,
        espacioCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        espacioCurrent: null,
      }));
    }
  },
  cargarPresupuesto: async (id) => {
    const presupuestoCurrent = await dato("presupuestos", id);
    if (presupuestoCurrent) {
      set((state) => ({
        ...state,
        presupuestoCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        presupuestoCurrent: null,
      }));
    }
  },
  cargarPedido: async (id) => {
    const pedidoCurrent = await dato("pedidos", id);
    if (pedidoCurrent) {
      set((state) => ({
        ...state,
        pedidoCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        pedidoCurrent: null,
      }));
    }
  },
  cargarEvento: async (id) => {
    const eventoCurrent = await dato("eventos", id);
    if (eventoCurrent) {
      set((state) => ({
        ...state,
        eventoCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        eventoCurrent: null,
      }));
    }
  },
  cargarVenta: async (id) => {
    const ventaCurrent = await dato("ventas", id);
    if (ventaCurrent) {
      set((state) => ({
        ...state,
        ventaCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        ventaCurrent: null,
      }));
    }
  },
  cargarCompra: async (id) => {
    const compraCurrent = await dato("compras", id);
    if (compraCurrent) {
      set((state) => ({
        ...state,
        compraCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        compraCurrent: null,
      }));
    }
  },
  cargarPago: async (id) => {
    const pagoCurrent = await dato("pagos", id);
    if (pagoCurrent) {
      set((state) => ({
        ...state,
        pagoCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        pagoCurrent: null,
      }));
    }
  },
  cargarIva: async (id) => {
    const ivaCurrent = await dato("tiposIvas", id);
    if (ivaCurrent) {
      set((state) => ({
        ...state,
        ivaCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        ivaCurrent: null,
      }));
    }
  },
  cargarTrabajador: async (id) => {
    const trabajadoresCurrent = await dato("trabajadores", id);
    if (trabajadoresCurrent) {
      set((state) => ({
        ...state,
        trabajadoresCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        trabajadoresCurrent: null,
      }));
    }
  },
  cargarContacto: async (id) => {
    const contactosCurrent = await dato("contactos", id);
    if (contactosCurrent) {
      set((state) => ({
        ...state,
        contactosCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        contactosCurrent: null,
      }));
    }
  },
}));
