/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select from "react-select";
import Venta from "../pdfs/imprimirVenta";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Cargando from "../cargando/cargando";
import Modal from "react-modal";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Pago from "../pago/pago";
import { FormClientes } from "../clientes";

export default function FormFacturaVenta() {
  Modal.setAppElement("#root");
  registerLocale("es", es);
  const [clienteIsOpen, setClienteIsOpen] = useState(false);
  const [enable, setEnable] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [articulos, setArticulos] = useState();
  const [modalIsOpenDoc, setIsOpenDoc] = useState(false);
  const location = useLocation();
  function closeClienteModal() {
    setClienteIsOpen(false);
  }
  function closeModalDoc() {
    setIsOpenDoc(false);
  }
  const [modalIsOpenPago, setIsOpenPago] = useState(false);
  function closeModalPago() {
    setIsOpenPago(false);
  }
  const { idVenta } = useParams();
  const ivas = {};
  const total = {};
  const navigate = useNavigate();
  const { insert, delDato, updateDato, obtenerRegistroMaximo } = useAuth();
  const {
    clientesStore,
    loadPagos,
    pagosStore,
    loadSeries,
    seriesStore,
    loadClientes,
    loadArticulos,
    loadVentas,
    articulosStore,
    setVenta,
    ventaCurrent,
    cargarVenta,
    loadCombinados,
    combinadosStore,
    setPago,
    setCliente,
    cargarPago,
    cargarCliente,
    setUnsavedChanges
  } = useStore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      switch (e.target.value) {
        case "Añadir":
          if (
            ventaCurrent.cliente?.nombre &&
            ventaCurrent.cliente?.nombre !== null &&
            ventaCurrent.cliente?.nombre !== ""
          ) {
            try {
            setUnsavedChanges(false)
            const modifiedArticulos = ventaCurrent.articulos.map((articulo) => {
              const precioVenta = parseFloat(articulo.precioVenta.toString().replace(',', '.'));
              const cantidad = parseFloat(articulo.cantidad?.toString().replace(',', '.'));
              const descuento = parseFloat(articulo.descuento?.toString().replace(',', '.'));
              return {
                ...articulo,
                precioVenta: isNaN(precioVenta) ? 0 : precioVenta,
                cantidad: isNaN(cantidad) ? 0 : cantidad,
                descuento: isNaN(descuento) ? 0 : descuento,
              };
            });
            const modifiedData = {
              ...ventaCurrent,
              articulos: modifiedArticulos,
            };
              const id = await insert("ventas", modifiedData);
              navigate(`/facturas/listado/${id}`);
            } catch (error) {
              console.log(error);
            }
          } else {
            showToast("error", "Debes seleccionar al menos un cliente");
          }
          break;
        case "Agregar":
          var artiSelect = ventaCurrent.articulos;
          //artiSelect.push(articulo);
          setVenta({ ...ventaCurrent, articulos: artiSelect });
          break;
        case "Calcular Precio":
          let total = 0;
          ventaCurrent.articulos.map(
            // eslint-disable-next-line array-callback-return
            (articulo) => {
              total +=
                parseFloat(articulo.precioVenta) *
                parseFloat(articulo.cantidad);
            }
          );
          setVenta({ ...ventaCurrent, precio: total.toFixed(2) });
          break;
        case "Eliminar":
          const confirmDelete = window.confirm(
            `¿Seguro que desea eliminar el presupuesto?`
          );
          if (confirmDelete) {
            setUnsavedChanges(false)
            await delDato("ventas", idVenta);
            loadVentas();
            navigate(`/facturas/listado`);
          }
          break;
        case "Validar":
          ventaCurrent.numero = await obtenerRegistroMaximo(ventaCurrent.serie);
        // eslint-disable-next-line no-fallthrough
        case "Modificar":
          const confirmUpdate = window.confirm(
            "¿Seguro que desea modificar la factura"
          );
          if (confirmUpdate) {
            setUnsavedChanges(false)
            const modifiedArticulos = ventaCurrent.articulos.map((articulo) => {
              const precioVenta = parseFloat(articulo.precioVenta.toString().replace(',', '.'));
              const cantidad = parseFloat(articulo.cantidad?.toString().replace(',', '.'));
              const descuento = parseFloat(articulo.descuento?.toString().replace(',', '.'));
              return {
                ...articulo,
                precioVenta: isNaN(precioVenta) ? 0 : precioVenta,
                cantidad: isNaN(cantidad) ? 0 : cantidad,
                descuento: isNaN(descuento) ? 0 : descuento,
              };
            });
            const modifiedData = {
              ...ventaCurrent,
              articulos: modifiedArticulos,
            };
            try {
              await updateDato("ventas", idVenta, modifiedData);
              setEnable(false);
              navigate(`/facturas/listado`);
            } catch (err) {
              console.log(err);
            }
          } else {
            ventaCurrent.numero = null;
          }
          break;
        case "Editar":
        setUnsavedChanges(true)
          setEnable(true);
          break;
        case "Imprimir":
          setIsOpenDoc(true);
          break;
        default:
          break;
      }
    } catch (err) {}
  };

  const handleChange = (e, i, campo) => {
    const valor = e.target.value.replace(/[^\d,.]/g, "");
    const articulos = ventaCurrent.articulos;
    var total = 0;
    var totalConIva = 0;
    if (campo === "cantidad") {
      articulos[i].cantidad = valor;
    } else if (campo === "precioVenta") {
      articulos[i].precioVenta = valor;
    } else {
      articulos[i].descuento = valor;
    }
    const precio = parseFloat(articulos[i].cantidad.toString().replace(',','.')) * parseFloat(articulos[i].precioVenta.toString().replace(',','.'));
    const descuento = (parseFloat(articulos[i].descuento.toString().replace(',','.')) / 100) * precio;
    articulos[i].total = parseFloat(precio - descuento)
    // eslint-disable-next-line array-callback-return
    articulos.map((articulo) => {
      total += parseFloat(articulo.total.toString().replace(',','.')).toFixed(2);
      totalConIva += parseFloat(
        (parseFloat(articulo.total.toString().replace(',','.')).toFixed(2) * (1 + articulo.iva.porcentaje / 100)).toFixed(2)
        
      );
    });
    setVenta({ ...ventaCurrent, articulos, total:parseFloat(total), totalConIva });
  };
  async function datos() {
    await Promise.all([
      loadClientes(),
      loadArticulos(),
      loadPagos(),
      loadSeries(),
      loadCombinados(),
    ]);
    setArticulos(articulosStore.concat(combinadosStore));
    if (idVenta) {
      await cargarVenta(idVenta);
      if (ventaCurrent === null) {
        showToast("error", "El documento no existe");
        navigate("/articulos");
      }
    }
    setCargando(false);
  }
  useEffect(() => {
    return () => {
      setIsOpenDoc(false);
    };
  }, [location]);
  useEffect(() => {
    datos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Combina los datos de articulosStore y combinadosStore en todosArticulosStore
    setArticulos([...articulosStore, ...combinadosStore]);
  }, [articulosStore, combinadosStore]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(ventaCurrent.articulos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setVenta({ ...ventaCurrent, articulos: items });
  };

  const handlerEliminar = (indice) => {
    console.log(indice);
    // Crea una nueva lista sin el elemento en el índice dado

    var nuevaLista = ventaCurrent.articulos.filter((_, i) => i !== indice);

    // Actualiza el estado de la lista con la nueva lista
    setVenta({
      ...ventaCurrent,
      articulos: nuevaLista,
    });
  };

  function funcionTotales() {
    ventaCurrent?.articulos?.map((articulo) => {
      const iva = articulo.iva?.nombre;
      if (iva) {
        var precio = parseFloat(parseFloat(articulo.precioVenta?.toString().replace(',','.')) * parseFloat(articulo.cantidad?.toString().replace(',','.')))
        const descuento = precio * (parseFloat(articulo.descuento?.toString().replace(',','.') / 100));
        precio = precio - descuento;
        if (ivas[iva]) {
          ivas[iva] += (articulo.iva?.porcentaje * precio) / 100;
        } else {
          ivas[iva] = (articulo.iva?.porcentaje * precio) / 100;
        }
        if (total["Total sin iva"]) {
          total["Total sin iva"] += precio;
          total["Total con iva"] +=
            precio + (precio * articulo.iva?.porcentaje) / 100;
        } else {
          total["Total sin iva"] = precio;
          total["Total con iva"] =
            precio + (precio * articulo.iva?.porcentaje) / 100;
        }
      }
    });
    const result = [];
    for (const iva in ivas) {
      result.push({
        iva: iva,
        total: ivas[iva],
      });
    }
    const result2 = [];
    for (const iva in total) {
      result.push({
        iva: iva,
        total: total[iva],
      });
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          margin: "40px",
          marginBottom: "20px",
        }}
      >
        {result.map((result) => {
          return (
            <div>
              <label htmlFor="" className="label">
                {result.iva}
              </label>
              <label htmlFor="" className="label">
                {result?.total ? result?.total?.toFixed(2).toString().replace('.', ',') : 0}
              </label>
            </div>
          );
        })}
        {result2.map((result) => {
          return (
            <div>
              <label htmlFor="" className="label">
                {result.iva}
              </label>
              <label htmlFor="" className="label">
                {result?.total ? result?.total?.toFixed(2).toString().replace('.', ',') : 0}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">
            {idVenta ? "Modificar " : "Añadir "}
            Factura
          </label>
          <div className="buttonMain">
            {idVenta ? (
              <>
                {!ventaCurrent?.numero ? (
                  <input
                    className="button main"
                    type="submit"
                    value="Validar"
                    onClick={handleSubmit}
                  />
                ) : null}

                <input
                  className="button main"
                  type="submit"
                  value="Imprimir"
                  onClick={handleSubmit}
                />
                {enable ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <>
                    {!ventaCurrent?.numero ? (
                      <input
                        className="button main"
                        type="submit"
                        value="Editar"
                        onClick={handleSubmit}
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
        <div
          className={idVenta ? (enable ? "enabled" : "disabled") : "enabled"}
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Cliente
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={
                      ventaCurrent?.cliente?.id
                        ? clientesStore.find(
                            (cliente) =>
                              cliente.id === ventaCurrent?.cliente?.id
                          )
                        : null
                    }
                    options={clientesStore}
                    getOptionLabel={(cliente) => cliente.nombre}
                    getOptionValue={(cliente) => cliente.id}
                    onChange={(val) =>
                      setVenta({
                        ...ventaCurrent,
                        cliente: { id: val.id, nombre: val.nombre, cifDni:val.cifDni },
                        pago: val.pago,
                        serie: val.serie,
                      })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      if (ventaCurrent.ref || ventaCurrent.ref === 0) {
                        setVenta({ ...ventaCurrent, cliente: null });
                        setCliente({});
                        setClienteIsOpen(true);
                      } else {
                        showToast(
                          "error",
                          "Debe introducir antes la referencia"
                        );
                      }
                    }}
                  />

                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      console.log(ventaCurrent.cliente);
                      if (ventaCurrent?.cliente?.id) {
                        cargarCliente(ventaCurrent.cliente?.id);
                        setClienteIsOpen(true);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Forma de pago
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      ventaCurrent?.pago?.id
                        ? pagosStore.find(
                            (pago) => pago.id === ventaCurrent?.pago?.id
                          )
                        : null
                    }
                    options={pagosStore}
                    getOptionLabel={(pago) => pago.nombre}
                    getOptionValue={(pago) => pago.id}
                    onChange={(val) => setVenta({ ...ventaCurrent, pago: val })}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      setVenta({ ...ventaCurrent, pago: {} });
                      setPago({});
                      setIsOpenPago(true);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      cargarPago(ventaCurrent.pago?.id);
                      setIsOpenPago(true);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={() => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar la forma de pago?"
                      );
                      if (confirmDelete) {
                        delDato("pagos", ventaCurrent.pago.id);
                        setVenta({ ...ventaCurrent, pago: {} });
                        loadPagos();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label className="label">Serie Factura</label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={ventaCurrent?.serie ? ventaCurrent?.serie : ""}
                    options={seriesStore}
                    getOptionLabel={(serie) => serie.nombre}
                    getOptionValue={(serie) => serie.id}
                    onChange={(val) =>
                      setVenta({ ...ventaCurrent, serie: val })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      const nombre = prompt("Inserte la nueva serie");
                      if (nombre !== "" && nombre !== null) {
                        const id = await insert("series", { nombre });
                        loadSeries();
                        setVenta({ ...ventaCurrent, serie: { id, nombre } });
                      } else {
                        showToast(
                          "error",
                          "Debes introducir una serie de factura"
                        );
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el tipo de cliente?"
                      );
                      if (confirmDelete) {
                        await delDato("series", ventaCurrent.serie.id);
                      }
                      loadSeries();
                      setVenta({ ...ventaCurrent, serie: null });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="columna">
              <div className="grupo">
                <label className="label">Numero Factura</label>
                <input
                  disabled
                  className="text"
                  type="text"
                  placeholder="Numero Factura"
                  value={ventaCurrent?.numero ? ventaCurrent?.numero : ""}
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label className="label">Referencia</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Referencia"
                  name="ref"
                  id="ref"
                  value={ventaCurrent?.ref ? ventaCurrent?.ref : 0}
                  onChange={(ref) =>
                    setVenta({ ...ventaCurrent, ref: ref?.target?.value })
                  }
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Fecha
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Fecha factura"
                  locale="es"
                  selected={
                    ventaCurrent?.fecha
                      ? typeof ventaCurrent.fecha === "string" ||
                        ventaCurrent.fecha instanceof Date
                        ? ventaCurrent.fecha
                        : ventaCurrent.fecha.toDate()
                      : null
                  }
                  onChange={(fecha) => setVenta({ ...ventaCurrent, fecha })}
                />
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna segunda">
              <DragDropContext onDragEnd={handleDragEnd}>
                <table className="table">
                  <thead className="thead">
                    <tr className="fila">
                      <th className="columna1">Producto</th>
                      <th className="columna2">Cantidad</th>
                      <th className="columna2">Precio</th>
                      <th className="columna2">Descuento</th>
                      <th className="columna2">Subtotal</th>
                      <th style={{ marginRight: "20px" }}></th>
                    </tr>
                  </thead>
                  <Droppable droppableId="filas">
                    {(provided) => (
                      <tbody
                        className="tbody"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {ventaCurrent?.articulos?.map((articulo, i) => {
                          return (
                            <Draggable
                              key={articulo.id & i}
                              draggableId={articulo.id}
                              index={i}
                            >
                              {(provided) => (
                                <tr
                                  className="fila2"
                                  key={i}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <td className="columna1">
                                    <label
                                      className="label"
                                      style={{
                                        whiteSpace: "pre-line",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {articulo.nombre}
                                    </label>
                                  </td>
                                  <td className="columna2">
                                    <input
                                      key={i}
                                      onChange={(event) =>
                                        handleChange(event, i, "cantidad")
                                      }
                                      type="text"
                                      className="text2"
                                      value={articulo.cantidad
                                        ? articulo.cantidad.toString().replace('.', ',')
                                        : (articulo.cantidad = 0)}
                                    />
                                  </td>
                                  <td className="columna2">
                                    <input
                                      key={i}
                                      onChange={(event) =>
                                        handleChange(event, i, "precioVenta")
                                      }
                                      className="text2"
                                      type="text"
                                      value={articulo.precioVenta
                                        ? articulo.precioVenta.toString().replace('.', ',')
                                        : (articulo.precioVenta = 0)}
                                    />
                                  </td>
                                  <td className="columna2">
                                    <input
                                      key={i}
                                      onChange={(event) =>
                                        handleChange(event, i, "descuento")
                                      }
                                      className="text2"
                                      type="text"
                                      value={articulo.descuento
                                        ? articulo.descuento.toString().replace('.', ',')
                                        : (articulo.descuento = 0)}
                                    />
                                  </td>
                                  <td
                                    className="columna2"
                                    style={{ textAlign: "end" }}
                                  >
                                    <label htmlFor="" className="label">
                                      {articulo.total
                                        ? articulo.total.toFixed(2).toString().replace('.', ',')
                                        : (articulo.total = 0)}
                                    </label>
                                  </td>
                                  <td
                                    onClick={() => {
                                      handlerEliminar(0, i);
                                    }}
                                  >
                                    <AiOutlineCloseCircle
                                      style={{
                                        marginRight: "20px",
                                       
                                      }}
                                      size={"1.5em"}
  color="#CB950E"
                                    />
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })}
                        <tr className="fila">
                          <td className="columna1">
                            <Select
                              className="select"
                              classNamePrefix="selectClass"
                              isSearchable={true}
                              options={articulos}
                              getOptionLabel={(tipo) => tipo.nombre}
                              getOptionValue={(tipo) => tipo.id}
                              onChange={(val) => {
                                if (val.iva?.nombre) {
                                  console.log(val);
                                  var articulo = val;
                                  if (articulo.articulosSelected) {
                                    articulo.articulosSelected.map((arti) => {
                                      const nombre = `${articulo.nombre} \n \u00A0\u00A0\u00A0\u00A0 ${arti.cantidad} ${arti.nombre}`;
                                      articulo = { ...articulo, nombre };
                                    });
                                  }
                                  articulo = {
                                    ...articulo,
                                    cantidad: 0,
                                    descuento: ventaCurrent?.cliente?.descuento
                                      ? ventaCurrent.cliente.descuento
                                      : 0,
                                  };
                                  if (ventaCurrent?.articulos)
                                    var artiSelect = ventaCurrent.articulos;
                                  else artiSelect = [];

                                  artiSelect.push(articulo);
                                  setVenta({
                                    ...ventaCurrent,
                                    articulos: artiSelect,
                                  });
                                } else {
                                  showToast(
                                    "error",
                                    "Articulo con datos erroneos o incompleto"
                                  );
                                }
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="iva">{funcionTotales()}</tr>
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            </div>
          </div>
        </div>
      </form>

      <Modal
        isOpen={clienteIsOpen}
        onRequestClose={closeClienteModal}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <FormClientes
          cerrar={closeClienteModal}
          llamadoDesde={"facturaVenta"}
          idClienteSelected={ventaCurrent?.cliente?.id}
        />
      </Modal>

      <Modal
        isOpen={modalIsOpenDoc}
        onRequestClose={closeModalDoc}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      ><AiOutlineCloseCircle
      style={{
        marginTop: "30px",
        
        position:"absolute",
        right:"30px",
        color:"red"
        
      }}
      size={"1.5em"}
  color="#CB950E"
      onClick={closeModalDoc}
    />
        
          <Venta doc={ventaCurrent} cerra={closeModalDoc} />
        
      </Modal>
      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
      <Modal
        isOpen={modalIsOpenPago}
        onRequestClose={closeModalPago}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <Pago cerrar={closeModalPago} llamadoDesde={"venta"} />
      </Modal>
    </div>
  );
}
