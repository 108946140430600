import { useStore } from "../../store/appStore";
import { useAuth } from "../../context/authContext";
import { showToast } from "../../utils/utils";
import { CompactPicker } from 'react-color'

export default function Tipo(props) {
  const {cerra} = props
  const {
    eventoCurrent,
    tipoEventoCurrent,
    setEvento,
    setTipoEvento,
    loadTiposEventos

  } = useStore();
  const { insert, updateDato } = useAuth();

  const handleChange = ({ target: { value, name } }) => {
    //controlamos que en estos camos solo se escriba numeros
    setTipoEvento({...tipoEventoCurrent,nombre: value} );
  };
  const handleChangeComplete = (color) => {
    setTipoEvento({...tipoEventoCurrent,letra: color.hex} );
    console.log(color)
  };
  const handleChangeFondo = (color) => {
    setTipoEvento({...tipoEventoCurrent,fondo: color.hex} );
    console.log('fondo',color)
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    console.log(accion,tipoEventoCurrent)
    switch (accion) {
      case "Añadir":
        console.log("añadir");
        if (
          tipoEventoCurrent?.nombre &&
          tipoEventoCurrent?.fondo &&
          tipoEventoCurrent?.letra
        ) {
          try {
            const id = await insert("tiposEventos", tipoEventoCurrent);
            console.log(id);
            setEvento({...eventoCurrent,tiposEvento:tipoEventoCurrent})
            loadTiposEventos()

          } catch (err) {
            console.log(err);
          }
        } else {
          showToast(
            "error",
            "Debe de rellenar los datos del formulario"
          );
        }
        cerra()
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar el tipo de Evento?"
        );
        if (confirmUpdate) {
        }
        break;
      default:
        break;
    }
  };
  return (
    <div className="form" style={{ width: "500px", marginTop: "20vh"}}>
      <form>
        {eventoCurrent.tiposEvento?.id ? (
          <div className="superior">
            <label className="tituloVentana">Modificar tipo de evento</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Modificar"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : (
          <div className="superior">
            <label className="tituloVentana">Añadir tipo de evento</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
        <div className="datos">
          <div className="columna">
            <div className="grupo">
              <label className="label">Nombre</label>
              <input
                type="text"
                className="text"
                placeholder="Tipo"
                name="nombre"
                id="nombre"
                onChange={handleChange}
                value={tipoEventoCurrent?.nombre}
              />
            </div>
            <div className="grupo">
              <label className="label">Letra</label>
              <CompactPicker
               color={ tipoEventoCurrent?.letra }
               onChangeComplete={ handleChangeComplete }/>
            </div>
            <div className="grupo">
              <label className="label">Fondo</label>
              <CompactPicker
               color={ tipoEventoCurrent?.fondo }
               onChangeComplete={ handleChangeFondo }/>
            </div>
            <div className="grupo" style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
              <label className="label" style={{color:tipoEventoCurrent?.letra,backgroundColor:tipoEventoCurrent?.fondo}}>Texto de ejemplo</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
