import React, { useEffect, useState } from "react";
import { useStore } from "../../store/appStore";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const CardPresupuestos = (props) => {
  const [datos, setDatos] = useState();
  const navigate = useNavigate();
  const { clienteCurrent, loadPresupuestosFiltrados } = useStore();
  useEffect(() => {
    const cliente = props.cliente ? props.cliente : clienteCurrent.cifDni;
    loadPresupuestosFiltrados(cliente, props.fecha,props.lugar)
      .then((presupuestos) => {
        setDatos(presupuestos);
        console.log("Presupuestos filtrados:", presupuestos);
      })
      .catch((error) => {
        console.error("Error al cargar presupuestos:", error);
      });
  }, []);


  useEffect(()=>{
    console.log(datos)
  },[datos])
    return (
      <div className="datos">
        {datos?.length>0 ? 
          <div className="divCardPresupuestos">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <th style={{ textAlign: "left" }}>Fecha del evento</th>
                  <th style={{ textAlign: "left" }}>Nombre del espacio</th>
                  <th style={{ textAlign: "left" }}>Comensales</th>
                  <th style={{ textAlign: "left" }}>Importe con iva</th>
                </tr>
              </thead>
              <tbody>
                {datos.map((dato, index) => (
                  <tr
                    key={dato.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "white" : "#f2f2f2",
                    }}
                    onDoubleClick={() =>
                      navigate(`/presupuestos/listado/${dato.id}`)
                    }
                  >
                    <td>
                      {moment(dato?.fecha?.toDate()).format("DD/MM/YYYY")}
                    </td>
                    <td>{dato.espacio.nombre}</td>
                    <td>{dato.pax}</td>
                    <td>{dato.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
         : 
          <label
            style={{
              display: "flex",
              margin: "30px",
              fontSize: "20px",
            }}
          >
            No tiene presupuestos asociados
          </label>
        }
      </div>
    );
};
