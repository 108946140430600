import { Document, Page, Text, View, Image, pdf } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import styles from "../../css/file.js";
import logo from "../../img/logoFile.jpg";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import Cargando from "../cargando/cargando.js";

const Venta = (props) => {
  const { doc } = props;
  const fecha = doc.fecha
    ? moment(doc.fecha.toDate()).format("DD/MM/YYYY")
    : null;
  const evento = doc.fechaEvento
    ? moment(doc.fechaEvento.toDate()).format("DD/MM/YYYY")
    : null;
  const [blobPDF, setBlobPDF] = useState(null);
  const [blob, setBlob] = useState(null);
  const [blobUnido, setBlobUnido] = useState(null);
  const [blobCondiciones, setBlobCondiciones] = useState(null);
  const ivas = {};
  const articulos = [[], []];
  const preciosPorIva = {};
  const result = [];
  let ultimaFamilia = "";
  totales();
  function dividir() {
    let numero = 0;

    let i = 0;
    doc?.articulos.map((articulo) => {
      if (articulo.familia.nombre !== ultimaFamilia) {
        numero += 16.6;
        ultimaFamilia = articulo.familia.nombre;
      }
      if (numero > 335) {
        numero = 0;
        i += 1;
      }
      articulos[i].push(articulo);
      numero += 11.6;
    });
  }
  useEffect(() => {
    dividir();
    downloadAndConvertToBlob();
  }, []);
  function pagina() {
    return (
      <Document>
        {articulos
          ? articulos.map((lista,i) => (
              <Page size="A4" {...props} style={styles.page}>
                <View style={styles.container}>
                  <View style={styles.encabezado}>
                    <View style={styles.encabezadoIzquierdo}>
                      <Image
                        className="logo"
                        src={logo}
                        alt="logo"
                        style={styles.image}
                      />
                      <Text>BAJOPLATO S.L.</Text>
                      <Text>C/ JOAQUIN COSTA 25</Text>
                      <Text>41002 SEVILLA</Text>
                      <Text>B90046434</Text>
                      <View
                        style={{
                          ...styles.contenedorCabeceraDatosDocumento,
                          width: "350px",
                        }}
                      >
                        <Text style={styles.cabeceraDatosDocumento}>
                          Documento
                        </Text>
                        <Text style={styles.cabeceraDatosDocumento}>
                          Comensales
                        </Text>
                        <Text style={styles.cabeceraDatosDocumento}>
                          Evento
                        </Text>
                        <Text style={styles.cabeceraDatosDocumento}>
                          Pedido
                        </Text>
                        <Text style={styles.cabeceraDatosDocumento}>
                          Transporte
                        </Text>
                      </View>
                      <View
                        style={{
                          ...styles.contenedorDatosDocumento,
                          width: "350px",
                        }}
                      >
                        <Text style={styles.datosDocumento}>Pedido</Text>
                        <Text style={styles.datosDocumento}>
                          {doc.comensales}
                        </Text>
                        <Text style={styles.datosDocumento}>{evento}</Text>
                        <Text style={styles.datosDocumento}>{fecha}</Text>
                        <Text style={styles.datosDocumento}>
                          {doc.transporte.nombre}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.encabezadoDerecho}>
                      <Text>
                        {doc.cliente?.nombre
                          ? doc?.cliente?.nombre
                          : doc?.proveedor?.nombre}
                      </Text>
                      <Text>
                        {doc.cliente?.direccion
                          ? doc?.cliente?.direccion
                          : doc?.proveedor?.direccion}
                      </Text>
                      <Text>
                        {doc.cliente?.cp
                          ? doc?.cliente?.cp
                          : doc?.proveedor?.cp}{" "}
                        {doc.cliente?.localidad
                          ? doc?.cliente?.localidad
                          : doc?.proveedor?.localidad}
                      </Text>
                      <Text>
                        {doc.cliente?.provincia
                          ? doc?.cliente?.provincia
                          : doc?.proveedor?.provincia}
                      </Text>
                      <Text>
                        {doc.cliente?.cif
                          ? doc?.cliente?.cif
                          : doc?.proveedor?.cifDni}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.contenedorCabeceraArticulos}>
                    <Text style={styles.cabeceraDescripcion}>DESCRIPCIÓN</Text>
                    <Text
                      style={{ ...styles.cabeceraArticulos, width: "75px" }}
                    >
                      CANTIDAD
                    </Text>
                    <Text
                      style={{ ...styles.cabeceraArticulos, width: "75px" }}
                    >
                      PRECIO UD.
                    </Text>
                    <Text
                      style={{ ...styles.cabeceraArticulos, width: "75px" }}
                    >
                      SUBTOTAL
                    </Text>
                    <Text
                      style={{ ...styles.cabeceraArticulos, width: "75px" }}
                    >
                      TOTAL
                    </Text>
                  </View>
                  <View style={styles.contenedorArticulos}>
                    <View style={styles.datosDescripcion}></View>
                    <View
                      style={{ ...styles.datosArticulos, width: "75px" }}
                    ></View>
                    <View
                      style={{ ...styles.datosArticulos, width: "75px" }}
                    ></View>
                    <View
                      style={{ ...styles.datosArticulos, width: "75px" }}
                    ></View>
                    <View
                      style={{ ...styles.datosArticulos, width: "75px" }}
                    ></View>
                    <View style={styles.textoAbsoluto}>
                      {lista.map((articulo, i) => {
                        return (
                          <View
                            style={{
                              ...styles.textoFila,
                              display: "flex",
                              flexDirection: "column",
                            }}
                            key={i}
                          >
                            {articulo.familia.nombre !== ultimaFamilia && (
                              <View style={styles.textoFila} key={i}>
                                <Text
                                  style={{
                                    ...styles.textoDescripcion,
                                    fontSize: "10px",
                                    textAlign: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  {articulo.familia.nombre}
                                </Text>
                              </View>
                            )}
                            {articulo.familia.nombre !== ultimaFamilia
                              ? (ultimaFamilia = articulo.familia.nombre)
                              : null}
                            <View style={styles.textoFila} key={i}>
                              <Text style={styles.textoDescripcion}>
                                {articulo.nombre}
                              </Text>
                              <Text
                                style={{
                                  ...styles.textoArticulo,
                                  width: "75px",
                                  textAlign: "center",
                                }}
                              >
                                {articulo.cantidad}
                              </Text>
                              <Text
                                style={{
                                  ...styles.textoArticulo,
                                  width: "75px",
                                  textAlign: "center",
                                }}
                              >
                                {articulo.precio}
                              </Text>
                              <Text
                                style={{
                                  ...styles.textoArticulo,
                                  width: "75px",
                                  textAlign: "center",
                                }}
                              >
                                {articulo.cantidad * articulo.precio}
                              </Text>
                              <Text
                                style={{
                                  ...styles.textoArticulo,
                                  width: "75px",
                                  textAlign: "center",
                                }}
                              >
                                {articulo.total}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>

                  <View style={styles.contenedorCabeceraObservaciones}>
                    <Text style={styles.datosCabeceraObservaciones}>
                      OBSERVACIONES:
                    </Text>
                    <View style={styles.datosCabeceraTotal}>
                      <Text style={{ marginLeft: 10 }}>TOTAL:</Text>
                      <Text style={{ marginRight: 10 }}>{doc.total}</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.datosObservaciones}></Text>
                  </View>
                </View>
              </Page>
          ))
          : null}
      </Document>
    );
  }
  useEffect(() => {
    const myDoc = pagina();
    setBlobPDF(myDoc);
  }, []);

  useEffect(() => {
    if (blobPDF) newFunction();
    async function newFunction() {
      const bloB = await pdf(blobPDF).toBlob();
      setBlob(bloB);
    }
  }, [blobPDF]);

  useEffect(() => {
    async function mergePDFs() {
      const unidos = await concatBlobs(blob, blobCondiciones);
      const pdfUrl = URL.createObjectURL(unidos); // Obtén la URL del Blob combinado
      setBlobUnido(pdfUrl);
    }
    if (blob && blobCondiciones) mergePDFs();
  }, [blob, blobCondiciones]);

  async function concatBlobs(blob1, blob2) {
    const pdfDoc1 = await PDFDocument.load(await blob1.arrayBuffer());

    // Crear un nuevo documento PDF para almacenar las páginas combinadas
    const mergedPdf = await PDFDocument.create();

    // Agregar las páginas del primer documento al nuevo documento
    const pages1 = await mergedPdf.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
    pages1.forEach((page) => mergedPdf.addPage(page));

    /* Agregar las páginas del segundo documento al nuevo documento
    const pages2 = await mergedPdf.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
    pages2.forEach((page) => mergedPdf.addPage(page));*/

    // Generar un nuevo Blob con el PDF combinado
    const mergedPdfBytes = await mergedPdf.save();
    return new Blob([mergedPdfBytes], { type: "application/pdf" });
  }

  async function downloadAndConvertToBlob() {
    const fileURL = "";

    try {
      const response = await fetch(fileURL);
      if (!response.ok) {
        throw new Error("Error al descargar el archivo.");
      }
      const blob = await response.blob();
      setBlobCondiciones(blob);
      console.log("Blob del PDF:", blob);
    } catch (error) {
      console.error("Error al descargar y convertir el archivo:", error);
    }
  }
  return blobCondiciones ? (
    <object
      data={blobUnido}
      type="application/pdf"
      style={{ height: "100%", width: "100%" }}
    >
      Fichero PDF no cargado correctamente
    </object>
  ) : (
    <Cargando />
  );

  function totales() {
    doc?.articulos?.map((articulo) => {
      const iva = articulo.iva?.nombre;
      if (iva) {
        var precio = articulo.precioVenta * articulo.cantidad;
        const descuento = precio * (articulo.descuento / 100);
        precio = precio - descuento;
        if (ivas[iva]) {
          ivas[iva] += (articulo.iva?.porcentaje * precio) / 100;
          preciosPorIva[iva] += precio;
        } else {
          ivas[iva] = (articulo.iva?.porcentaje * precio) / 100;
          preciosPorIva[iva] = precio;
        }
      }
    });
    for (const iva in ivas) {
      result.push({
        iva: iva,
        total: ivas[iva],
        base: preciosPorIva[iva],
      });
    }
  }
};

export default Venta;
