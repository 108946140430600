import { StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: {
    padding: 60,
    paddingTop: 120,
    fontSize: 8,
  },
 
  encabezado: {
    position: "absolute",
    fontSize: 8,
    top: 0,
    left: 0,
    right: 0,
  },
  header: {
    width: "100%",
    backgroundColor: "#E7E7E7",
    height: 100,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 30,
  },
  encabezadoIzquierdo: {
    display: "flex",
    justifyContent: "center",
    flex: 3,
  },
  l1: { fontSize: 14, marginVertical: 5 },
  l2: { fontSize: 12, marginVertical: 2 },
  l3: { fontSize: 10, marginVertical: 2 },
  l4: { fontSize: 10, marginVertical: 2 },
  cabecera: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    color: "#d1920e",
    fontSize: 10,
    margin: 5,
  },
  contenedorClientes: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  conRecuadro: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    width: "", // Adjust the width of the box
    height: 30, // Adjust the height of the box
    borderWidth: 2,
    color: "#d1920e",
    borderColor: "#d1920e",
    justifyContent: "center",
    alignItems: "center",
  },
  lineaDivisoria: {
    borderBottomColor: "#d1920e",
    borderBottomStyle: "solid", // Agregamos esta línea para establecer el estilo del borde
    width: "100%",
    borderBottomWidth: 1, // Cambiamos "borderBottom" por "borderBottomWidth"
    marginVertical: 5,
  },
  lineaCliente: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  datosClientes: {
    margin: 10,
    width:'80%'
  },
  campoCliente:{
    display:'flex',
    flexDirection:'row'
  },
  texto: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "15px",
    paddingRight: "5px",
    lineHeight: "1.5",
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
  },
  logo: {
    width: 150,
  },
  footer: {
    position: "absolute",
    fontSize: 8,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
  },
  info: {
    width: "100%",
    backgroundColor: "grey",
    height: 40,
    color: "white",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 30,
  },
  infoSeccion: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  footerImg: {
    height: 20,
    marginRight: 5,
  },
  section: {
    margin: 10,
    padding: 10,
    flex:1
  },
  signature: {
    marginTop: 20,
    padding: 10,
    height:100,
    borderWidth: 1,
  },
  firmas:{
    display:"flex",
    flexDirection:"row"
  }
});
export default styles;
