import { useStore } from "../../store/appStore";
import { useAuth } from "../../context/authContext";
import { showToast } from "../../utils/utils";

export default function FamilaCompra(props) {
  const {
    articuloCompraCurrent,
    setArticuloCompra,
    setFamiliaCompra,
    familaCompraCurrent,
    loadFamiliasCompra
  } = useStore();
  const { insert, updateDato } = useAuth();

  const handleChange = ({ target: { value, name } }) => {
    //controlamos que en estos camos solo se escriba numeros
    if (name === "maximo") {
      value = value.replace(/[^\d,.]/g, "");
    }
    console.log(value,name)
    //modifcamos el valor en articulo
    setFamiliaCompra({ ...familaCompraCurrent, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (
          familaCompraCurrent.nombre &&
          familaCompraCurrent.maximo &&
          familaCompraCurrent.nombre !== "" &&
          familaCompraCurrent.nombre !== null &&
          familaCompraCurrent.maximo !== "" &&
          familaCompraCurrent.maximo !== null
        ) {
          try {
            familaCompraCurrent.maximo =  parseFloat(familaCompraCurrent.maximo?.toString().replace(',', '.'));
            const id = await insert("familiasCompra", familaCompraCurrent);
            setArticuloCompra({ ...articuloCompraCurrent, familia: { ...familaCompraCurrent, id }, modelo:null });
            loadFamiliasCompra();
            props.cerrar();
          } catch (err) {}
        } else {
          showToast("error", "Debe rellenar todos los datos");
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar la familia?"
        );
        if (confirmUpdate) {
          familaCompraCurrent.maximo =  parseFloat(familaCompraCurrent.maximo?.toString().replace(',', '.'));
          try {
            
              await updateDato(
                "familiasCompra",
                articuloCompraCurrent.familia?.id,
                familaCompraCurrent
              );
              setArticuloCompra({...articuloCompraCurrent,familia:{...familaCompraCurrent,id:articuloCompraCurrent.familia?.id}})
            loadFamiliasCompra();
            props.cerrar();
          } catch (err) {
            console.log(err);
          }
        }
        break;
      default:
        break;
    }
  };
  return (
    <div className="form" style={{ width: "500px", marginTop: "20vh" }}>
      <form>
        {articuloCompraCurrent.familia?.id ? (
          <div className="superior">
            <label className="tituloVentana">Modificar familia de compra</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Modificar"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : (
          <div className="superior">
            <label className="tituloVentana">Añadir familia de compra</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
        <div className="datos">
          <div className="columna">
            <div className="grupo">
              <label className="label">Nombre</label>
              <input
                type="text"
                className="text"
                placeholder="Familia"
                name="nombre"
                id="nombre"
                onChange={handleChange}
                value={familaCompraCurrent.nombre}
              />
            </div>
            <div className="grupo">
              <label className="label">Máximo</label>
              <input
                type="text"
                className="text"
                placeholder="Máximo"
                name="maximo"
                id="maximo"
                onChange={handleChange}
                value={familaCompraCurrent?.maximo
                  ? familaCompraCurrent?.maximo.toString().replace('.', ',')
                  : (familaCompraCurrent.maximo = 0)}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
