import { Route, Routes } from "react-router-dom";
import { Main, Login } from "./screens/index";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./context/authContext";
import "./css/form.scss";
import { ToastContainer } from "react-toastify";
import { ListadoCliente, FormClientes } from "./components/clientes/index";
import "react-toastify/dist/ReactToastify.css";
import { ListadoProveedores, FormProveedores } from "./components/proveedores";
import {
  FormArticulo,
  FormCombinado,
  ListadoArticulo,
  ListadoCombinado,
} from "./components/articulos";


import {
  FormPrespuestos,
  ListadoPresupuestos,
} from "./components/presupuestos";
import {
  FormEventos,
  ListadoEventos,
  Calendario
} from "./components/eventos";
import { ListadoAgentes, FormAgente } from "./components/agente";
import { ListadoEspacio, FormEspacios } from "./components/espacios";
import {
  ListadoFacturaVenta,
  FormFacturaVenta,
} from "./components/facturasVenta";
import {
  ListadoFacturasCompra,
  FormFacturaCompra,
} from "./components/facturasCompra";
import {
  ListadoTrabajadores,
  FormTrabajadores,
} from "./components/trabajadores";
import { ListadoDocumento, FormDocumentos } from "./components/documentos";
import { useStore } from "./store/appStore";
import { useEffect } from "react";
import ListadoArticuloCompra from "./components/articulosCompra/ListadoArticulo";
import FormArticuloCompra from "./components/articulosCompra/FormArticulo";
import ListadoPedidos from "./components/pedidos/ListadoPedidos";
import FormPedidos from "./components/pedidos/FormPedidos";
export default function App() {
  const {
    loadArticulos,
    loadArticulosCompra,
    loadClientes,
    loadProveedores,
    loadAgentes,
    loadCombinados,
    loadPresupuestos,
    loadVentas,
    loadCompras,
    loadFamilias,
    loadEspacios,
    loadTiposClientes,
    loadSeries,
    loadTiposProveedores,
    loadTiposIdentificaciones,
    loadPagos,
    loadTiposAgentes,
    loadTiposComisiones,
    loadTiposArticulos,
    loadTiposCombinados,
    loadAlmacenes,
    loadSubFamilias,
    loadTiposIvas,
    loadTiposPresupuestos,
    loadMedidas,
    loadTrabajadores,
    loadContactos,
  } = useStore();
  useEffect(() => {
    loadArticulos();
    loadArticulosCompra();
    loadClientes();
    loadProveedores();
    loadAgentes();
    loadCombinados();
    loadPresupuestos();
    loadVentas();
    loadCompras();
    loadFamilias();
    loadEspacios();
    loadTiposClientes();
    loadSeries();
    loadTiposProveedores();
    loadTiposIdentificaciones();
    loadPagos();
    loadTiposAgentes();
    loadTiposComisiones();
    loadTiposArticulos();
    loadTiposCombinados();
    loadAlmacenes();
    loadSubFamilias();
    loadTiposIvas();
    loadTiposPresupuestos();
    loadMedidas();
    loadTrabajadores();
    loadContactos();
  }, []);

  return (
    <div id="root" style={{ height: "100%" }}>
      <AuthProvider>
        <ToastContainer style={{ marginTop: "60px" }} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/*" element={<Main />}>
              <Route path="clientes/*" element={<ListadoCliente />} />
              <Route
                path="clientes/nuevo"
                element={<FormClientes limpiar={true} />}
              />
              <Route path="clientes/listado" element={<ListadoCliente />} />
              <Route path="clientes/listado/:id" element={<FormClientes />} />
              <Route path="proveedores/*" element={<ListadoProveedores />} />
              <Route path="proveedores/nuevo" element={<FormProveedores />} />
              <Route
                path="proveedores/listado"
                element={<ListadoProveedores />}
              />
              <Route
                path="proveedores/listado/:idProveedor"
                element={<FormProveedores />}
              />
              <Route path="agentes/*" element={<ListadoAgentes />} />
              <Route path="agentes/nuevo" element={<FormAgente />} />
              <Route path="agentes/listado" element={<ListadoAgentes />} />
              <Route
                path="agentes/listado/:idAgente"
                element={<FormAgente />}
              />
              <Route path="articulos/*" element={<ListadoArticulo />} />
              <Route path="articulos/nuevo" element={<FormArticulo />} />
              <Route path="articulos/listado" element={<ListadoArticulo />} />
              <Route
                path="articulos/listado/:idArticulo"
                element={<FormArticulo />}
              />
              <Route path="combinados/*" element={<ListadoCombinado />} />
              <Route path="combinados/nuevo" element={<FormCombinado />} />
              <Route path="combinados/listado" element={<ListadoCombinado />} />
              <Route
                path="combinados/listado/:idCombinado"
                element={<FormCombinado />}
              />
              <Route path="presupuestos/" element={<ListadoPresupuestos />} />
              <Route path="presupuestos/nuevo" element={<FormPrespuestos />} />
              <Route
                path="presupuestos/listado"
                element={<ListadoPresupuestos />}
              />
              <Route
                path="presupuestos/listado/:idPresupuesto"
                element={<FormPrespuestos />}
              />
              <Route path="facturas/*" element={<ListadoFacturaVenta />} />
              <Route path="facturas/nuevo" element={<FormFacturaVenta />} />
              <Route
                path="facturas/listado"
                element={<ListadoFacturaVenta />}
              />
              <Route
                path="facturas/listado/:idVenta"
                element={<FormFacturaVenta />}
              />
              <Route path="articulosCompra/*" element={<ListadoArticuloCompra />} />
              <Route path="articulosCompra/nuevo" element={<FormArticuloCompra />} />
              <Route path="articulosCompra/listado" element={<ListadoArticuloCompra />} />
              <Route
                path="articulosCompra/listado/:idArticuloCompra"
                element={<FormArticuloCompra />}
              />
              <Route
                path="facturasCompras/*"
                element={<ListadoFacturasCompra />}
              />
              <Route
                path="facturasCompras/nuevo"
                element={<FormFacturaCompra />}
              />
              <Route
                path="facturasCompras/listado"
                element={<ListadoFacturasCompra />}
              />
              <Route
                path="facturasCompras/listado/:idCompra"
                element={<FormFacturaCompra />}
              />
              <Route path="espacios/*" element={<ListadoEspacio />} />
              <Route path="espacios/nuevo" element={<FormEspacios />} />
              <Route path="espacios/listado" element={<ListadoEspacio />} />
              <Route
                path="espacios/listado/:idEspacio"
                element={<FormEspacios />}
              />
              <Route path="pedido/*" element={<ListadoPedidos />} />
              <Route path="pedido/nuevo" element={<FormPedidos />} />
              <Route path="pedido/listado" element={<ListadoPedidos />} />
              <Route
                path="pedido/listado/:idPedido"
                element={<FormPedidos />}
              />
              <Route path="trabajadores/*" element={<ListadoTrabajadores />} />
              <Route path="trabajadores/nuevo" element={<FormTrabajadores />} />
              <Route
                path="trabajadores/listado"
                element={<ListadoTrabajadores />}
              />
              <Route
                path="trabajadores/listado/:idTrabajador"
                element={<FormTrabajadores />}
              />
              <Route path="documentos/*" element={<ListadoDocumento />} />
              <Route path="documentos/listado" element={<FormDocumentos />} />
              <Route path="eventos/" element={<ListadoEventos />} />
              <Route path="eventos/nuevo" element={<FormEventos />} />
              <Route path="eventos/listado" element={<ListadoEventos />} />
              <Route path="eventos/calendario" element={<Calendario />} />
              <Route
                path="eventos/listado/:idEvento"
                element={<FormEventos />}
              />
            </Route>
          </Route>
          <Route path="*" element={<h1>no found</h1>} />
        </Routes>
      </AuthProvider>
    </div>
  );
}
