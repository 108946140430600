import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Evento from "../pdfs/imprimirEvento";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Cargando from "../cargando/cargando";
import Modal from "react-modal";
import es from "date-fns/locale/es";
import { Timing, Seguimiento, Documentos } from "./index";
import { CardPresupuestos } from "../presupuestos/cardPresupuestos";
import DatePicker, { registerLocale } from "react-datepicker";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Datos from "./Datos";

export default function FormEvento() {
  registerLocale("es", es);
  Modal.setAppElement("#root");
  const [enable, setEnable] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [persona, setPersona] = useState(true);
  const [IsOpenDoc, setIsOpenDoc] = useState(false);
  const { idEvento } = useParams();
  const navigate = useNavigate();
  const { insert, delDato, updateDato, authUser } = useAuth();
  const {
    eventoCurrent,
    loadEventos,
    cargarEvento,
    setEvento,
    loadClientes,
    loadTiposEventos,
    loadTiposMenus,
    loadTiposMontajes,
    tiposCeremoniasStore,
    loadTiposCeremonias,
    loadHorarios,
    loadTiposComensales,
    setUnsavedChanges
  } = useStore();

  function closeModalDoc() {
    setIsOpenDoc(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      switch (e.target.value) {
        case "Añadir":
          console.log("evento", eventoCurrent);
          if (eventoCurrent.cliente?.nombre) {
            setUnsavedChanges(false)
            console.log(eventoCurrent.cliente.nombre);
            const id = await insert("eventos", eventoCurrent);
            navigate(`/eventos/listado/${id}`);
          } else {
            showToast("error", "Debes al menos seleccionar un cliente antes");
            console.log(eventoCurrent.cliente.nombre);
          }
          break;
        case "Eliminar":
          const confirmDelete = window.confirm(
            `¿Seguro que desea eliminar el evento?`
          );
          if (confirmDelete) {
            await delDato("eventos", idEvento);
            setUnsavedChanges(false)
            loadEventos();
            navigate(`/eventos/listado`);
          }
          break;
        case "Modificar":
          const confirmUpdate = window.confirm(
            `¿Seguro que desea modificar el evento?`
          );
          if (confirmUpdate && eventoCurrent.cliente?.nombre) {
            setUnsavedChanges(false)
            try {
              await updateDato("eventos", idEvento, eventoCurrent);
              setEnable(false);
              navigate(`/eventos/listado`);
            } catch (err) {
              console.log(err);
            }
          } else if (confirmUpdate) {
            showToast("error", "Debes al menos seleccionar un cliente antes");
          }
          break;
        case "Editar":
        setUnsavedChanges(true)
          setEnable(true);
          break;
        case "Imprimir":
          setIsOpenDoc(true);
          break;
        default:
          break;
      }
    } catch (err) {}
  };

  async function datos() {
    console.log("Cargar");
    await Promise.all([
      loadClientes(),
      setPersona(await authUser()),
      loadTiposCeremonias(),
      loadTiposEventos(),
      loadTiposMenus(),
      loadTiposMontajes(),
      loadHorarios(),
      loadTiposComensales(),
    ]);

    if (idEvento) {
      await cargarEvento(idEvento);
    }
    setCargando(false);
  }
  useEffect(() => {
    datos();
  }, []);

  useEffect(() => {
    console.log(tiposCeremoniasStore);
  }, [tiposCeremoniasStore]);
  return (
    <>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="superior">
            <label className="tituloVentana">
              {idEvento ? "Modificar " : "Añadir "}
              evento
            </label>
            <div className="buttonMain">
              {idEvento ? (
                <>
                  <input
                    className="button main"
                    type="submit"
                    value="Imprimir"
                    onClick={handleSubmit}
                  />
                  {enable ? (
                    <>
                      <input
                        className="button main"
                        type="submit"
                        value="Modificar"
                        onClick={handleSubmit}
                      />
                      <input
                        type="submit"
                        className="button main"
                        value="Eliminar"
                        onClick={handleSubmit}
                      />
                    </>
                  ) : (
                    <input
                      className="button main"
                      type="submit"
                      value="Editar"
                      onClick={handleSubmit}
                    />
                  )}
                </>
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Añadir"
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
          <div
            className={idEvento ? (enable ? "enabled" : "disabled") : "enabled"}
          >
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label className="label" style={{ flex: 2 }}>
                    Nombre del Evento
                  </label>
                  <input
                    type="text"
                    style={{ flex: 3, marginRight: "10px" }}
                    className="text"
                    placeholder="Nombre del Evento"
                    name="nombre"
                    id="nombre"
                    onChange={(val) =>
                      setEvento({ ...eventoCurrent, nombre: val.target.value })
                    }
                    value={eventoCurrent.nombre}
                  />
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label htmlFor="" className="label">
                    Fecha
                  </label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="es"
                    placeholderText="Fecha del evento"
                    selected={
                      eventoCurrent?.fecha
                        ? typeof eventoCurrent?.fecha === "string" ||
                          eventoCurrent?.fecha instanceof Date
                          ? eventoCurrent?.fecha
                          : eventoCurrent?.fecha.toDate()
                        : null
                    }
                    onChange={(fecha) => setEvento({ ...eventoCurrent, fecha })}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <Modal isOpen={cargando}>
          <Cargando />
        </Modal>
        <Modal
        isOpen={IsOpenDoc}
        onRequestClose={closeModalDoc}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "30px",

            position: "absolute",
            right: "30px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalDoc}
        />
       <Evento doc={eventoCurrent} cerra={closeModalDoc} />
      </Modal>
      </div>
      <div>
        <Tabs className="react-tabs">
          <TabList className="react-tabs__tab-list">
            <Tab className="react-tabs__tab">Datos del evento</Tab>
            <Tab className="react-tabs__tab">Timing</Tab>
            <Tab className="react-tabs__tab">Seguimientos</Tab>
            <Tab className="react-tabs__tab">Presupuestos</Tab>
            <Tab className="react-tabs__tab">Documentos</Tab>
          </TabList>

          <TabPanel className="react-tabs__tab-panel">
            <div className="form" style={{border:'none'}}>
              <Datos enable={enable}  idEvento={idEvento}/>
            </div>
          </TabPanel>
          <TabPanel className="react-tabs__tab-panel">
          <div className="form" style={{border:'none'}}>
            <Timing
              idEvento={idEvento}
              enable={enable}
              evento={eventoCurrent}
              setEvento={setEvento}
            />
            </div>
          </TabPanel>
          <TabPanel className="react-tabs__tab-panel">
          <div className="form" style={{border:'none'}}>
            <Seguimiento
              persona={persona}
              idEvento={idEvento}
              enable={enable}
              evento={eventoCurrent}
              setEvento={setEvento}
            /></div>
          </TabPanel>
          <TabPanel className="react-tabs__tab-panel">
          <div className="form" style={{border:'none'}}>
            <CardPresupuestos
              fecha={eventoCurrent?.fecha}
              cliente={eventoCurrent?.cliente?.cifDni}
              lugar={eventoCurrent?.espacio?.id}
            /></div>
          </TabPanel>
          <TabPanel className="react-tabs__tab-panel">
          <div className="form" style={{border:'none'}}>
            {idEvento ? (
              <Documentos
                idEvento={idEvento}
                setCargando={setCargando}
                cargando={cargando}
                enable={enable}
              />
            ) : (
              <label
                style={{
                  display: "flex",
                  margin: "30px",
                  fontSize: "20px",
                }}
              >
                Debes de guardar guardar primero el evento
              </label>
            )}</div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}
