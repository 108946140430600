import { useStore } from "../../store/appStore";
import { useAuth } from "../../context/authContext";
import { showToast } from "../../utils/utils";

export default function Iva(props) {
  const {
    articuloCurrent,
    setArticulo,
    setIva,
    ivaCurrent,
    loadTiposIvas,
    combinadoCurrent,
    setCombinado,
  } = useStore();
  const { insert, updateDato } = useAuth();

  const handleChange = ({ target: { value, name } }) => {
    //controlamos que en estos camos solo se escriba numeros
    if (name === "porcentaje") {
      value = value.replace(/[^\d,.]/g, "");
    }
    //modifcamos el valor en articulo
    setIva({ ...ivaCurrent, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (
          ivaCurrent.nombre &&
          ivaCurrent.porcentaje &&
          ivaCurrent.nombre !== "" &&
          ivaCurrent.nombre !== null &&
          ivaCurrent.porcentaje !== "" &&
          ivaCurrent.porcentaje !== null
        ) {
          try {
            ivaCurrent.porcentaje =  parseFloat(ivaCurrent.porcentaje?.toString().replace(',', '.'));
            const id = await insert("tiposIvas", ivaCurrent);
            props.llamadoDesde === "combinado"
              ? setCombinado({
                  ...combinadoCurrent,
                  iva: { ...ivaCurrent, id },
                })
              : setArticulo({ ...articuloCurrent, iva: { ...ivaCurrent, id } });
            loadTiposIvas();
            props.cerrar();
          } catch (err) {}
        } else {
          showToast("error", "Debe rellenar todos los datos");
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar el tipo de Iva?"
        );
        if (confirmUpdate) {
          ivaCurrent.porcentaje =  parseFloat(ivaCurrent.porcentaje?.toString().replace(',', '.'));
          try {
            if (props.llamadoDesde === "combinado") {
              await updateDato(
                "tiposIvas",
                combinadoCurrent.iva?.id,
                ivaCurrent
              )
              setCombinado({...combinadoCurrent,iva:{...ivaCurrent,id:combinadoCurrent.iva?.id}})
            } else {
              await updateDato(
                "tiposIvas",
                articuloCurrent.iva?.id,
                ivaCurrent
              );
              setArticulo({...articuloCurrent,iva:{...ivaCurrent,id:articuloCurrent.iva?.id}})
            }
            loadTiposIvas();
            props.cerrar();
          } catch (err) {
            console.log(err);
          }
        }
        break;
      default:
        break;
    }
  };
  return (
    <div className="form" style={{ width: "500px", marginTop: "20vh" }}>
      <form>
        {articuloCurrent.iva?.id || combinadoCurrent.iva?.id ? (
          <div className="superior">
            <label className="tituloVentana">Modificar tipo de iva</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Modificar"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : (
          <div className="superior">
            <label className="tituloVentana">Añadir tipo de iva</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
        <div className="datos">
          <div className="columna">
            <div className="grupo">
              <label className="label">Nombre</label>
              <input
                type="text"
                className="text"
                placeholder="Tipo"
                name="nombre"
                id="nombre"
                onChange={handleChange}
                value={ivaCurrent.nombre}
              />
            </div>
            <div className="grupo">
              <label className="label">Porcentaje</label>
              <input
                type="text"
                className="text"
                placeholder="Porcentaje"
                name="porcentaje"
                id="porcentaje"
                onChange={handleChange}
                value={ivaCurrent?.porcentaje
                  ? ivaCurrent?.porcentaje.toString().replace('.', ',')
                  : (ivaCurrent.porcentaje = 0)}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
