// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth"

import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";

// configuración de firebase
const firebaseConfig = {

  apiKey: "AIzaSyD14lWe-3Q3kvCb_WTDANpCj-176LnpuhM",

  authDomain: "cateringcampuzano-3cf1f.firebaseapp.com",

  projectId: "cateringcampuzano-3cf1f",

  storageBucket: "cateringcampuzano-3cf1f.appspot.com",

  messagingSenderId: "717873787312",

  appId: "1:717873787312:web:469dd3c2b0001d23d3e675",

  measurementId: "G-RQWC2R9XL0"
/*
apiKey: "AIzaSyDbKUN8vrPlL8lxFG05qeIBCG079_kxRO8",

  authDomain: "campuzanopro.firebaseapp.com",

  projectId: "campuzanopro",

  storageBucket: "campuzanopro.appspot.com",

  messagingSenderId: "934704431290",

  appId: "1:934704431290:web:88a3a77764298d8a4c0283"
*/
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const storage = getStorage(app)