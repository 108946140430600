import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Cargando from "../cargando/cargando";
import Modal from "react-modal";
import Iva from "../iva/iva";
import FormProveedores from "../proveedores/FormProveedores";

export default function FormArticulo() {
  Modal.setAppElement("#root");
  const {
    articuloCurrent,
    loadCategorias,
    categoriasStore,
    setArticulo,
    cargarArticulo,
    loadFamilias,
    loadProveedores,
    loadTiposIvas,
    loadMedidas,
    loadAlmacenes,
    loadSubFamilias,
    familiasStore,
    proveedoresStore,
    setProveedor,
    cargarProveedor,
    tiposIvasStore,
    almacenesStore,
    medidasStore,
    subFamiliasStore,
    cargarIva,
    setIva,
    setUnsavedChanges,
  } = useStore();
  const [esFoto, setFoto] = useState(false);
  const [modalIsOpenIva, setIsOpenIva] = useState(false);
  function closeModalIva() {
    setIsOpenIva(false);
  }
  const [modalIsOpenProveedores, setIsOpenProveedores] = useState(false);
  function closeModalProveedores() {
    setIsOpenProveedores(false);
  }
  const [cargando, setCargando] = useState(true);
  const { insert, delDato, updateDato, uploadFile } = useAuth();
  const [image, setImage] = useState();

  const [enable, setEnable] = useState(false);
  const { idArticulo } = useParams();
  const navigate = useNavigate();

  const handleChange = ({ target: { value, name } }) => {
    //controlamos que en estos camos solo se escriba numeros
    if (
      name === "stockMinimo" ||
      name === "stock" ||
      name === "unidadCompra"
    ) {
      value = value.replace(/[^\d]/g, "");
    }
    if (
      name === "precioVenta" ||
      name === "precioCompra" 
    ) {
      value = value.replace(/[^\d,]/g, "");
    }
    //modifcamos el valor en articulo
    setArticulo({ ...articuloCurrent, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (
          articuloCurrent.nombre &&
          articuloCurrent.nombre !== "" &&
          articuloCurrent.nombre !== null &&
          articuloCurrent.iva &&
          articuloCurrent.familia &&
          articuloCurrent.subFamilia &&
          articuloCurrent.precioVenta &&
          articuloCurrent.precioVenta !== null &&
          articuloCurrent.precioVenta !== "" &&
          articuloCurrent.categoria
        ) {
          try {
            setUnsavedChanges(false)
            articuloCurrent.precioVenta =  parseFloat(articuloCurrent.precioVenta?.toString().replace(',', '.'));            
            articuloCurrent.precioVenta =  parseFloat(articuloCurrent.precioCompra?.toString().replace(',', '.'));  
            articuloCurrent.unidadCompra =  parseInt(articuloCurrent.unidadCompra);            
            articuloCurrent.stock =  parseInt(articuloCurrent.stock);            
            articuloCurrent.stockMinimo =  parseInt(articuloCurrent.stockMinimo);            
            const id = await insert("articulos", articuloCurrent);
            navigate(`/articulos/listado/${id}`);
          } catch (err) {}
        } else {
          showToast("error", "Debes rellenar al menos el nombre, familia, subfamilia, grupo de impresión, precio de venta e iva del articulo");
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al cliente?"
        );
        if (confirmDelete) {
          await delDato("articulos", idArticulo);
          setUnsavedChanges(false)
          navigate(`/articulos/listado`);
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al cliente?"
        );
        if (confirmUpdate && articuloCurrent.nombre) {
          setUnsavedChanges(false);
          try {
            setUnsavedChanges(false)
            await updateDato("articulos", idArticulo, articuloCurrent);
            navigate("/articulos/listado");
          } catch (err) {
            showToast("Error", err);
          }
        } else if (confirmUpdate) {
          showToast("error", "Debes rellenar al menos el nombre del articulo");
        }
        break;
      case "Editar":
        console.log(articuloCurrent);
        setUnsavedChanges(true);
        setEnable(true);
        break;
      default:
        break;
    }
  };
  async function datos() {
    await Promise.all([
      loadAlmacenes(),
      loadFamilias(),
      loadCategorias(),
      loadMedidas(),
      loadProveedores(),
      loadTiposIvas(),
    ]);
    if (idArticulo) {
      await cargarArticulo(idArticulo);
      if (articuloCurrent === null) {
        showToast("error", "El articulo no existe");
        navigate("/articulos");
      }
    }
    setCargando(false);
  }
  useEffect(() => {
    datos();
  }, []);

  useEffect(() => {
    console.log(articuloCurrent);
  }, [articuloCurrent]);

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">
            {idArticulo ? "Modificar Articulo" : "Añadir Articulo"}
          </label>
          <div className="buttonMain">
            {idArticulo || articuloCurrent.id ? (
              enable ? (
                <>
                  <input
                    className="button main"
                    type="submit"
                    value="Modificar"
                    onClick={handleSubmit}
                  />
                  <input
                    type="submit"
                    className="button main"
                    value="Eliminar"
                    onClick={handleSubmit}
                  />
                </>
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Editar"
                  onClick={handleSubmit}
                />
              )
            ) : (
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
        <div
          className={
            idArticulo || articuloCurrent.id
              ? enable
                ? "enabled"
                : "disabled"
              : "enabled"
          }
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Nombre
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Nombre"
                  name="nombre"
                  id="nombre"
                  value={articuloCurrent.nombre ? articuloCurrent.nombre : ""}
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Familia
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      articuloCurrent.familia ? articuloCurrent.familia : ""
                    }
                    options={familiasStore}
                    getOptionLabel={(familia) => familia.nombre}
                    getOptionValue={(familia) => familia.id}
                    onChange={(val) => {
                      setArticulo({
                        ...articuloCurrent,
                        familia: val,
                        subFamilia: null,
                      });
                      loadSubFamilias(val.id);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      const nombre = prompt("Inserte la nueva familia");
                      if (nombre !== "" && nombre !== null) {
                        const id = await insert("familias", { nombre });
                        loadFamilias();
                        setArticulo({
                          ...articuloCurrent,
                          familia: { id, nombre },
                          subFamilia: null,
                        });
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el tipo de agente?"
                      );
                      if (confirmDelete) {
                        await delDato("familias", articuloCurrent.familia.id);
                      }
                      loadFamilias();
                      loadSubFamilias();
                      setArticulo({
                        ...articuloCurrent,
                        familia: null,
                        subFamilia: null,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Subfamilia
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      articuloCurrent.subFamilia
                        ? articuloCurrent.subFamilia
                        : ""
                    }
                    options={subFamiliasStore}
                    getOptionLabel={(familia) => familia.nombre}
                    getOptionValue={(familia) => familia.id}
                    onChange={(val) =>
                      setArticulo({
                        ...articuloCurrent,
                        subFamilia: { id: val.id, nombre: val.nombre },
                      })
                    }
                  />

                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      if (articuloCurrent.familia) {
                        const nombre = prompt("Inserte la nueva subfamilia");
                        if (nombre !== "" && nombre !== null) {
                          const id = await insert("subFamilias", {
                            nombre,
                            familia: articuloCurrent.familia,
                          });
                          setArticulo({
                            ...articuloCurrent,
                            subFamilia: { id, nombre },
                          });
                          loadSubFamilias(articuloCurrent.familia.id);
                        } else {
                          showToast(
                            "error",
                            "Debe selecionar una familia primero"
                          );
                        }
                      } else {
                        showToast(
                          "error",
                          "Primero debes de selecionar una familia"
                        );
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el tipo de agente?"
                      );
                      if (confirmDelete) {
                        await delDato(
                          "subFamilias",
                          articuloCurrent.subFamilia.id
                        );
                      }
                      loadSubFamilias(articuloCurrent.familia.id);
                      setArticulo({ ...articuloCurrent, subFamilia: null });
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Grupo Impresión
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      articuloCurrent.categoria ? articuloCurrent.categoria : ""
                    }
                    options={categoriasStore}
                    getOptionLabel={(categoria) => categoria.nombre}
                    getOptionValue={(categoria) => categoria.id}
                    onChange={(val) => {
                      console.log(val);
                      setArticulo({ ...articuloCurrent, categoria: val });
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      const nombre = prompt(
                        "Inserte el nuevo grupo de impresión"
                      );
                      if (nombre !== "" && nombre !== null) {
                        const id = await insert("categorias", { nombre });
                        loadCategorias();
                        setArticulo({
                          ...articuloCurrent,
                          categoria: { id, nombre },
                        });
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el almacen?"
                      );
                      if (confirmDelete) {
                        await delDato("almacenes", articuloCurrent.almacen.id);
                      }
                      loadAlmacenes();
                      setArticulo({ ...articuloCurrent, almacen: null });
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Almacen
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      articuloCurrent.almacen ? articuloCurrent.almacen : ""
                    }
                    options={almacenesStore}
                    getOptionLabel={(almacen) => almacen.nombre}
                    getOptionValue={(almacen) => almacen.id}
                    onChange={(val) => {
                      setArticulo({ ...articuloCurrent, almacen: val });
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      const nombre = prompt("Inserte el nuevo almacen");
                      if (nombre !== "" && nombre !== null) {
                        const id = await insert("almacenes", { nombre });
                        loadAlmacenes();
                        setArticulo({
                          ...articuloCurrent,
                          almacen: { id, nombre },
                        });
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el almacen?"
                      );
                      if (confirmDelete) {
                        await delDato("almacenes", articuloCurrent.almacen.id);
                      }
                      loadAlmacenes();
                      setArticulo({ ...articuloCurrent, almacen: null });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="columna">
              <div className="grupo gImagen">
                <img
                  style={
                    !articuloCurrent.imagen
                      ? {
                          backgroundImage:
                            "url(https://sistelin.es/wp-content/uploads/2022/10/SISTELIN.webp)",
                        }
                      : null
                  }
                  src={articuloCurrent.imagen}
                  alt=""
                  className="image"
                />
                <div className="footerImage">
                  <input
                    type="file"
                    accept=" .jpg, .png"
                    name="imagen"
                    id="imagen"
                    onChange={(e) => {
                      if (
                        e.target.files[0].name.substring(
                          e.target.files[0].name.length - 4
                        ) === ".png" ||
                        e.target.files[0].name.substring(
                          e.target.files[0].name.length - 4
                        ) === ".jpg"
                      ) {
                        setImage(e.target.files[0]);
                        setFoto(true);
                      } else {
                        showToast(
                          "error",
                          "Solo se pueden subir ficheros png y jpg, no se subira ningún fichero"
                        );
                      }
                    }}
                  />
                  <button
                    type="submit"
                    className="button"
                    onClick={async () => {
                      if (esFoto) {
                        try {
                          const url = await uploadFile(
                            image,
                            `imagenes/${articuloCurrent.id}`
                          );
                          setArticulo({ ...articuloCurrent, imagen: url });
                        } catch (err) {
                          showToast("error", err);
                        }
                      } else {
                        showToast(
                          "error",
                          "Solo se pueden subir ficheros png y jpg, no se subira ningún fichero"
                        );
                      }
                    }}
                  >
                    Subir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Proveedor Habitual
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    isSearchable={true}
                    value={
                      articuloCurrent?.proveedor?.id
                        ? proveedoresStore.find(
                            (proveedor) =>
                              proveedor.id === articuloCurrent?.proveedor?.id
                          )
                        : null
                    }
                    options={proveedoresStore}
                    getOptionLabel={(proveedor) => proveedor.nombre}
                    getOptionValue={(proveedor) => proveedor.id}
                    onChange={(val) => {
                      setArticulo({
                        ...articuloCurrent,
                        proveedor: { id: val.id, nombre: val.nombre },
                      });
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      if (articuloCurrent.nombre) {
                        setArticulo({
                          ...articuloCurrent,
                          proveedor: null,
                        });
                        setProveedor({});
                        setIsOpenProveedores(true);
                      } else {
                        showToast(
                          "error",
                          "Al menos debes de rellenar el nombre del articulo"
                        );
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      if (articuloCurrent.proveedor) {
                        cargarProveedor(articuloCurrent.proveedor.id);
                        setIsOpenProveedores(true);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Proveedores
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    isMulti={true}
                    isSearchable={true}
                    value={
                      articuloCurrent.proveedores
                        ? articuloCurrent.proveedores
                        : ""
                    }
                    options={proveedoresStore}
                    getOptionLabel={(proveedor) => proveedor.nombre}
                    getOptionValue={(proveedor) => proveedor.id}
                    onChange={(val) => {
                      let tempPro = [];
                      val.map((pro) => {
                        const temProInt = { id: pro.id, nombre: pro.nombre };
                        tempPro.push(temProInt);
                      });
                      setArticulo({ ...articuloCurrent, proveedores: tempPro });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Iva
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={articuloCurrent?.iva ? articuloCurrent?.iva : ""}
                    options={tiposIvasStore}
                    getOptionLabel={(iva) => iva.nombre}
                    getOptionValue={(iva) => iva.id}
                    onChange={(val) =>
                      setArticulo({ ...articuloCurrent, iva: val })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      setArticulo({ ...articuloCurrent, iva: {} });
                      setIva({});
                      setIsOpenIva(true);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      cargarIva(articuloCurrent?.iva?.id);
                      setIsOpenIva(true);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={() => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el tipo de iva?"
                      );
                      if (confirmDelete) {
                        console.log(articuloCurrent);
                        delDato("tiposIvas", articuloCurrent.iva.id);
                        setArticulo({ ...articuloCurrent, iva: null });
                        loadTiposIvas();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Precio de Compra
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Precio de compra"
                  name="precioCompra"
                  id="precioCompra"
                  value={
                    articuloCurrent?.precioCompra
                        ? articuloCurrent?.precioCompra.toString().replace('.', ',')
                        : (articuloCurrent.precioCompra = 0)
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label className="label">Precio de Venta</label>
                <input
                  className="text"
                  type="text"
                  placeholder="precio de venta"
                  name="precioVenta"
                  id="precioVenta"
                  onChange={handleChange}
                  value={
                    articuloCurrent?.precioVenta
                        ? articuloCurrent?.precioVenta.toString().replace('.', ',')
                        : (articuloCurrent.precioVenta = 0)
                  }
                />
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Unidades de medidas
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      articuloCurrent.unidadMedida
                        ? articuloCurrent.unidadMedida
                        : ""
                    }
                    options={medidasStore}
                    getOptionLabel={(medida) => medida.nombre}
                    getOptionValue={(medida) => medida.id}
                    onChange={(val) => {
                      setArticulo({
                        ...articuloCurrent,
                        unidadMedida: val,
                        unidadCompra: null,
                      });
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      const nombre = prompt("Inserte el nuevo tipo de medida");
                      if (nombre !== "" && nombre !== null) {
                        const id = await insert("unidadesMedida", { nombre });
                        setArticulo({
                          ...articuloCurrent,
                          unidadMedida: { id, nombre },
                          unidadCompra: null,
                        });
                        loadMedidas();
                      }
                    }}
                  />

                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el tipo de medida?"
                      );
                      if (confirmDelete) {
                        console.log(
                          articuloCurrent,
                          articuloCurrent.unidadMedida.id
                        );
                        await delDato(
                          "unidadesMedida",
                          articuloCurrent.unidadMedida.id
                        );
                      }
                      setArticulo({
                        ...articuloCurrent,
                        unidadMedida: null,
                        unidadCompra: null,
                      });
                      loadMedidas();
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Unidades de compra
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Cantidad de unidades"
                  name="unidadCompra"
                  id="unidadCompra"
                  onChange={handleChange}
                  value={
                    articuloCurrent.unidadCompra
                      ? articuloCurrent.unidadCompra
                      : ""
                  }
                />
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label className="label">Control de stock</label>
                <div className="text switchText">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={articuloCurrent.controlStock}
                      defaultChecked={articuloCurrent.controlStock}
                      onChange={() => {
                        articuloCurrent.controlStock =
                          !articuloCurrent.controlStock;
                        setArticulo({
                          ...articuloCurrent,
                          controlStock: articuloCurrent.controlStock,
                        });
                      }}
                    />
                    <div className="slider round"></div>
                  </label>
                </div>
              </div>
              <div
                className={
                  articuloCurrent.controlStock
                    ? "grupo visible"
                    : "grupo hidden"
                }
              >
                <label className="label">Stock minimo</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Stock Minimo"
                  name="stockMinimo"
                  id="stockMinimo"
                  onChange={handleChange}
                  value={articuloCurrent.stockMinimo}
                />
              </div>
              <div1L
                className={
                  articuloCurrent.controlStock
                    ? "grupo visible"
                    : "grupo hidden"
                }
              >
                <label className="label">Stock</label>
                <div className="grupoSelect">
                  <input
                    type="text"
                    className="text"
                    placeholder="Stock"
                    name="stock"
                    id="stock"
                    disabled
                    value={articuloCurrent.stock}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Actualizar"
                    onClick={async () => {
                      const stock = prompt("Introduce el Stock contado");
                      if (stock) {
                        setArticulo({
                          ...articuloCurrent,
                          stock,
                        });
                      }
                    }}
                  />
                </div>
              </div1L>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={modalIsOpenIva}
        onRequestClose={closeModalIva}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",

            position: "absolute",
            right: "20px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalIva}
        />
        <Iva cerrar={closeModalIva} llamadoDesde={"articulo"} />
      </Modal>
      <Modal
        isOpen={modalIsOpenProveedores}
        onRequestClose={closeModalProveedores}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",
            height: "30px",
            width: "30px",
            position: "absolute",
            right: "20px",
          }}
          onClick={closeModalProveedores}
        />
        <FormProveedores
          cerrar={closeModalProveedores}
          idProveedorSelected={articuloCurrent.proveedor?.id}
          llamadoDesde={"articulos"}
        />
      </Modal>
      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
    </div>
  );
}
