import React, { useEffect,useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";


export default function ListadoAgentes() {
  const {loadAgentes,agentesStore} = useStore();

  const data = useMemo(() => agentesStore, [agentesStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Tipo", accessor: "tipo.nombre" },
      { Header: "Contacto", accessor: "contacto" },
      { Header: "Telefono", accessor: "telefono" },
      { Header: "E-Mail", accessor: "mail" },
      { Header: "Tipo de comision", accessor: "tipoComision.nombre" },
      {
        Header: "Comision",
        accessor: (d) => {
          if (d.tipoComision?.nombre === "Importe fijo")
            return `${d.comision.toString().replace('.', ',')} €`;
          else if (d.tipoComision?.nombre === "Porcentaje")
          return `${d.comision.toString().replace('.', ',')} %`;
        else
        return "No"
        },
      },
    ],
    []
  );

  useEffect(() => {
    loadAgentes();
  }, []);
  return <Table columns={columns} data={data} />;
}
