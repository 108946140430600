import React, { useState } from "react";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { showToast } from "../../utils/utils";

const Timing = ({ evento, setEvento, enable, idEvento }) => {
  const [hora, setHora] = useState("");
  const [concepto, setConcepto] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [valid, setValid] = useState(true);

  const agregarFila = () => {
    if (valid) {
      const nuevaFila = {
        id: new Date().getTime(),
        hora,
        concepto,
        observaciones,
      };

      const nuevosTiming = evento?.timings
        ? [...evento.timings, nuevaFila]
        : [nuevaFila];
      setEvento({ ...evento, timings: nuevosTiming });
      setHora("");
      setConcepto("");
      setObservaciones("");
    }else{
      showToast("error","Revise el formato de la hora debe ser hh:mm")
    }
  };

  const handleTimeChange = (event) => {
    const inputValue = event.target.value.replace(/[^\d:.]/g, '').substring(0, 5)
    const pattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/; // HH:mm pattern
    setHora(inputValue);
    if (pattern.test(inputValue) || inputValue === "") {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <div className={idEvento ? (enable ? "enabled" : "disabled") : "enabled"}>
      <form>
        <div className="datos" style={{ border: "none" }}>
          <div className="columna">
            <div className="grupo">
              <label className="label">Hora:</label>
              <input
                className="text"
                type="text"
                value={hora}
                onChange={handleTimeChange}
                style={{ borderColor: valid ? "initial" : "red" }}
              />
            </div>
          </div>
          <div className="columna">
            <div className="grupo">
              <label className="label">Concepto:</label>
              <input
                type="text"
                className="text"
                value={concepto}
                onChange={(e) => setConcepto(e.target.value)}
              />
            </div>
          </div>
          <div className="columna">
            <div className="grupo">
              <label className="label">Observaciones:</label>
              <textarea
                className="text"
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
              />
            </div>
          </div>
          <button type="button" onClick={agregarFila}>
            <AiOutlinePlusCircle size={"1.5em"} color="#CB950E" />
          </button>
        </div>
      </form>
      {evento.timings?.length > 0 ? (
        <div className="datos">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              margin: "20px",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  display: "flex",
                  marginRight: "25px",
                }}
              >
                <th style={{ textAlign: "left", flex: 3 }}>Hora</th>
                <th style={{ textAlign: "left", flex: 3 }}>Concepto</th>
                <th style={{ textAlign: "left", flex: 12 }}>Obsercasiones</th>
                <th style={{ textAlign: "left", flex: 1 }}></th>
              </tr>
            </thead>
            <tbody>
              {evento?.timings?.map((dato, index) => (
                <tr
                  key={dato.id}
                  style={{
                    display: "flex",
                    marginRight: "25px",
                    backgroundColor: index % 2 === 0 ? "white" : "#f2f2f2",
                  }}
                >
                  <td style={{ flex: 3 }}>{dato.hora}</td>
                  <td style={{ flex: 3 }}>{dato.concepto}</td>
                  <td style={{ flex: 12, whiteSpace: "pre-line" }}>
                    {dato.observaciones}
                  </td>
                  <td style={{ flex: 1 }}>
                    {
                      <AiOutlineCloseCircle
                        size={"1.5em"}
                        color="#CB950E"
                        onClick={() => {
                          // Crea una nueva lista sin el elemento en el índice dado
                          var nuevaLista = evento.timings.filter(
                            (_, i) => i !== index
                          );
                          // Actualiza el estado de la lista con la nueva lista
                          setEvento({
                            ...evento,
                            timings: nuevaLista,
                          });
                        }}
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default Timing;
