import { useState } from "react";
import { Sidebar, Center, Topbar } from "../components/index";
import "../css/home.scss";

export function Main() {
  const [menu, setMenu] = useState("");

  const sendMenu = (menuSend) => {
    setMenu(menuSend);
  };

  return (
    <div className="home">
      <Topbar className="topbar" data={menu} />
      <div className="principal">
        <Sidebar className="sidebar" data={menu} sendMenu={sendMenu} />
        <Center className="center" />
      </div>
    </div>
  );
}
