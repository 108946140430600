import {
  Document,
  Page,
  Text,
  View,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import styles from "../../css/cssEvento";
import logo from "../../img/logoCampuzano.png";
import mail from "../../img/icons/mail.png";
import ubicacion from "../../img/icons/ubicacion.png";
import phono from "../../img/icons/phono.png";
import moment from "moment";

const Evento = (props) => {
  const { doc } = props;
  return (
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <Document title="Contrato">
        <Page size="A4" {...props} style={styles.page}>
          <View style={styles.encabezado} fixed>
            <View style={styles.header}>
              <View style={styles.encabezadoIzquierdo}>
                <Text style={styles.l1}>
                  CONTRATO DE PRESTACIÓN DE SERVICIOS / RESERVA
                </Text>
                <Text style={styles.l2}>Campuzano Catering</Text>
                <Text style={styles.l3}>
                  {moment(new Date()).format(
                    "[En Sevilla a] DD [de] MMMM [de] YYYY"
                  )}
                </Text>
                <Text style={styles.l4}>
                  {doc.nombre} -{" "}
                  {moment(doc?.fecha?.toDate()).format("DD/MM/YYYY")} -{" "}
                  {doc.tiposEvento.nombre}
                </Text>
              </View>
              <View style={styles.encabezadoDerecho}>
                <Image
                  className="logo"
                  src={logo}
                  alt="logo"
                  style={styles.logo}
                />
              </View>
            </View>
          </View>
          <View>
            <View style={styles.contenedorClientes}>
              <View style={styles.conRecuadro}>
                <Text> DATOS DEL CLIENTE </Text>
              </View>
              <View style={styles.datosClientes}>
                <View style={styles.lineaCliente}>
                  <View style={styles.campoCliente}>
                    <Text style={styles.boldText}>Cliente 1: </Text>
                    <Text>{doc.cliente?.nombre}</Text>
                  </View>
                  <View style={styles.campoCliente}>
                    <Text style={styles.boldText}>
                      {doc.cliente?.tiposIdentificacion.nombre}:{" "}
                    </Text>
                    <Text>{doc.cliente?.cifDni}</Text>
                  </View>
                  <View style={styles.campoCliente}>
                    <Text style={styles.boldText}>Teléfono: </Text>
                    <Text>{doc.cliente?.telefono}</Text>
                  </View>
                </View>
                <View style={styles.lineaCliente}>
                  <View style={styles.campoCliente}>
                    <Text style={styles.boldText}>Domicilio: </Text>
                    <Text>{`${doc.cliente?.direccion} ${
                      doc.cliente?.localidad ? doc.cliente?.localidad : ""
                    } ${doc.cliente?.cp ? doc.cliente?.cp : ""} ${
                      doc.cliente?.provincia ? doc.cliente?.provincia : ""
                    } ${doc.cliente?.pais ? doc.cliente?.pais : ""}`}</Text>
                  </View>
                  <View style={styles.campoCliente}>
                    <Text style={styles.boldText}>Email: </Text>
                    <Text>{doc.cliente?.mail}</Text>
                  </View>
                </View>
              </View>
              {doc.cliente?.nombre2 ? (
                <View style={styles.datosClientes}>
                  <View style={styles.lineaCliente}>
                    <View style={styles.campoCliente}>
                      <Text style={styles.boldText}>Cliente 1: </Text>
                      <Text>{doc.cliente?.nombre2}</Text>
                    </View>
                    <View style={styles.campoCliente}>
                      <Text style={styles.boldText}>
                        {doc.cliente?.tiposIdentificacion2.nombre}:{" "}
                      </Text>
                      <Text>{doc.cliente?.cifDni2}</Text>
                    </View>
                    <View style={styles.campoCliente}>
                      <Text style={styles.boldText}>Teléfono: </Text>
                      <Text>{doc.cliente?.telefono2}</Text>
                    </View>
                  </View>
                  <View style={styles.lineaCliente}>
                    <View style={styles.campoCliente}>
                      <Text style={styles.boldText}>Domicilio: </Text>
                      <Text>{`${doc.cliente?.direccion2} ${
                        doc.cliente?.localidad2 ? doc.cliente?.localidad2 : ""
                      } ${doc.cliente?.cp2 ? doc.cliente?.cp2 : ""} ${
                        doc.cliente?.provincia2 ? doc.cliente?.provincia2 : ""
                      } ${doc.cliente?.pais2 ? doc.cliente?.pais2 : ""}`}</Text>
                    </View>
                    <View style={styles.campoCliente}>
                      <Text style={styles.boldText}>Email: </Text>
                      <Text>{doc.cliente.mail2}</Text>
                    </View>
                  </View>
                </View>
              ) : null}
            </View>
            <View style={styles.contenedorClientes}>
              <View style={styles.conRecuadro}>
                <Text>
                  {moment(new Date()).format("[ FECHA DEL EVENTO] DD-MM-YYYY ")}
                </Text>
              </View>
              <View style={styles.datosClientes}>
                <View style={styles.lineaCliente}>
                  <View style={styles.campoCliente}>
                    <Text style={styles.boldText}>Horario: </Text>
                    <Text>{doc.horario?.nombre}</Text>
                  </View>
                  <View style={styles.campoCliente}>
                    <Text style={styles.boldText}>Salon: </Text>
                    <Text>{doc.espacio?.nombre}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.cabecera}>
              <Text>REUNIDOS</Text>
            </View>
            <View style={styles.texto}>
              <Text>
                De una parte EL CLIENTE indicado en la cabecera del documento y
                de otra, Sergio Mulet García con D.N.I. número 28.924.622-Y, en
                calidad de administrador único de BAJOPLATO S.L., en adelante
                CATERING CAMPUZANO, con domicilio en Sevilla, Calle Joaquín
                Costa 25, y con CIF B- 90046434.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Reconociéndose las partes la capacidad legal y representación
                necesarias para otorgar el presente contrato de prestación de
                servicios de banquete, en adelante el Contrato.
              </Text>
            </View>
            <View style={styles.cabecera}>
              <Text>EXPONEN</Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Que el CLIENTE es el único PROMOTOR y ORGANIZADOR del evento, el
                cual contrata a CATERING CAMPUZANO para que preste el servicio
                de alimentación y bebida, así como de la recepción y atención a
                los invitados durante todo el desarrollo del evento, aportando
                el personal y los medios materiales necesarios para la
                realización del servicio.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Del mismo modo, CATERING CAMPUZANO se encargará del montaje y
                desmontaje de la infraestructura necesaria para el desarrollo
                del servicio contratado, incluyendo los medios materiales y
                humanos necesario para tal fin.
              </Text>
            </View>
            <View style={styles.cabecera}>
              <Text>CLÁUSULAS</Text>
            </View>
            <View style={styles.texto}>
              <Text>
                1.- OBJETO DEL CONTRATO: Constituye el objeto principal del
                contrato la prestación de servicio de banquete.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                2.- CONDICIONES PARTICULARES DEL SERVICIO: Las condiciones
                particulares del servicio se reflejará en el CONTRATO DE MENÚ Y
                SERVICIOS, que se firmará una vez realizada la prueba de menú.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                3- DURACIÓN DEL CONTRATO: La duración del contrato vendrá
                establecida por la fecha de firma del presente contrato y la
                finalización del servicio.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                4.- LUGAR DE CELEBRACIÓN: El evento se celebrará en el lugar
                indicado en la cabecera del presente contrato, en adelante, EL
                LOCAL.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Ambas partes conocen las características del LOCAL y las
                condiciones establecidas por éste para el desarrollo del evento,
                tales como horarios, aforo y normas de uso.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Los gastos de alquiler del LOCAL correrán a cargo del CLIENTE.
                <Text style={styles.boldText}>
                  Catering Campuzano no se hace cargo de cualquier tipo de canon
                  u otro gasto exigido por el espacio contratado por el cliente,
                </Text>
                a excepción de algunas fincas y haciendas que tengan algún tipo
                de acuerdo con Catering Campuzano.
                <Text style={styles.boldText}>
                  Las fincas, haciendas o cualquier tipo de espacio donde se
                  vaya a desarrollar el evento deben disponer de tomas de luz,
                  agua y sombra en la zona de cocina y office; cualquier gastos
                  generado en este sentido correrá a cargo del cliente.
                </Text>
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                5.- NUMERO DE COMENSALES: el número de comensales mínimo se
                indica al final del presente contrato, referido como
                &quot;Número de adultos:&quot;. El número mínimo final de
                invitados se establecerá en el PRESUPUESTO FINAL una vez
                conocido los detalles del evento después de la prueba de menú,
                <Text style={styles.boldText}>
                  pudiendo éste modificar hasta cinco días antes de la fecha del
                  evento y siendo este número el mínimo a facturar.
                </Text>
              </Text>
            </View>
            <View style={styles.texto}>
              <Text style={styles.boldText}>
                En caso de producirse una bajada de comensales respecto a lo
                reflejado en el PRESUPUESTO FINAL fuera del plazo indicado se
                aplicará un suplemento del 25% del importe no facturado de
                acuerdo a la siguiente formula:
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                (Precio del menú * Nº de comensales a la baja * 25% )/comensales
                finales &lt;--
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Ejemplo: Bajada de 25 comensales sobre un presupuesto de 100,00
                € para 150 comensales: (100,00 € * 25 * 25%)/125 = 5,00 €
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Los menús infantiles y técnicos no afectarán al cómputo para el
                cumplimiento de esta cláusula.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                6.- PRECIOS DE LOS SERVICIOS: Los precios y condiciones
                aplicables a los servicios serán los establecidos en el
                PRESUPUESTO FINAL que se enviará al cliente una vez realizada la
                prueba de menú y conocido los detalles del evento, y se basarán
                en el presupuesto inicial entregado al cliente o, en ausencia de
                éste, la carta de menús de Catering Campuzano entregada al
                CLIENTE.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                7.- FACTURACIÓN Y FORMA DE PAGO: En concepto de depósito y
                reserva y como anticipo del importe total, EL CLIENTE abonará la
                cantidad de mil cien euros (1.100.00 €) mediante transferencia
                bancaria al número de cuenta IBAN: ES67 0182 1640 1102 0168 8369
                (BBVA), BIC/SWIFT: BBVAESMM a nombre de Bajoplato S.L. indicando
                la fecha y lugar del evento, importe que se deducirá del
                montante total de la factura. --&gt;15 días antes del evento se
                realizará un pago del 75%; la cantidad restante se abonará en un
                plazo máximo de 72 horas.&lt;--
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                8.- DAÑOS Y DESPERFECTOS: Si hubiera algún tipo de desperfecto
                en las instalaciones, materiales o cualquier elemento propiedad
                del LOCAL o CATERING CAMPUZANO o puesto a disposición de éste
                mediante cualquier tipo de contrato, ocasionado por alguno de
                los invitados al servicio descrito, la responsabilidad directa
                sobre los daños ocasionados recaerá sobre EL CLIENTE.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                9.- RESPONSABILIDAD CIVIL: CATERING CAMPUZANO tiene contratada
                póliza de responsabilidad civil, cuya renovación es automática
                con carácter anual. Así mismo, CATERING CAMPUZANO declara estar
                en posesión del Número de Registro Sanitario 26.016731/SE y
                garantiza la calidad higiénico-sanitaria de cada uno de los
                procesos mediante la implantación, mantenimiento y verificación
                continua de un sistema de autocontrol conforme a los principios
                APPCC. En ningún caso CATERING CAMPUZANO será responsable
                directo o subsidiario de los daños o perjuicios producidos por
                actos u omisiones imputables a personas ajenas a la prestación
                del servicio.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                10.- PROTECCIÓN DE DATOS: El tratamiento de datos e información
                de carácter personal a los que las partes pudieran acceder con
                motivo de la ejecución del contrato, recibirán el tratamiento
                previsto en la legislación vigente.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                11.- EXTINCIÓN DEL CONTRATO: En caso de producirse la suspensión
                del evento por causas imputables a EL CLIENTE, éste podrá
                posponer el evento a una fecha acordada por ambas partes en
                función de la disponibilidad de CATERING CAMPUZANO. En caso
                contrario, CATERING CAMPUZANO tendrá derecho a hacer suya la
                cantidad inicial entregada en concepto de reserva. Así mismo,
                CATERING CAMPUZANO podrá exigir el pago de los gastos que, en su
                caso, hubieran sido asumidos como consecuencia de la
                contratación de servicios o realización de compras para el
                evento.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                No obstante, en caso de producirse la suspensión del evento por
                alguna causa de fuerza mayor derivada de la pandemia originada
                por el COVID-19, como la imposibilidad de viajar, la prohibición
                de realizar celebraciones u otra medida restrictiva que impida
                la celebración del evento se procederá a la devolución íntegra
                de la señal. En caso de haberse realizado la prueba de menús
                antes de la suspensión del evento se descontará del importe de
                la señal 60,00 € por comensal que haya asistido a la prueba.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                En caso de producirse la suspensión de la boda por causas
                imputables a CAMPUZANO CATERING, EL CLIENTE tendrá derecho a la
                devolución de todas las cantidades que, en su caso, le hubieran
                sido entregadas a cuenta.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                12.- GENERALIDADES: El contrato y sus anexos, que forman parte
                integrante del mismo a todos los efectos, representan el único
                acuerdo válido entre las partes en relación con el objeto de
                este y dejan sin efecto cualesquiera otros contratos o acuerdos
                alcanzados por las partes con anterioridad en relación con el
                mismo objeto, ya sean orales o escritos.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Ninguna modificación del contrato o cualquiera de sus anexos
                será válida si no se formaliza por escrito y está firmada por
                las partes.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                El contrato, así como las relaciones contractuales que se puedan
                derivar del mismo, se regirán por las propias estipulaciones y,
                en lo no previsto expresamente en ellas, supletoriamente por lo
                establecido en el Código Civil.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Las partes se someten de común acuerdo a la jurisdicción de los
                Juzgados y Tribunales de la Ciudad de Sevilla, con expresa
                renuncia a su propio fuero, si fuese distinto, para cuanto se
                derive de la celebración, interpretación y ejecución del
                Contrato.
              </Text>
            </View>
            <View style={styles.texto}>
              <Text>
                Y en prueba de conformidad con lo expresado, las partes firman
                el presente contrato en duplicado, en el lugar y fecha señalados
                en el encabezamiento.
              </Text>
            </View>
            <View style={styles.firmas}>
              <View style={styles.section}>
                <Text>EL CLIENTE :</Text>
                <View style={styles.signature} />
              </View>
              <View style={styles.section}>
                <Text> CATERING CAMPUZANO :</Text>
                <View style={styles.signature} />
              </View>
            </View>
          </View>
          <View style={styles.footer} fixed>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Página ${pageNumber} / ${totalPages}`
              }
            />
            <View style={styles.info}>
              <View style={styles.infoSeccion}>
                <Image src={mail} style={styles.footerImg} />
                <Text>info@cateringcampuzano.com</Text>
              </View>
              <View style={styles.infoSeccion}>
                <Image src={phono} style={styles.footerImg} />
                <Text>695595411 | 66170212</Text>
              </View>
              <View style={styles.infoSeccion}>
                <Image src={ubicacion} style={styles.footerImg} />
                <View>
                  <Text>C/ Betis 19, Bajo Derecha</Text>
                  <Text> 41020 Sevilla - Sevilla</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default Evento;
