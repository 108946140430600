import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useStore } from "../../store/appStore";
import Select from "react-select";
import { showToast } from "../../utils/utils";
import { useAuth } from "../../context/authContext";
import Modal from "react-modal";
import Tipo from "../tipo/tipo";
import ReactDatePicker from "react-datepicker";

function Datos({ enable, idEvento }) {
  const [comensalSelecionado, setComensalSelecionado] = useState();
  const [cantidad, setCantidad] = useState();
  const [IsOpenDoc, setIsOpenDoc] = useState(false);
  const { insert, delDato, updateDato, authUser } = useAuth();
  const {
    eventoCurrent,
    setEvento,
    agentesStore,
    setAgente,
    cargarAgente,
    clientesStore,
    espaciosStore,
    setEspacio,
    setEspacioIsOpen,
    cargarEspacio,
    cargarCliente,
    loadClientes,
    tiposEventosStore,
    loadTiposEventos,
    tiposMenusStore,
    loadTiposMenus,
    tiposMontajesStore,
    loadTiposMontajes,
    tiposCeremoniasStore,
    horariosStore,
    proveedoresStore,
    loadTiposComensales,
    tiposComensalesStore,
  } = useStore();

  const handleChange = ({ target: { value, name } }) => {
    console.log(name === "minimoComensales");
    if (name === "cantidad") {
      value = value.replace(/[^\d,.]/g, "");
      setCantidad(value);
      return;
    }
    if (name === "minimoComensales") {
      value = value.replace(/[^\d,.]/g, "");
      console.log(name, value);
    }
    setEvento({ ...eventoCurrent, [name]: value });
  };
  function closeModalDoc() {
    setIsOpenDoc(false);
  }

  const handleAddToList = () => {
    if (comensalSelecionado && cantidad !== "") {
      let tempoComensales = [];
      const newItem = {
        nombre: comensalSelecionado.nombre,
        cantidad: parseInt(cantidad),
      };

      eventoCurrent.comensales?.map((comensal) => {
        const temComensalInt = {
          nombre: comensal.nombre,
          cantidad: comensal.cantidad,
        };
        tempoComensales.push(temComensalInt);
      });
      tempoComensales.push(newItem);

      setEvento({ ...eventoCurrent, comensales: tempoComensales });
    }
    setComensalSelecionado("");
    setCantidad("");
  };

  const handleEliminarComensal = (index) => {
    const nuevosComensales = eventoCurrent.comensales.filter(
      (_, i) => i !== index
    );
    setEvento({ ...eventoCurrent, comensales: nuevosComensales });
  };
  return (
    <div className={idEvento ? (enable ? "enabled" : "disabled") : "enabled"}>
      <div className="datos" style={{ border: "none" }}>
        <div className="columna">
          <div className="grupo">
            <label htmlFor="" className="label">
              Cliente
            </label>
            <div className="grupoSelect">
              <Select
                className="select"
                classNamePrefix="selectClass"
                isSearchable={true}
                value={
                  eventoCurrent?.cliente?.id
                    ? clientesStore.find(
                        (cliente) => cliente.id === eventoCurrent?.cliente?.id
                      )
                    : null
                }
                options={clientesStore}
                getOptionLabel={(cliente) => cliente.nombre}
                getOptionValue={(cliente) => cliente.id}
                onChange={(cliente) =>
                  setEvento({
                    ...eventoCurrent,
                    cliente,
                  })
                }
              />
              <input
                className="button"
                type="submit"
                value="Añadir"
                onClick={() => {
                  if (parseInt(eventoCurrent.pax || eventoCurrent.pax) !== 0) {
                    setEvento({
                      ...eventoCurrent,
                      cliente: null,
                    });
                    setEvento({});
                    //setClienteIsOpen(true);
                  } else {
                    showToast(
                      "error",
                      "Debe introducir antes los comensales minimos"
                    );
                  }
                }}
              />
              <input
                className="button"
                type="submit"
                value="Ver"
                onClick={() => {
                  if (eventoCurrent?.cliente?.id) {
                    cargarCliente(eventoCurrent.cliente?.id);
                    //setClienteIsOpen(true);
                  }
                }}
              />
            </div>
          </div>
          <div className="grupo">
            <label htmlFor="" className="label">
              Comercial
            </label>
            <div className="grupoSelect">
              <Select
                className="select"
                classNamePrefix="selectClass"
                isSearchable={true}
                value={
                  eventoCurrent?.comercial?.id
                    ? agentesStore.find(
                        (comercial) =>
                          comercial.id === eventoCurrent?.comercial?.id
                      )
                    : null
                }
                options={agentesStore}
                getOptionLabel={(comercial) => comercial.nombre}
                getOptionValue={(comercial) => comercial.id}
                onChange={(val) => {
                  setEvento({
                    ...eventoCurrent,
                    comercial: {
                      id: val.id,
                      nombre: val.nombre,
                      tipoComision: val.tipoComision,
                      comision: val.comision,
                    },
                  });
                }}
              />
              <input
                className="button"
                type="submit"
                value="Añadir"
                onClick={() => {
                  if (eventoCurrent.pax || eventoCurrent.pax === 0) {
                    setEvento({
                      ...eventoCurrent,
                      comercial: null,
                    });
                    setAgente({});
                    //setAgenteIsOpen(true);
                  } else {
                    showToast(
                      "error",
                      "Debe introducir antes los comensales minimos"
                    );
                  }
                }}
              />

              <input
                className="button"
                type="submit"
                value="Ver"
                onClick={() => {
                  console.log(eventoCurrent);
                  if (eventoCurrent?.comercial?.id) {
                    cargarAgente(eventoCurrent?.comercial?.id);
                    //setAgenteIsOpen(true);
                  }
                }}
              />
            </div>
          </div>
          <div className="grupo">
            <label htmlFor="" className="label">
              Espacio
            </label>
            <div className="grupoSelect">
              <Select
                className="select"
                classNamePrefix="selectClass"
                isSearchable={true}
                value={
                  eventoCurrent?.espacio?.id
                    ? espaciosStore.find(
                        (espacio) => espacio.id === eventoCurrent?.espacio?.id
                      )
                    : null
                }
                options={espaciosStore}
                getOptionLabel={(espacio) => espacio.nombre}
                getOptionValue={(espacio) => espacio.id}
                onChange={(val) => {
                  setEvento({
                    ...eventoCurrent,
                    espacio: {
                      id: val.id,
                      nombre: val.nombre,
                      tipoComision: val.tipoComision ? val.tipoComision : null,
                      canon: val.canon ? val.canon : null,
                      comision: val.comision ? val.comision : null,
                    },
                  });
                }}
              />
              <input
                className="button"
                type="submit"
                value="Añadir"
                onClick={() => {
                  if (eventoCurrent.pax || eventoCurrent.pax === 0) {
                    setEvento({
                      ...eventoCurrent,
                      espacio: null,
                    });
                    setEspacio({});
                    setEspacioIsOpen(true);
                  } else {
                    showToast(
                      "error",
                      "Debe introducir antes los comensales minimos"
                    );
                  }
                }}
              />
              <input
                className="button"
                type="submit"
                value="Ver"
                onClick={() => {
                  console.log(eventoCurrent);
                  if (eventoCurrent?.espacio?.id) {
                    cargarEspacio(eventoCurrent?.espacio?.id);
                    setEspacioIsOpen(true);
                    console.log("hecho");
                  }
                }}
              />
            </div>
          </div>
          <div className="grupo">
            <label htmlFor="" className="label">
              Proveedores
            </label>
            <div className="grupoSelect">
              <Select
                className="select"
                isMulti={true}
                isSearchable={true}
                value={
                  eventoCurrent.proveedores ? eventoCurrent.proveedores : ""
                }
                options={proveedoresStore}
                getOptionLabel={(proveedor) => proveedor.nombre}
                getOptionValue={(proveedor) => proveedor.id}
                onChange={(val) => {
                  let tempPro = [];
                  val.map((pro) => {
                    const temProInt = { id: pro.id, nombre: pro.nombre };
                    tempPro.push(temProInt);
                  });
                  setEvento({ ...eventoCurrent, proveedores: tempPro });
                }}
              />
            </div>
          </div>
          {eventoCurrent.tiposEvento?.nombre === "Boda" ? (
            <div className="grupo">
              <label htmlFor="" className="label">
                Fecha
              </label>
              <div className="grupoSelect">
                <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  locale="es"
                  placeholderText="Fecha de la prueba de Menú"
                  selected={
                    eventoCurrent?.fechaMenu
                      ? typeof eventoCurrent?.fechaMenu === "string" ||
                        eventoCurrent?.fechaMenu instanceof Date
                        ? eventoCurrent?.fechaMenu
                        : eventoCurrent?.fechaMenu.toDate()
                      : null
                  }
                  onChange={(fechaMenu) =>
                    setEvento({ ...eventoCurrent, fechaMenu })
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="columna">
          <div className="grupo">
            <label className="label">Tipo de Evento</label>
            <div className="grupoSelect">
              <Select
                className="select"
                classNamePrefix="selectClass"
                isSearchable={true}
                value={
                  eventoCurrent?.tiposEvento ? eventoCurrent?.tiposEvento : ""
                }
                options={tiposEventosStore}
                getOptionLabel={(tipo) => tipo.nombre}
                getOptionValue={(tipo) => tipo.id}
                onChange={(val) =>
                  setEvento({
                    ...eventoCurrent,
                    tiposEvento: val,
                  })
                }
              />
              <input
                className="button"
                type="submit"
                value="Añadir"
                onClick={
                  () => setIsOpenDoc(true)
                  /*const nombre = prompt("Inserte el nuevo tipo de evento");
                  if (nombre !== null && nombre !== "") {
                    const id = await insert("tiposEventos", {
                      nombre,
                    });
                    setEvento({
                      ...eventoCurrent,
                      tiposEvento: { id, nombre },
                    });
                    loadTiposEventos();
                  }}*/
                }
              />
              <input
                className="button"
                type="submit"
                value="Eliminar"
                onClick={async () => {
                  const confirmDelete = window.confirm(
                    "¿Seguro que desea eliminar el tipo de evento?"
                  );
                  if (confirmDelete) {
                    await delDato("tiposEventos", eventoCurrent.tiposEvento.id);
                  }
                  loadTiposEventos();
                  setEvento({
                    ...eventoCurrent,
                    tiposEvento: null,
                  });
                }}
              />
            </div>
          </div>
          <div className="grupo">
            <label className="label">Tipo de Menú</label>
            <div className="grupoSelect">
              <Select
                className="select"
                classNamePrefix="selectClass"
                isSearchable={true}
                value={eventoCurrent?.tiposMenu ? eventoCurrent?.tiposMenu : ""}
                options={tiposMenusStore}
                getOptionLabel={(tipo) => tipo.nombre}
                getOptionValue={(tipo) => tipo.id}
                onChange={(val) =>
                  setEvento({
                    ...eventoCurrent,
                    tiposMenu: val,
                  })
                }
              />
              <input
                className="button"
                type="submit"
                value="Añadir"
                onClick={async () => {
                  const nombre = prompt("Inserte el nuevo tipo de menú");
                  if (nombre !== null && nombre !== "") {
                    const id = await insert("tiposMenus", {
                      nombre,
                    });
                    setEvento({
                      ...eventoCurrent,
                      tiposMenu: { id, nombre },
                    });
                    loadTiposMenus();
                  }
                }}
              />
              <input
                className="button"
                type="submit"
                value="Eliminar"
                onClick={async () => {
                  const confirmDelete = window.confirm(
                    "¿Seguro que desea eliminar el tipo de menú?"
                  );
                  if (confirmDelete) {
                    await delDato("tiposMenus", eventoCurrent.tiposmenu.id);
                  }
                  loadTiposMenus();
                  setEvento({
                    ...eventoCurrent,
                    tiposMenu: null,
                  });
                }}
              />
            </div>
          </div>
          <div className="grupo">
            <label className="label">Tipo de Montaje</label>
            <div className="grupoSelect">
              <Select
                className="select"
                classNamePrefix="selectClass"
                isSearchable={true}
                value={
                  eventoCurrent?.tiposMontaje ? eventoCurrent?.tiposMontaje : ""
                }
                options={tiposMontajesStore}
                getOptionLabel={(tipo) => tipo.nombre}
                getOptionValue={(tipo) => tipo.id}
                onChange={(val) =>
                  setEvento({
                    ...eventoCurrent,
                    tiposMontaje: val,
                  })
                }
              />
              <input
                className="button"
                type="submit"
                value="Añadir"
                onClick={async () => {
                  const nombre = prompt("Inserte el nuevo tipo de montaje");
                  if (nombre !== null && nombre !== "") {
                    const id = await insert("tiposMontajes", {
                      nombre,
                    });
                    setEvento({
                      ...eventoCurrent,
                      tiposMontaje: { id, nombre },
                    });
                    loadTiposMontajes();
                  }
                }}
              />
              <input
                className="button"
                type="submit"
                value="Eliminar"
                onClick={async () => {
                  const confirmDelete = window.confirm(
                    "¿Seguro que desea eliminar el tipo de montaje?"
                  );
                  if (confirmDelete) {
                    await delDato(
                      "tiposMontajes",
                      eventoCurrent.tiposMontaje.id
                    );
                  }
                  loadTiposMontajes();
                  setEvento({
                    ...eventoCurrent,
                    tiposMontaje: null,
                  });
                }}
              />
            </div>
          </div>
          {eventoCurrent.tiposEvento?.nombre === "Boda" ? (
            <div className="grupo">
              <label className="label">Tipo de Ceremonia</label>
              <div className="grupoSelect">
                <Select
                  className="select"
                  classNamePrefix="selectClass"
                  isSearchable={true}
                  value={
                    eventoCurrent?.tiposCeremonia
                      ? eventoCurrent?.tiposCeremonia
                      : ""
                  }
                  options={tiposCeremoniasStore}
                  getOptionLabel={(tipo) => tipo.nombre}
                  getOptionValue={(tipo) => tipo.id}
                  onChange={(val) =>
                    setEvento({
                      ...eventoCurrent,
                      tiposCeremonia: val,
                    })
                  }
                />
              </div>
            </div>
          ) : null}
          <div className="grupo">
            <label className="label">Franja Horaria</label>
            <div className="grupoSelect">
              <Select
                className="select"
                classNamePrefix="selectClass"
                isSearchable={true}
                value={eventoCurrent?.horario ? eventoCurrent?.horario : ""}
                options={horariosStore}
                getOptionLabel={(tipo) => tipo.nombre}
                getOptionValue={(tipo) => tipo.id}
                onChange={(val) =>
                  setEvento({
                    ...eventoCurrent,
                    horario: val,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="datos">
        <div style={{ flex: 4, display: "flex" }} className="grupo">
          <label style={{ flex: 4 }} className="label">
            Tipo de comensal
          </label>
          <div style={{ flex: 8 }}>
            <Select
              className="select"
              classNamePrefix="selectClass"
              isSearchable={true}
              style={{ flex: 8 }}
              value={comensalSelecionado}
              options={tiposComensalesStore}
              getOptionLabel={(tipo) => tipo.nombre}
              getOptionValue={(tipo) => tipo.id}
              onChange={(val) => setComensalSelecionado(val)}
            />
          </div>
          <input
            style={{
              border: "1px solid lightgray",
              width: "5em",
              height: "2em",
              margin: "5px",
            }}
            value={cantidad}
            name="cantidad"
            onChange={handleChange} //(val)=>{setCantidad(val.target.value) }}
          />
          <input
            className="button"
            type="submit"
            onClick={handleAddToList}
            value={"Sumar"}
          />
          <input
            className="button"
            type="submit"
            value="Añadir"
            onClick={async () => {
              const nombre = prompt("Inserte el nuevo tipo de comensal");
              if (nombre !== null && nombre !== "") {
                const id = await insert("tiposComensales", {
                  nombre,
                });
                setComensalSelecionado(id, nombre);
                loadTiposComensales();
              }
            }}
          />
          <input
            className="button"
            type="submit"
            value="Eliminar"
            onClick={async () => {
              const confirmDelete = window.confirm(
                "¿Seguro que desea eliminar el tipo de comensal?"
              );
              if (confirmDelete) {
                await delDato(
                  "tiposComensales",
                  eventoCurrent.tiposComensal.id
                );
              }
              loadTiposComensales();
            }}
          />
        </div>
        <div className="grupo" style={{ marginLeft: "20px", flex: 2 }}>
          <h2 style={{ fontWeight: "bold" }}>
            Comensales: (minimo:{" "}
            <input
              style={{
                border: "1px solid lightgray",
                width: "5em",
                height: "2em",
                margin: "5px",
              }}
              value={eventoCurrent.minimoComensales}
              name="minimoComensales"
              onChange={handleChange} //(val)=>{setCantidad(val.target.value) }}
            />
            ){" "}
          </h2>
          <ul style={{ marginLeft: "10px" }}>
            {eventoCurrent?.comensales?.map((item, index) => (
              <li style={{ display: "flex" }} key={index}>
                <label style={{ flex: 7 }}>
                  {" "}
                  {item.nombre}: {item.cantidad} personas
                </label>
                <AiOutlineCloseCircle
                  style={{ flex: 1 }}
                  size={"1.5em"}
                  color="#CB950E"
                  onClick={() => handleEliminarComensal(index)}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Modal
        isOpen={IsOpenDoc}
        onRequestClose={closeModalDoc}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "30px",

            position: "absolute",
            right: "30px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalDoc}
        />
        <Tipo doc={eventoCurrent} cerra={closeModalDoc} />
      </Modal>
    </div>
  );
}

export default Datos;
