/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select from "react-select";
import Pedido from "../pdfs/imprimirPedido";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Cargando from "../cargando/cargando";
import Modal from "react-modal";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { FormProveedores } from "../proveedores";
import PedidoPlaza from "./PedidoPlaza";
import GrupoSelect from "../GrupoSelect";

export default function FormPedidos() {
  Modal.setAppElement("#root");
  registerLocale("es", es);
  const [proveedorIsOpen, setClienteIsOpen] = useState(false);
  const [enable, setEnable] = useState(false);
  const [cargando, setCargando] = useState(true);
  let ultimaFamilia = "";
  const [modalIsOpenDoc, setIsOpenDoc] = useState(false);
  const location = useLocation();
  function closeClienteModal() {
    setClienteIsOpen(false);
  }
  function closeModalDoc() {
    setIsOpenDoc(false);
  }
  const [modalIsOpenPlaza, setIsOpenPlaza] = useState(false);
  function closeModalPlaza() {
    setIsOpenPlaza(false);
  }
  const { idPedido } = useParams();
  const ivas = {};
  const total = {};
  const navigate = useNavigate();
  const { insert, delDato, updateDato } = useAuth();
  const {
    proveedoresStore,
    loadProveedores,
    loadArticulosCompra,
    loadVentas,
    articulosCompraStore,
    setPedido,
    pedidoCurrent,
    cargarPedido,
    setCliente,
    cargarCliente,
    setUnsavedChanges,
  } = useStore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      switch (e.target.value) {
        case "Añadir":
          if (
            pedidoCurrent.proveedor?.nombre &&
            pedidoCurrent.proveedor?.nombre !== null &&
            pedidoCurrent.proveedor?.nombre !== ""
          ) {
            try {
              setUnsavedChanges(false);
              const modifiedArticulos = pedidoCurrent.articulos.map(
                (articulo) => {
                  const precio = parseFloat(
                    articulo.precio.toString().replace(",", ".")
                  );
                  const cantidad = parseFloat(
                    articulo.cantidad?.toString().replace(",", ".")
                  );
                  const descuento = parseFloat(
                    articulo.descuento?.toString().replace(",", ".")
                  );
                  return {
                    ...articulo,
                    precio: isNaN(precio) ? 0 : precio,
                    cantidad: isNaN(cantidad) ? 0 : cantidad,
                    descuento: isNaN(descuento) ? 0 : descuento,
                  };
                }
              );
              const modifiedData = {
                ...pedidoCurrent,
                articulos: modifiedArticulos,
              };
              const id = await insert("pedidos", modifiedData);
              navigate(`/pedido/listado/${id}`);
            } catch (error) {
              console.log(error);
            }
          } else {
            showToast("error", "Debes seleccionar al menos un proveedor");
          }
          break;
        case "Agregar":
          var artiSelect = pedidoCurrent.articulos;
          //artiSelect.push(articulo);
          setPedido({ ...pedidoCurrent, articulos: artiSelect });
          break;
        case "Calcular Precio":
          let total = 0;
          pedidoCurrent.articulos.map(
            // eslint-disable-next-line array-callback-return
            (articulo) => {
              total +=
                parseFloat(articulo.precio) * parseFloat(articulo.cantidad);
            }
          );
          setPedido({ ...pedidoCurrent, precio: total.toFixed(2) });
          break;
        case "Eliminar":
          const confirmDelete = window.confirm(
            `¿Seguro que desea eliminar el presupuesto?`
          );
          if (confirmDelete) {
            setUnsavedChanges(false);
            await delDato("pedidos", idPedido);
            loadVentas();
            navigate(`/pedido/listado`);
          }
          break;
        case "Modificar":
          const confirmUpdate = window.confirm(
            "¿Seguro que desea modificar la pedido"
          );
          if (confirmUpdate) {
            setUnsavedChanges(false);
            const modifiedArticulos = pedidoCurrent.articulos.map(
              (articulo) => {
                const precio = parseFloat(
                  articulo.precio.toString().replace(",", ".")
                );
                const cantidad = parseFloat(
                  articulo.cantidad?.toString().replace(",", ".")
                );
                const descuento = parseFloat(
                  articulo.descuento?.toString().replace(",", ".")
                );
                return {
                  ...articulo,
                  precio: isNaN(precio) ? 0 : precio,
                  cantidad: isNaN(cantidad) ? 0 : cantidad,
                  descuento: isNaN(descuento) ? 0 : descuento,
                };
              }
            );
            const modifiedData = {
              ...pedidoCurrent,
              articulos: modifiedArticulos,
            };
            try {
              await updateDato("pedidos", idPedido, modifiedData);
              setEnable(false);
              navigate(`/pedido/listado`);
            } catch (err) {
              console.log(err);
            }
          } else {
            pedidoCurrent.numero = null;
          }
          break;
        case "Editar":
          setUnsavedChanges(true);
          setEnable(true);
          break;
        case "Imprimir":
          setIsOpenDoc(true);
          break;
        default:
          break;
      }
    } catch (err) {}
  };

  const handleChange = (e, i, campo) => {
    const valor = e.target.value.replace(/[^\d,.]/g, "");
    const articulos = pedidoCurrent.articulos;
    var total = 0;
    if (campo === "cantidad") {
      articulos[i].cantidad = valor;
    } else if (campo === "precio") {
      articulos[i].precio = valor;
    } else {
      articulos[i].descuento = valor;
    }
    const precio =
      parseFloat(articulos[i].cantidad.toString().replace(",", ".")) *
      parseFloat(articulos[i].precio.toString().replace(",", "."));

    articulos[i].total = parseFloat(precio);
    // eslint-disable-next-line array-callback-return
    articulos.map((articulo) => {
      total += parseFloat(articulo.total.toString().replace(",", ".")).toFixed(
        2
      );
    });
    setPedido({
      ...pedidoCurrent,
      articulos,
      total: parseFloat(total),
    });
  };
  async function datos() {
    await Promise.all([loadProveedores(), loadArticulosCompra()]);
    if (idPedido) {
      await cargarPedido(idPedido);
      if (pedidoCurrent === null) {
        showToast("error", "El documento no existe");
        navigate("/articulos");
      }
    }
    setCargando(false);
  }
  useEffect(() => {
    return () => {
      setIsOpenDoc(false);
    };
  }, [location]);
  useEffect(() => {
    datos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerEliminar = (indice) => {
    console.log(indice);
    // Crea una nueva lista sin el elemento en el índice dado

    var nuevaLista = pedidoCurrent.articulos.filter((_, i) => i !== indice);

    // Actualiza el estado de la lista con la nueva lista
    setPedido({
      ...pedidoCurrent,
      articulos: nuevaLista,
    });
  };

  function funcionTotales() {
    pedidoCurrent?.articulos?.map((articulo) => {
      var precio = parseFloat(
        parseFloat(articulo.precio?.toString().replace(",", ".")) *
          parseFloat(articulo.cantidad?.toString().replace(",", "."))
      );
      if (total["Total"]) {
        total["Total"] += precio;
      } else {
        total["Total"] = precio;
      }
    });
    const result = [];
    for (const iva in ivas) {
      result.push({
        iva: iva,
        total: ivas[iva],
      });
    }
    const result2 = [];
    for (const iva in total) {
      result.push({
        iva: iva,
        total: total[iva],
      });
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          margin: "40px",
          marginBottom: "20px",
        }}
      >
        {result.map((result) => {
          return (
            <div>
              <label htmlFor="" className="label">
                {result.iva}
              </label>
              <label htmlFor="" className="label">
                {result?.total
                  ? result?.total?.toFixed(2).toString().replace(".", ",")
                  : 0}
              </label>
            </div>
          );
        })}
        {result2.map((result) => {
          return (
            <div>
              <label htmlFor="" className="label">
                {result.iva}
              </label>
              <label htmlFor="" className="label">
                {result?.total
                  ? result?.total?.toFixed(2).toString().replace(".", ",")
                  : 0}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">
            {idPedido ? "Modificar " : "Añadir "}
            Pedido
          </label>
          <div className="buttonMain">
            {idPedido ? (
              <>
                <input
                  className="button main"
                  type="submit"
                  value="Imprimir"
                  onClick={handleSubmit}
                />
                {enable ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <>
                    {!pedidoCurrent?.numero ? (
                      <input
                        className="button main"
                        type="submit"
                        value="Editar"
                        onClick={handleSubmit}
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <>
                <input
                  className="button main"
                  type="submit"
                  value="Añadir"
                  onClick={handleSubmit}
                />
                {pedidoCurrent.articulos ? null : (
                  <input
                    style={{
                      display:
                        pedidoCurrent?.tipoBoda &&
                        pedidoCurrent?.comensales > 0 &&
                        pedidoCurrent?.transporte
                          ? "block"
                          : "none",
                    }}
                    className="button main"
                    type="submit"
                    value="Generar"
                    onClick={() => setIsOpenPlaza(true)}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={idPedido ? (enable ? "enabled" : "disabled") : "enabled"}
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Proveedor
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={
                      pedidoCurrent?.proveedor?.id
                        ? proveedoresStore.find(
                            (proveedor) =>
                              proveedor.id === pedidoCurrent?.proveedor?.id
                          )
                        : null
                    }
                    options={proveedoresStore}
                    getOptionLabel={(proveedor) => proveedor.nombre}
                    getOptionValue={(proveedor) => proveedor.id}
                    onChange={(val) =>
                      setPedido({
                        ...pedidoCurrent,
                        proveedor: {
                          id: val.id,
                          nombre: val.nombre,
                          cifDni: val.cifDni,
                        },
                      })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      if (pedidoCurrent.ref || pedidoCurrent.ref === 0) {
                        setPedido({ ...pedidoCurrent, proveedor: null });
                        setCliente({});
                        setClienteIsOpen(true);
                      } else {
                        showToast(
                          "error",
                          "Debe introducir antes la referencia"
                        );
                      }
                    }}
                  />

                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      console.log(pedidoCurrent.proveedor);
                      if (pedidoCurrent?.proveedor?.id) {
                        cargarCliente(pedidoCurrent.proveedor?.id);
                        setClienteIsOpen(true);
                      }
                    }}
                  />
                </div>
              </div>
              <GrupoSelect
                current={pedidoCurrent}
                label="Tipo de boda"
                campo="tipoBoda"
                datos={[
                  { id: "clasica", nombre: "Clásica" },
                  { id: "coctel", nombre: "Cóctel" },
                ]}
                set={setPedido}
              />
              <div className="grupo">
                <label className="label">Comensales</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Referencia"
                  name="ref"
                  id="ref"
                  value={
                    pedidoCurrent?.comensales ? pedidoCurrent?.comensales : 0
                  }
                  onChange={(comensales) =>
                    setPedido({
                      ...pedidoCurrent,
                      comensales: comensales?.target?.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="columna">
              <div className="grupo">
                <label className="label">Referencia</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Referencia"
                  name="ref"
                  id="ref"
                  value={pedidoCurrent?.ref ? pedidoCurrent?.ref : 0}
                  onChange={(ref) =>
                    setPedido({ ...pedidoCurrent, ref: ref?.target?.value })
                  }
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Fecha del Pedido
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Fecha pedido"
                  locale="es"
                  selected={
                    pedidoCurrent?.fecha
                      ? typeof pedidoCurrent.fecha === "string" ||
                        pedidoCurrent.fecha instanceof Date
                        ? pedidoCurrent.fecha
                        : pedidoCurrent.fecha.toDate()
                      : null
                  }
                  onChange={(fecha) => setPedido({ ...pedidoCurrent, fecha })}
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Fecha del Evento
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Fecha pedido"
                  locale="es"
                  selected={
                    pedidoCurrent?.fechaEvento
                      ? typeof pedidoCurrent.fechaEvento === "string" ||
                        pedidoCurrent.fechaEvento instanceof Date
                        ? pedidoCurrent.fechaEvento
                        : pedidoCurrent.fechaEvento.toDate()
                      : null
                  }
                  onChange={(fechaEvento) =>
                    setPedido({ ...pedidoCurrent, fechaEvento })
                  }
                />
              </div>
              <GrupoSelect
                current={pedidoCurrent}
                label="Transporte"
                campo="transporte"
                datos={[
                  { id: "proveedor", nombre: "Proveedor" },
                  { id: "campuzano", nombre: "Campuzano" },
                ]}
                set={setPedido}
              />
            </div>
          </div>
          <div
            className="datos"
            style={{
              display:
                pedidoCurrent?.tipoBoda &&
                pedidoCurrent?.comensales > 0 &&
                pedidoCurrent?.transporte
                  ? "block"
                  : "none",
            }}
          >
            <div className="columna segunda">
              <table className="table">
                <thead className="thead">
                  <tr className="fila">
                    <th className="columna1">Producto</th>
                    <th className="columna2">Cantidad</th>
                    <th className="columna2">Precio</th>
                    <th className="columna2">Subtotal</th>
                    <th style={{ marginRight: "20px" }}></th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {pedidoCurrent?.articulos?.map((articulo, i) => (
                    <React.Fragment key={i}>
                      {articulo.familia.nombre !== ultimaFamilia
                        ? (ultimaFamilia = articulo.familia.nombre)
                        : null}
                      <tr className="fila2" key={i}>
                        <td className="columna1">
                          <label
                            className="label"
                            style={{
                              whiteSpace: "pre-line",
                              wordWrap: "break-word",
                            }}
                          >
                            {articulo.nombre}
                          </label>
                        </td>
                        <td className="columna2">
                          <input
                            key={i}
                            onChange={(event) =>
                              handleChange(event, i, "cantidad")
                            }
                            type="text"
                            className="text2"
                            value={
                              articulo.cantidad
                                ? articulo.cantidad.toString().replace(".", ",")
                                : (articulo.cantidad = 0)
                            }
                          />
                        </td>
                        <td className="columna2">
                          <input
                            key={i}
                            onChange={(event) =>
                              handleChange(event, i, "precio")
                            }
                            className="text2"
                            type="text"
                            value={
                              articulo.precioTransporte > 0 &&
                              pedidoCurrent?.transporte?.nombre === "Proveedor"
                                ? articulo.precioTransporte
                                : articulo.precio
                                ? articulo.precio.toString().replace(".", ",")
                                : (articulo.precio = 0)
                            }
                          />
                        </td>
                        <td className="columna2" style={{ textAlign: "end" }}>
                          <label htmlFor="" className="label">
                            {articulo.total
                              ? articulo.total
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",")
                              : (articulo.total = 0)}
                          </label>
                        </td>
                        <td
                          onClick={() => {
                            handlerEliminar(i);
                          }}
                        >
                          <AiOutlineCloseCircle
                            style={{
                              marginRight: "20px",
                            }}
                            size={"1.5em"}
                            color="#CB950E"
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr className="fila">
                    <td className="columna1">
                      <Select
                        className="select"
                        classNamePrefix="selectClass"
                        isSearchable={true}
                        options={articulosCompraStore}
                        getOptionLabel={(tipo) => tipo.nombre}
                        getOptionValue={(tipo) => tipo.id}
                        onChange={(val) => {
                          const porPersona =
                            pedidoCurrent.tipoBoda.id === "clasica"
                              ? val.cantidadClasico
                              : val.cantidadCoctel;
                          const cantidad =
                            Math.ceil(
                              (pedidoCurrent.comensales * porPersona) /
                                val.unidades
                            ) * val.unidades;
                          const totalArticulo = val.precio * cantidad;
                          var articulo = val;
                          articulo = {
                            ...articulo,
                            cantidad,
                            total: totalArticulo,
                          };
                          if (pedidoCurrent?.articulos)
                            var artiSelect = pedidoCurrent.articulos;
                          else artiSelect = [];
                          artiSelect.push(articulo);
                          artiSelect.sort((a, b) => {
                            if (a.familia.nombre === "KIT" && b.familia.nombre !== "KIT") {
                              return -1; // a viene antes que b
                            }
                            if (a.familia.nombre !== "KIT" && b.familia.nombre === "KIT") {
                              return 1; // b viene antes que a
                            }
                            return a.familia.nombre.localeCompare(b.familia.nombre);
                          });
                          const total = pedidoCurrent.total
                            ? pedidoCurrent.total + totalArticulo
                            : totalArticulo;
                          setPedido({
                            ...pedidoCurrent,
                            articulos: artiSelect,
                            total,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="iva">{funcionTotales()}</tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>

      <Modal
        isOpen={proveedorIsOpen}
        onRequestClose={closeClienteModal}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <FormProveedores
          cerrar={closeClienteModal}
          llamadoDesde={"pedidoVenta"}
          idClienteSelected={pedidoCurrent?.proveedor?.id}
        />
      </Modal>

      <Modal
        isOpen={modalIsOpenDoc}
        onRequestClose={closeModalDoc}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "30px",

            position: "absolute",
            right: "30px",
            color: "red",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalDoc}
        />

        <Pedido doc={pedidoCurrent} cerra={closeModalDoc} />
      </Modal>
      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
      <Modal
        isOpen={modalIsOpenPlaza}
        onRequestClose={closeModalPlaza}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "25px",

            position: "absolute",
            right: "20px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalPlaza}
        />
        <PedidoPlaza cerrar={closeModalPlaza} />
      </Modal>
    </div>
  );
}
