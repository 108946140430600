import React, { useEffect, useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";

export default function ListadoEspacio() {
  const { loadEspacios, espaciosStore } = useStore();
  const data = useMemo(() => espaciosStore, [espaciosStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Contacto", accessor: "contacto" },
      { Header: "Direccion", accessor: "direccion" },
      { Header: "Localidad", accessor: "localidad" },
      { Header: "Provincia", accessor: "provincia" },
      { Header: "Telefono", accessor: "telefono" },
      { Header: "E-Mail", accessor: "mail" },
      { Header: "Tipo de comision", accessor: "tipoComision.nombre" },
      {
        Header: "Comision",
        accessor: (d) => {
          if (d.tipoComision?.nombre === "Importe fijo")
            return `${d.comision} €`;
          else if (d.tipoComision?.nombre === "Porcentaje")
          return `${d.comision} %`;
        else
        return "No"
        },
      },
    ],
    []
  );

  useEffect(() => {
    loadEspacios();
  }, []);
  return <Table columns={columns} data={data} />;
}
