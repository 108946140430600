import React, { useEffect, useState } from "react";
import { useStore } from "../../store/appStore";
import GrupoSelect from "../GrupoSelect";
import Cargando from "../cargando/cargando";
export default function PedidoPlaza(props) {
  const { cerrar } = props;

  const [cargando, setCargando] = useState(true);
  const {
    pedidoCurrent,
    setPedido,
    loadAllModelos,
    modelosStore,
    loadFamiliasCompras,
    familiasCompraStore,
    loadArticulosCompra,
    articulosCompraStore,
  } = useStore();
  async function datos() {
    await Promise.all([
      loadAllModelos(),
      loadFamiliasCompras(),
      loadArticulosCompra(),
    ]);
  }
  const [pedidoPlaza, setPedidoPlaza] = useState();
  useEffect(() => {
    datos();
  }, []);
  useEffect(() => {
    setCargando(false);
  }, [familiasCompraStore]);
  async function generarPlaza() {
    let articulos = [];
    let totalPedido = 0;
    Object.keys(pedidoPlaza).map((categoria) => {
      const articulosCategoria = articulosCompraStore.filter(
        (objeto) =>
          objeto.modelo.nombre === pedidoPlaza[categoria].nombre &&
          objeto.familia.nombre === categoria
      );
      articulosCategoria.map((val) => {
        const porPersona =
          pedidoCurrent.tipoBoda.id === "clasica"
            ? val.cantidadClasico
            : val.cantidadCoctel;
        const cantidad =
          Math.ceil((pedidoCurrent.comensales * porPersona) / val.unidades) *
          val.unidades;
        const precio =
          "KIT" in pedidoPlaza && val.incluido
            ? 0
            : val.precioTransporte > 0 &&
              pedidoCurrent?.transporte?.nombre === "Proveedor"
            ? val.precioTransporte
            : val.precio;
        const total = precio * cantidad;
        var articulo = val;
        articulo = {
          ...articulo,
          cantidad,
          total,
          precio,
        };
        totalPedido = totalPedido + total;
        articulos.push(articulo);
      });
    });
    setPedido({ ...pedidoCurrent, articulos, total: totalPedido });
    cerrar();
  }

  if (cargando) return <Cargando />;
  else
    return (
      <div className="form">
        <label htmlFor="" className="tituloVentana">
          Generar pedido
        </label>
        <div className="datos">
          <div className="columna">
            {familiasCompraStore
              .slice(0, Math.ceil(familiasCompraStore.length / 2))
              .map((familia, i) => (
                <GrupoSelect
                  key={i}
                  current={pedidoPlaza}
                  label={`${familia.nombre}`}
                  campo={familia.nombre}
                  datos={modelosStore.filter(
                    (objeto) => objeto.familia.nombre === familia.nombre
                  )}
                  set={setPedidoPlaza}
                />
              ))}
          </div>
          <div className="columna">
            {familiasCompraStore
              .slice(Math.ceil(familiasCompraStore.length / 2))
              .map((familia, i) => (
                <GrupoSelect
                  key={i}
                  current={pedidoPlaza}
                  label={`${familia.nombre}`}
                  campo={familia.nombre}
                  datos={modelosStore.filter(
                    (objeto) => objeto.familia.nombre === familia.nombre
                  )}
                  set={setPedidoPlaza}
                />
              ))}
          </div>
        </div>
        <div
          className="datos"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <input
            className="button main"
            type="submit"
            value="Generar"
            onClick={() => generarPlaza()}
          />
        </div>
      </div>
    );
}
