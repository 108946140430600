import { Document, Page, Text, View, Image, pdf } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import styles from "../../css/file.js";
import logo from "../../img/logoFile.jpg";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import Cargando from "../cargando/cargando.js";

const Venta = (props) => {
  const { doc } = props;
  const fecha = doc.fecha
    ? moment(doc.fecha.toDate()).format("DD/MM/YYYY")
    : null;
  const vencimiento = doc.fecha
    ? moment(doc.fecha.toDate()).add(doc.pago.dias, "days").toDate()
    : null;
  const vencimientoFormateado = moment(vencimiento).format("DD/MM/YYYY");
  const [blobPDF, setBlobPDF] = useState(null);
  const [blob, setBlob] = useState(null);
  const [blobUnido, setBlobUnido] = useState(null);
  const [blobCondiciones, setBlobCondiciones] = useState(null);
  const ivas = {};
  const preciosPorIva = {};
  const result = [];
  totales();
  useEffect(() => {
    downloadAndConvertToBlob();
  }, []);
  useEffect(() => {
    const myDoc = (
      <Document>
        <Page size="A4" {...props} style={styles.page}>
          <View style={styles.container}>
            <View style={styles.encabezado}>
              <View style={styles.encabezadoIzquierdo}>
                <Image
                  className="logo"
                  src={logo}
                  alt="logo"
                  style={styles.image}
                />
                <Text>BAJOPLATO S.L.</Text>
                <Text>C/ JOAQUIN COSTA 25</Text>
                <Text>41002 SEVILLA</Text>
                <Text>B90046434</Text>
                <View style={styles.contenedorCabeceraDatosDocumento}>
                  <Text style={styles.cabeceraDatosDocumento}>Documento</Text>
                  <Text style={styles.cabeceraDatosDocumento}>Número</Text>
                  <Text style={styles.cabeceraDatosDocumento}>Página</Text>
                  <Text style={styles.cabeceraDatosDocumento}>Fecha</Text>
                </View>
                <View style={styles.contenedorDatosDocumento}>
                  <Text style={styles.datosDocumento}>Factura</Text>
                  <Text style={styles.datosDocumento}>
                    {doc.numero ? doc.numero : "Borrador"}
                  </Text>
                  <Text style={styles.datosDocumento}>{}</Text>
                  <Text style={styles.datosDocumento}>{fecha}</Text>
                </View>
              </View>
              <View style={styles.encabezadoDerecho}>
                <Text>
                  {doc.cliente?.nombre
                    ? doc?.cliente?.nombre
                    : doc?.proveedor?.nombre}
                </Text>
                <Text>
                  {doc.cliente?.direccion
                    ? doc?.cliente?.direccion
                    : doc?.proveedor?.direccion}
                </Text>
                <Text>
                  {doc.cliente?.cp ? doc?.cliente?.cp : doc?.proveedor?.cp}{" "}
                  {doc.cliente?.localidad
                    ? doc?.cliente?.localidad
                    : doc?.proveedor?.localidad}
                </Text>
                <Text>
                  {doc.cliente?.provincia
                    ? doc?.cliente?.provincia
                    : doc?.proveedor?.provincia}
                </Text>
                <Text>
                  {doc.cliente?.cif
                    ? doc?.cliente?.cif
                    : doc?.proveedor?.cifDni}
                </Text>
              </View>
            </View>
            <View style={styles.contenedorCabeceraReferencia}>
              <Text style={styles.cabeceraTextoReferencia}>REFERENCIA</Text>
              <Text style={styles.cabeceraFormaPago}>FORMA DE PAGO</Text>
            </View>
            <View style={styles.contenedorDatosFormaDePago}>
              <Text style={styles.datosReferencia}>{doc.ref}</Text>
              <Text style={styles.datosFormaDePago}>
                {doc.cliente?.pago?.nombre}
              </Text>
            </View>
            <View style={styles.contenedorCabeceraArticulos}>
              <Text style={styles.cabeceraArticulos}>ARTÍCULO</Text>
              <Text style={styles.cabeceraDescripcion}>DESCRIPCIÓN</Text>
              <Text style={styles.cabeceraArticulos}>CANTIDAD</Text>
              <Text style={styles.cabeceraArticulos}>PRECIO UD.</Text>
              <Text style={styles.cabeceraArticulos}>SUBTOTAL</Text>
              <Text style={styles.cabeceraArticulos}>DTO.</Text>
              <Text style={styles.cabeceraArticulos}>TOTAL</Text>
            </View>
            <View style={styles.contenedorArticulos}>
              <View style={styles.datosArticulos}></View>
              <View style={styles.datosDescripcion}></View>
              <View style={styles.datosArticulos}></View>
              <View style={styles.datosArticulos}></View>
              <View style={styles.datosArticulos}></View>
              <View style={styles.datosArticulos}></View>
              <View style={styles.datosArticulos}></View>
              <View style={styles.textoAbsoluto}>
                {doc?.articulos?.map((articulo, i) => {
                  return (
                    <View style={styles.textoFila} key={i}>
                      <Text style={styles.textoArticulo}>
                        {articulo.cantidad}
                      </Text>
                      <Text style={styles.textoDescripcion}>
                        {articulo.nombre}
                      </Text>
                      <Text style={styles.textoArticulo}>
                        {articulo.cantidad}
                      </Text>
                      <Text style={styles.textoArticulo}>
                        {articulo.precioVenta}
                      </Text>
                      <Text style={styles.textoArticulo}>
                        {articulo.cantidad * articulo.precioVenta}
                      </Text>
                      <Text style={styles.textoArticulo}>
                        {articulo.descuento}
                      </Text>
                      <Text style={styles.textoArticulo}>{articulo.total}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={styles.contenedorCabeceraPago}>
                <Text style={styles.cabeceraDatosTipo}>TIPO</Text>
                <Text style={styles.cabeceraDatosPago}>BASE</Text>
                <Text style={styles.cabeceraDatosPago}>I.V.A.</Text>
              </View>
              <View style={styles.contenedorCabeceraBanco}>
                <Text style={styles.datosCabeceraVencimiento}>
                  Vencimientos
                </Text>
                <Text style={styles.datosCabeceraBanco}>IBAN</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "55.4%" }}>
                <View style={styles.contenedorDatosPago}>
                  <View style={styles.contenedorDatosTipo}></View>
                  <View style={styles.contenedorDatosBase}></View>
                  <View style={styles.contenedorDatosBase}></View>
                </View>
                <View style={styles.datosAbsolutos}>
                  {result?.map((iva) => {
                    console.log(iva);
                    return (
                      <View style={styles.tipoFila}>
                        <Text style={styles.datosTipo}>{iva.iva}</Text>
                        <Text style={styles.datosPago}>
                          {iva.base.toFixed(2)}
                        </Text>
                        <Text style={styles.datosPago}>
                          {iva.total.toFixed(2)}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
              <View style={styles.contenedorBanco}>
                <Text style={styles.datosVencimiento}>
                  {vencimientoFormateado}
                </Text>
                <Text style={styles.datosBanco}>
                  dsadhalidahlfhaslkfahlfhafakjfgbqkfgkfajgfsa
                </Text>
              </View>
            </View>
            <View style={styles.contenedorCabeceraObservaciones}>
              <Text style={styles.datosCabeceraObservaciones}>
                OBSERVACIONES:
              </Text>
              <View style={styles.datosCabeceraTotal}>
                <Text style={{ marginLeft: 10 }}>TOTAL:</Text>
                <Text style={{ marginRight: 10 }}>{doc.totalConIva}</Text>
              </View>
            </View>
            <View>
              <Text style={styles.datosObservaciones}></Text>
            </View>
          </View>
        </Page>
      </Document>
    );
    setBlobPDF(myDoc);
  }, []);

  useEffect(() => {
    if (blobPDF) newFunction();
    async function newFunction() {
      const bloB = await pdf(blobPDF).toBlob();
      setBlob(bloB);
    }
  }, [blobPDF]);

  useEffect(() => {
    async function mergePDFs() {
      const unidos = await concatBlobs(blob, blobCondiciones);
      const pdfUrl = URL.createObjectURL(unidos); // Obtén la URL del Blob combinado
      setBlobUnido(pdfUrl);
    }
    if (blob && blobCondiciones) mergePDFs();
  }, [blob, blobCondiciones]);

  async function concatBlobs(blob1, blob2) {
    const pdfDoc1 = await PDFDocument.load(await blob1.arrayBuffer());
    const pdfDoc2 = await PDFDocument.load(await blob2.arrayBuffer());

    // Crear un nuevo documento PDF para almacenar las páginas combinadas
    const mergedPdf = await PDFDocument.create();

    // Agregar las páginas del primer documento al nuevo documento
    const pages1 = await mergedPdf.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
    pages1.forEach((page) => mergedPdf.addPage(page));

    // Agregar las páginas del segundo documento al nuevo documento
    const pages2 = await mergedPdf.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
    pages2.forEach((page) => mergedPdf.addPage(page));

    // Generar un nuevo Blob con el PDF combinado
    const mergedPdfBytes = await mergedPdf.save();
    return new Blob([mergedPdfBytes], { type: "application/pdf" });
  }

  async function downloadAndConvertToBlob() {
    const fileURL =
      "https://firebasestorage.googleapis.com/v0/b/cateringcampuzano-3cf1f.appspot.com/o/documentos%2Ffactura?alt=media&token=f8465421-7d2c-435e-abc5-149186c5c6c9"; // Reemplaza con la URL del archivo en Firebase Storage.

    try {
      const response = await fetch(fileURL);
      if (!response.ok) {
        throw new Error("Error al descargar el archivo.");
      }
      const blob = await response.blob();
      setBlobCondiciones(blob);
      console.log("Blob del PDF:", blob);
    } catch (error) {
      console.error("Error al descargar y convertir el archivo:", error);
    }
  }
  return blobCondiciones ? (
    <object data={blobUnido} type="application/pdf" style={{ height: "100%", width: "100%" }}>
      Fichero PDF no cargado correctamente
    </object>
  ) : (
    <Cargando />
  );

  function totales() {
    doc?.articulos?.map((articulo) => {
      const iva = articulo.iva?.nombre;
      if (iva) {
        var precio = articulo.precioVenta * articulo.cantidad;
        const descuento = precio * (articulo.descuento / 100);
        precio = precio - descuento;
        if (ivas[iva]) {
          ivas[iva] += (articulo.iva?.porcentaje * precio) / 100;
          preciosPorIva[iva] += precio;
        } else {
          ivas[iva] = (articulo.iva?.porcentaje * precio) / 100;
          preciosPorIva[iva] = precio;
        }
      }
    });
    for (const iva in ivas) {
      result.push({
        iva: iva,
        total: ivas[iva],
        base: preciosPorIva[iva],
      });
    }
  }
};

export default Venta;
