import React from "react";
import Select from "react-select";

export default function GrupoSelect(props) {
  const { campo, current, set, datos, label, style,load,insert,delDato,tabla } = props;

  return (
    <div style={style} className="grupo">
      <label htmlFor="" className="label">
        {label}
      </label>
      <div className="grupoSelect">
        <Select
          className="select"
          classNamePrefix="selectClass"
          isSearchable={true}
          value={
            current?.[campo]?.id
              ? datos.find((datos) => datos.id === current?.[campo]?.id)
              : null
          }
          options={datos}
          getOptionLabel={(campo) => campo.nombre}
          getOptionValue={(campo) => campo.id}
          onChange={(val) => {
            set({
              ...current,
              [campo]: { id: val.id, nombre: val.nombre },
            });
            console.log(current);
          }}
        />
      </div>
      {insert ? <input
        className="button"
        type="submit"
        value="Añadir"
        onClick={async () => {
          const nombre = prompt("Inserte el nuevo tipo de cliente");
          if (nombre !== "" && nombre !== null) {
            const id = await insert(tabla, { nombre });
            load();
            set({
              ...current,
              tipo: { id, nombre },
            });
          }
        }}
      /> : null}
      {delDato ? <input
        className="button"
        type="submit"
        value="Eliminar"
        onClick={async () => {
          const confirmDelete = window.confirm(
            "¿Seguro que desea eliminar el registro?"
          );
          if (confirmDelete) {
            await delDato(tabla, current.tipo.id);
          }
          load();
          set({ ...current, tipo: null });
        }}
      /> : null}
    </div>
  );
}
