import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import Select from "react-select";
import { CardContactos } from "../contactos";
import { CardPresupuestos } from "../presupuestos/cardPresupuestos";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CardVentas } from "../facturasVenta/cardVenta";
import { FormAgente } from "../agente";
import Cargando from "../cargando/cargando";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useStore } from "../../store/appStore";
import Modal from "react-modal";
import Pago from "../pago/pago";
import { CardEventos } from "../eventos";

export default function FormClientes(props) {
  Modal.setAppElement("#root");
  const location = useLocation();
  const {
    clienteCurrent,
    presupuestoCurrent,
    ventaCurrent,
    cargarCliente,
    setCliente,
    setAgente,
    agentesStore,
    loadAgentes,
    pagosStore,
    loadPagos,
    loadClientes,
    tiposIdentificacionesStore,
    loadTiposIdentificaciones,
    tiposClientesStore,
    loadTiposClientes,
    loadSeries,
    seriesStore,
    cargarPago,
    cargarAgente,
    setPago,
    setPresupuesto,
    setVenta,
    setUnsavedChanges,
  } = useStore();
  const { cerrar, idClienteSelected, llamadoDesde } = props;
  const { delDato, updateDato, insert } = useAuth();
  const [enable, setEnable] = useState(false);
  const [modalIsOpenPago, setIsOpenPago] = useState(false);
  function closeModalPago() {
    setIsOpenPago(false);
  }
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const [cargando, setCargando] = useState(true);
  const handleChange = ({ target: { value, name } }) => {
    if (name === "telefono" || name === "cp" || name === "descuento") {
      value = value.replace(/[^\d,.]/g, "");
    }
    setCliente({ ...clienteCurrent, [name]: value });
  };
  //

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        console.log("añadir");
        if (
          clienteCurrent?.nombre &&
          clienteCurrent?.pago &&
          clienteCurrent?.serie
        ) {
          try {
            console.log("validad");
            setUnsavedChanges(false);
            clienteCurrent.descuento = parseFloat(
              clienteCurrent.descuento?.toString().replace(",", ".")
            );
            const id = await insert("clientes", clienteCurrent);
            console.log(id);
            if (presupuestoCurrent.pax) {
              setPresupuesto({
                ...presupuestoCurrent,
                cliente: { ...clienteCurrent, id },
              });
              console.log(clienteCurrent);
              loadClientes();
              cerrar();
            } else if (ventaCurrent.ref) {
              setVenta({
                ...ventaCurrent,
                cliente: { ...clienteCurrent, id },
                pago: clienteCurrent.pago,
                serie: clienteCurrent.serie,
              });
              loadClientes();
              cerrar();
            } else navigate(`/clientes/listado/${id}`);
          } catch (err) {
            console.log(err);
          }
        } else {
          showToast(
            "error",
            "Al menos debe rellenar el nombre fiscal , seleccionar una serie de factura y una forma de pago"
          );
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al cliente?"
        );
        if (confirmDelete) {
          setCliente({});
          try {
            setUnsavedChanges(false);
            switch (llamadoDesde) {
              case "presupuestos":
                await delDato("clientes", idClienteSelected);
                setPresupuesto({ ...presupuestoCurrent, cliente: null });
                loadClientes();
                cerrar();
                break;
              case "facturaVenta":
                await delDato("clientes", idClienteSelected);
                setVenta({ ...ventaCurrent, cliente: null });
                loadClientes();
                cerrar();
                break;
              default:
                await delDato("clientes", id);
                navigate("/clientes/listado");
                break;
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al cliente?"
        );
        if (
          confirmUpdate &&
          clienteCurrent?.nombre &&
          clienteCurrent?.pago &&
          clienteCurrent?.serie
        ) {
          setUnsavedChanges(false);
          clienteCurrent.descuento = parseFloat(
            clienteCurrent.descuento?.toString().replace(",", ".")
          );

          try {
            switch (llamadoDesde) {
              case "presupuestos":
                await updateDato(
                  "clientes",
                  presupuestoCurrent.cliente.id,
                  clienteCurrent
                );
                loadClientes();
                setPresupuesto({
                  ...presupuestoCurrent,
                  cliente: {
                    clienteCurrent,
                    id: presupuestoCurrent.cliente.id,
                  },
                });
                cerrar();
                break;
              case "facturaVenta":
                await updateDato(
                  "clientes",
                  ventaCurrent.cliente.id,
                  clienteCurrent
                );
                loadClientes();
                setVenta({
                  ...ventaCurrent,
                  cliente: {
                    clienteCurrent,
                    id: ventaCurrent.cliente.id,
                  },
                });
                cerrar();
                break;
              default:
                await updateDato("clientes", id, clienteCurrent);

                navigate("/clientes/listado");
            }
          } catch (err) {
            console.log(err);
          }
        } else if (confirmUpdate) {
          showToast(
            "error",
            "Al menos debe rellenar el nombre fiscal , seleccionar una serie de factura y una forma de pago"
          );
        }
        break;
      case "Editar":
        setUnsavedChanges(true);
        setEnable(true);
        break;
      default:
        break;
    }
  };
  async function datos() {
    await Promise.all([
      loadAgentes(),
      loadTiposClientes(),
      loadTiposIdentificaciones(),
      loadClientes(),
      loadPagos(),
      loadSeries(),
    ]);
    if (id) {
      await cargarCliente(id);

      //if (!clienteCurrent?.nombre ) {
      if (clienteCurrent === null) {
        showToast("error", "El cliente no existe");
        navigate("/clientes/listado");
      }
    }

    setCargando(false);
  }
  useEffect(() => {
    datos();
    console.log("first");
  }, [location]);
  return (
    <>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="superior">
            <label className="tituloVentana">
              {id ? "Modificar Cliente" : "Añadir Cliente"}
            </label>
            <div className="buttonMain">
              {id || idClienteSelected ? (
                enable ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <input
                    className="button main"
                    type="submit"
                    value="Editar"
                    onClick={handleSubmit}
                  />
                )
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Añadir"
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
          <div className={id ? (enable ? "enabled" : "disabled") : "enabled"}>
            <div className="datos">
              <div className="columna">
              <div className="grupo">
                  <label className="label">Tipo de Cliente</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={clienteCurrent?.tipo ? clienteCurrent?.tipo : ""}
                      options={tiposClientesStore}
                      getOptionLabel={(tipo) => tipo.nombre}
                      getOptionValue={(tipo) => tipo.id}
                      onChange={(val) =>
                        setCliente({ ...clienteCurrent, tipo: val })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={async () => {
                        const nombre = prompt(
                          "Inserte el nuevo tipo de cliente"
                        );
                        if (nombre !== "" && nombre !== null) {
                          const id = await insert("tiposClientes", { nombre });
                          loadTiposClientes();
                          setCliente({
                            ...clienteCurrent,
                            tipo: { id, nombre },
                          });
                        }
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={async () => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar el tipo de cliente?"
                        );
                        if (confirmDelete) {
                          await delDato(
                            "tiposClientes",
                            clienteCurrent.tipo.id
                          );
                        }
                        loadTiposClientes();
                        setCliente({ ...clienteCurrent, tipo: null });
                      }}
                    />
                  </div>
                </div>

                <div className="grupo">
                  <label className="label">Agente Comercial</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={agentesStore.find(
                        (comercial) =>
                          comercial.id === clienteCurrent?.comercial?.id
                      )}
                      options={agentesStore}
                      getOptionLabel={(comercial) => comercial.nombre}
                      getOptionValue={(comercial) => comercial.id}
                      onChange={(val) => {
                        setCliente({
                          ...clienteCurrent,
                          comercial: { id: val.id, nombre: val.nombre },
                        });
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={() => {
                        if (clienteCurrent.nombre) {
                          setCliente({ ...clienteCurrent, comercial: null });
                          setAgente({});
                          setIsOpen(true);
                        } else {
                          showToast(
                            "error",
                            "Al menos debes de rellenar el nombre del cliente"
                          );
                        }
                      }}
                    />

                    <input
                      className="button"
                      type="submit"
                      value="Ver"
                      onClick={() => {
                        console.log(clienteCurrent);
                        if (clienteCurrent?.comercial?.id) {
                          cargarAgente(clienteCurrent?.comercial?.id);
                          setIsOpen(true);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="grupo">
                  <label htmlFor="" className="label">
                    Forma de pago
                  </label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="select"
                      isSearchable={true}
                      value={
                        clienteCurrent?.pago?.id
                          ? pagosStore.find(
                              (pago) => pago.id === clienteCurrent?.pago?.id
                            )
                          : null
                      }
                      options={pagosStore}
                      getOptionLabel={(pago) => pago.nombre}
                      getOptionValue={(pago) => pago.id}
                      onChange={(val) =>
                        setCliente({ ...clienteCurrent, pago: val })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={() => {
                        setCliente({ ...clienteCurrent, pago: {} });
                        setPago({});
                        setIsOpenPago(true);
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Ver"
                      onClick={() => {
                        cargarPago(clienteCurrent?.pago?.id);
                        setIsOpenPago(true);
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={() => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar la forma de pago?"
                        );
                        if (confirmDelete) {
                          delDato("pagos", clienteCurrent?.pago?.id);
                          setCliente({ ...clienteCurrent, pago: {} });
                          loadPagos();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label className="label">Nombre Comercial</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Nombre comercial"
                    name="nombreComercial"
                    id="nombreComercial"
                    onChange={handleChange}
                    value={
                      clienteCurrent?.nombreComercial
                        ? clienteCurrent?.nombreComercial
                        : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">Serie Factura</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={clienteCurrent?.serie ? clienteCurrent?.serie : ""}
                      options={seriesStore}
                      getOptionLabel={(serie) => serie.nombre}
                      getOptionValue={(serie) => serie.id}
                      onChange={(val) =>
                        setCliente({ ...clienteCurrent, serie: val })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={async () => {
                        const nombre = prompt("Inserte la nueva serie");
                        if (nombre !== "" && nombre !== null) {
                          const id = await insert("series", { nombre });
                          setCliente({
                            ...clienteCurrent,
                            serie: { id, nombre },
                          });
                          loadSeries();
                        }
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={async () => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar el tipo de cliente?"
                        );
                        if (confirmDelete) {
                          await delDato("series", clienteCurrent?.serie?.id);
                        }
                        loadSeries();
                        setCliente({ ...clienteCurrent, serie: null });
                      }}
                    />
                  </div>
                </div>
                <div className="grupo">
                  <label className="label">Descuento</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="Descuento"
                    name="descuento"
                    id="descuento"
                    value={
                      clienteCurrent?.descuento
                        ? clienteCurrent?.descuento.toString().replace(".", ",")
                        : (clienteCurrent.descuento = 0)
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label className="label">Tipo de Identificación</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={
                        clienteCurrent?.tiposIdentificacion
                          ? clienteCurrent?.tiposIdentificacion
                          : ""
                      }
                      options={tiposIdentificacionesStore}
                      getOptionLabel={(tipo) => tipo.nombre}
                      getOptionValue={(tipo) => tipo.id}
                      onChange={(val) =>
                        setCliente({
                          ...clienteCurrent,
                          tiposIdentificacion: val,
                        })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={async () => {
                        const nombre = prompt(
                          "Inserte el nuevo tipo de cliente"
                        );
                        if (nombre !== null && nombre !== "") {
                          const id = await insert("tiposIdentificaciones", {
                            nombre,
                          });
                          setCliente({
                            ...clienteCurrent,
                            tiposIdentificacion: { id, nombre },
                          });
                          loadTiposIdentificaciones();
                        }
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={async () => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar el tipo de identificacion?"
                        );
                        if (confirmDelete) {
                          await delDato(
                            "tiposIdentificaciones",
                            clienteCurrent.tiposIdentificacion.id
                          );
                        }
                        loadTiposIdentificaciones();
                        setCliente({
                          ...clienteCurrent,
                          tiposIdentificacion: null,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="grupo">
                  <label className="label">Identificación Fiscal</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="Identificación fiscal"
                    name="cifDni"
                    id="cifDni"
                    onChange={handleChange}
                    value={clienteCurrent?.cifDni ? clienteCurrent?.cifDni : ""}
                  />
                </div>
                <div className="grupo">
                  <label className="label">Nombre Fiscal</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Nombre fiscal"
                    name="nombre"
                    id="nombre"
                    onChange={handleChange}
                    value={clienteCurrent?.nombre ? clienteCurrent?.nombre : ""}
                  />
                </div>
                <div className="grupo">
                  <label className="label">Telefono</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Telefono"
                    name="telefono"
                    id="telefono"
                    onChange={handleChange}
                    value={
                      clienteCurrent?.telefono ? clienteCurrent?.telefono : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">E-Mail</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="E-Mail"
                    name="mail"
                    id="mail"
                    onChange={handleChange}
                    value={clienteCurrent?.mail ? clienteCurrent?.mail : ""}
                  />
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label className="label">Dirección</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Dirección"
                    name="direccion"
                    id="direccion"
                    value={
                      clienteCurrent?.direccion ? clienteCurrent?.direccion : ""
                    }
                    onChange={handleChange}
                  />
                </div>

                <div className="grupo">
                  <label className="label">Localidad</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="Localidad"
                    name="localidad"
                    id="localidad"
                    value={
                      clienteCurrent?.localidad ? clienteCurrent?.localidad : ""
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="grupo">
                  <label className="label">Provincia</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="Provincia"
                    name="provincia"
                    id="provincia"
                    value={
                      clienteCurrent?.provincia ? clienteCurrent?.provincia : ""
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="grupo">
                  <label className="label">Código Postal</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="CP"
                    name="cp"
                    id="cp"
                    value={clienteCurrent?.cp ? clienteCurrent?.cp : ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="grupo">
                  <label className="label">País</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="País"
                    name="pais"
                    id="pais"
                    value={clienteCurrent?.pais ? clienteCurrent?.pais : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {clienteCurrent?.tipo?.nombre === "Boda" ? (
              <div className="datos">
                <div className="columna">
                  <div className="grupo">
                    <label className="label">Tipo de Identificación</label>
                    <div className="grupoSelect">
                      <Select
                        className="select"
                        classNamePrefix="selectClass"
                        isSearchable={true}
                        value={
                          clienteCurrent?.tiposIdentificacion2
                            ? clienteCurrent?.tiposIdentificacion2
                            : ""
                        }
                        options={tiposIdentificacionesStore}
                        getOptionLabel={(tipo) => tipo.nombre}
                        getOptionValue={(tipo) => tipo.id}
                        onChange={(val) =>
                          setCliente({
                            ...clienteCurrent,
                            tiposIdentificacion2: val,
                          })
                        }
                      />
                      <input
                        className="button"
                        type="submit"
                        value="Añadir"
                        onClick={async () => {
                          const nombre = prompt(
                            "Inserte el nuevo tipo de cliente"
                          );
                          if (nombre !== null && nombre !== "") {
                            const id = await insert("tiposIdentificaciones", {
                              nombre,
                            });
                            setCliente({
                              ...clienteCurrent,
                              tiposIdentificacion: { id, nombre },
                            });
                            loadTiposIdentificaciones();
                          }
                        }}
                      />
                      <input
                        className="button"
                        type="submit"
                        value="Eliminar"
                        onClick={async () => {
                          const confirmDelete = window.confirm(
                            "¿Seguro que desea eliminar el tipo de identificacion?"
                          );
                          if (confirmDelete) {
                            await delDato(
                              "tiposIdentificaciones",
                              clienteCurrent.tiposIdentificacion.id
                            );
                          }
                          loadTiposIdentificaciones();
                          setCliente({
                            ...clienteCurrent,
                            tiposIdentificacion: null,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="grupo">
                    <label className="label">Identificación Fiscal</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="Identificación fiscal"
                      name="cifDni2"
                      id="cifDni2"
                      onChange={handleChange}
                      value={
                        clienteCurrent?.cifDni2 ? clienteCurrent?.cifDni2 : ""
                      }
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">Nombre Fiscal</label>
                    <input
                      className="text"
                      type="text"
                      placeholder="Nombre fiscal"
                      name="nombre2"
                      id="nombre2"
                      onChange={handleChange}
                      value={
                        clienteCurrent?.nombre2 ? clienteCurrent?.nombre2 : ""
                      }
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">Telefono</label>
                    <input
                      className="text"
                      type="text"
                      placeholder="Telefono"
                      name="telefono2"
                      id="telefono2"
                      onChange={handleChange}
                      value={
                        clienteCurrent?.telefono2
                          ? clienteCurrent?.telefono2
                          : ""
                      }
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">E-Mail</label>
                    <input
                      className="text"
                      type="text"
                      placeholder="E-Mail"
                      name="mail2"
                      id="mail2"
                      onChange={handleChange}
                      value={clienteCurrent?.mail2 ? clienteCurrent?.mail2 : ""}
                    />
                  </div>
                </div>
                <div className="columna">
                  <div className="grupo">
                    <label className="label">Dirección</label>
                    <input
                      className="text"
                      type="text"
                      placeholder="Dirección"
                      name="direccion2"
                      id="direccion2"
                      value={
                        clienteCurrent?.direccion2
                          ? clienteCurrent?.direccion2
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </div>

                  <div className="grupo">
                    <label className="label">Localidad</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="Localidad"
                      name="localidad2"
                      id="localidad2"
                      value={
                        clienteCurrent?.localidad2
                          ? clienteCurrent?.localidad2
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">Provincia</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="Provincia"
                      name="provincia2"
                      id="provincia2"
                      value={
                        clienteCurrent?.provincia2
                          ? clienteCurrent?.provincia2
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">Código Postal</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="CP"
                      name="cp2"
                      id="cp2"
                      value={clienteCurrent?.cp2 ? clienteCurrent?.cp2 : ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">País</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="País"
                      name="pais2"
                      id="pais2"
                      value={clienteCurrent?.pais2 ? clienteCurrent?.pais2 : ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </form>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              border: "8px",
            },
            content: {
              border: "0px",
              background: 0,
            },
          }}
        >
          <AiOutlineCloseCircle
            style={{
              marginTop: "25px",

              position: "absolute",
              right: "20px",
            }}
            size={"1.5em"}
            color="#CB950E"
            onClick={closeModal}
          />
          <FormAgente
            cerrar={closeModal}
            llamadoDesde={"clientes"}
            idAgenteSelected={clienteCurrent?.comercial?.id}
          />
        </Modal>
        <Modal isOpen={cargando}>
          <Cargando />
        </Modal>
        <Modal
          isOpen={modalIsOpenPago}
          onRequestClose={closeModalPago}
          style={{
            overlay: {
              border: "8px",
            },
            content: {
              border: "0px",
              background: 0,
            },
          }}
        >
          <AiOutlineCloseCircle
            style={{
              marginTop: "25px",
              height: "30px",
              width: "30px",
              position: "absolute",
              right: "20px",
            }}
            onClick={closeModalPago}
          />
          <Pago cerrar={closeModalPago} llamadoDesde={"cliente"} />
        </Modal>
      </div>
      <Tabs className="react-tabs">
        <TabList className="react-tabs__tab-list">
          <Tab className="react-tabs__tab">Contactos</Tab>
          <Tab className="react-tabs__tab">Presupuestos</Tab>
          <Tab className="react-tabs__tab">Facturas</Tab>
          <Tab className="react-tabs__tab">Eventos</Tab>
        </TabList>
        <TabPanel className="react-tabs__tab-panel">
          <CardContactos
            llamado={"cliente"}
            contactos={clienteCurrent.contactos}
          />
        </TabPanel>
        <TabPanel className="react-tabs__tab-panel">
          <CardPresupuestos />
        </TabPanel>
        <TabPanel className="react-tabs__tab-panel">
          <CardVentas id={id} />
        </TabPanel>
        <TabPanel className="react-tabs__tab-panel">
          <CardEventos id={id} />
        </TabPanel>
      </Tabs>
    </>
  );
}
