import React, { useState, useEffect } from "react";
import { useStore } from "../../store/appStore";
import { showToast } from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
export const eliminarContactos = (id, contactos, setCliente, clienteCurrent, setAgente, agenteCurrent, setProveedor, proveedorCurrent, llamado) => {
  const nuevosContactos = contactos.filter((contacto) => contacto.id !== id);
  switch(llamado){
    case "agente":
      setAgente({...agenteCurrent,contactos:nuevosContactos});
      break;
    case "cliente":
      setCliente({...clienteCurrent,contactos: nuevosContactos});
      break;
    case "proveedor":
      setProveedor({...proveedorCurrent,contactos: nuevosContactos});
      break;
    default:
      break;
  }
};

export const CrearContacto = (props) => {
  const {
    agenteCurrent,
    setAgente,
    clienteCurrent,
    setCliente,
    proveedorCurrent,
    setProveedor,
  } = useStore();
  const [contacto, setContacto] = useState(props.contactoSeleccionado || {});

  const handleChange = ({ target: { value, name } }) => {
    setContacto({ ...contacto, [name]: value });
  }

  const [contactoExistente, setContactoExistente] = useState(false);

  useEffect(() => {
    if (props.contactos) {
      const contactoSeleccionado = props.contactos.find(
        (c) => c.id === contacto.id
      );
      if (contactoSeleccionado) {
        setContactoExistente(true);
      } else {
        setContactoExistente(false);
      }
    }
  }, [props.contactos, contacto.id]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (contacto.nombre && contacto.nombre !== "" && contacto.nombre !== null) {
      if (contactoExistente ) {
        const nuevosContactos = props.contactos.map((c) => {
          if (c.id === contacto.id) {
            return { id: c.id, ...contacto };
          }
          return c;
        });
        switch(props.llamado){
          case "agente":
            setAgente({...agenteCurrent,contactos:nuevosContactos});
            break;
          case "cliente":
            setCliente({...clienteCurrent,contactos: nuevosContactos});
            break;
          case "proveedor":
            setProveedor({...proveedorCurrent,contactos: nuevosContactos});
            break;
          default:
            break;
        }
      } else {
        const nuevoContacto = { id: uuidv4(), ...contacto };
        switch (props.llamado) {
          case "agente":
            setAgente({ ...agenteCurrent, contactos: [...(agenteCurrent.contactos ?? []), nuevoContacto] });
            console.log("es un agente");
            break;
          case "cliente":
            setCliente({ ...clienteCurrent, contactos: [...(clienteCurrent.contactos ?? []), nuevoContacto] });
            console.log("Es un cliente")
            console.log(clienteCurrent.contactos)
            break;
          case "proveedor":
            setProveedor({ ...proveedorCurrent, contactos: [...(proveedorCurrent.contactos ?? []), nuevoContacto] });
            break;
          default:
            break;
        }
        console.log(props.contactos,"Añadiendo");
        console.log(contactoExistente);
      }
    } else {
      showToast("error", "Debe al menos rellenar el nombre del contacto");
    }
    props.cerrar();
  };

  return (
    <div className="form">
      <label className="tituloVentana">Crear Contacto</label>
      <form onSubmit={handleSubmit}>
        <div className="datos">
          <div className="columna">
            <div className="grupo">
              <label className="label">Nombre</label>
              <input
                className="text"
                type="text"
                placeholder="Nombre"
                name="nombre"
                id="nombre"
                onChange={handleChange}
                value={contacto.nombre}
              />
            </div>
            <div className="grupo">
              <label className="label">Cif/Dni</label>
              <input
                className="text"
                type="text"
                placeholder="Cif/Dni"
                name="cifDni"
                id="cifDni"
                onChange={handleChange}
                value={contacto.cifDni}
              />
            </div>
            <div className="grupo">
              <label className="label">telefono</label>
              <input
                className="text"
                type="text"
                placeholder=" Telefono"
                name="telefono"
                id="telefono"
                onChange={handleChange}
                value={contacto.telefono}
              />
            </div>
            <div className="grupo">
              <label className="label">E-Mail</label>
              <input
                className="text"
                type="text"
                placeholder="Email"
                name="mail"
                id="mail"
                onChange={handleChange}
                value={contacto.mail}
              />
            </div>
          </div>
          <div className="columna">
            <div className="grupo">
              <label className="label">Direccion</label>
              <input
                className="text"
                type="text"
                placeholder="Direccion"
                name="direccion"
                id="direccion"
                onChange={handleChange}
                value={contacto.direccion}
              />
            </div>
            <div className="grupo">
              <label className="label">Localidad</label>
              <input
                className="text"
                type="text"
                placeholder="Localidad"
                name="localidad"
                id="localidad"
                onChange={handleChange}
                value={contacto.localidad}
              />
            </div>
            <div className="grupo">
              <label className="label">Codigo Postal</label>
              <input
                className="text"
                type="text"
                placeholder="Codigo Postal"
                name="cp"
                id="cp"
                onChange={handleChange}
                value={contacto.cp}
              />
            </div>
            <div className="grupo">
              <label className="label">Provincia</label>
              <input
                className="text"
                type="text"
                placeholder="Provincia"
                name="provincia"
                id="provincia"
                onChange={handleChange}
                value={contacto.provincia}
              />
            </div>

            <div className="grupo">
              <label className="label">Pais</label>
              <input
                className="text"
                type="text"
                placeholder="Pais"
                name="pais"
                id="pais"
                onChange={handleChange}
                value={contacto.pais}
              />
            </div>
          </div>
        </div>

        <input
          type="submit"
          className="button main margen"
          value={contactoExistente?"Modificar":"Guardar"}
          onClick={handleSubmit}
        />
      </form>
    </div>
  );
};
