import { Document, Page, Text, View, Image, pdf, Font } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";
import styles from "../../css/cssPresupuesto.js";
import logo from "../../img/logoFile.jpg";
import moment from "moment";
import Cargando from "../cargando/cargando.js";
// Import your local font file
import calibril from "../../font/calibril.ttf";

const Presupuesto = (props) => {
  /*
  const fecha = moment(doc?.fecha?.toDate()).format("DD/MM/YYYY");const vencimiento = moment(doc?.fecha?.toDate())
    .add(doc?.pago?.dias, "days")
    .toDate();
  const vencimientoFormateado = moment(vencimiento).format("DD/MM/YYYY");*/
  const { doc } = props;

  console.log(doc, "Documento");
  const [articulosOrdenados, setArticulosOrdenados] = useState(null);
  const [blobPDF, setBlobPDF] = useState(null);
  const [blob, setBlob] = useState(null);
  const [blobUnido, setBlobUnido] = useState(null);
  const [blobCondiciones, setBlobCondiciones] = useState(null);
  const ivas = {};
  const preciosPorIva = {};
  const result = [];
  totales();
  useEffect(() => {
    ordenarArticulos(doc.articulos);
    downloadAndConvertToBlob();
    totales();
  }, []);
  // Función para eliminar espacios en blanco al principio de cada línea
  const eliminarEspaciosEnBlanco = (cadena) => {
    return cadena
      .split("\n")
      .map((linea) => linea.trimLeft())
      .join("\n");
  };
  useEffect(() => {
    Font.register({ family: 'Calibril', src: calibril });

    if (articulosOrdenados) {
      const myDoc = (
        <Document title={titulo}>
          <Page size="A4" {...props} style={styles.page}>
            <View style={styles.container}>
              <View style={styles.encabezado}>
                <View style={styles.encabezadoIzquierdo}>
                  <Text style={styles.calibril13}>BAJOPLATO S.L.</Text>
                  <Text style={styles.calibril13}>C/ JOAQUIN COSTA 25</Text>
                  <Text style={styles.calibril13}>41002 SEVILLA</Text>
                  <Text style={styles.calibril13}>B90046434</Text>
                </View>
                <View style={styles.encabezadoDerecho}>
                  <Image
                    className="logo"
                    src={logo}
                    alt="logo"
                    style={styles.image}
                  />
                </View>
              </View>
              <View style={styles.contenedorCabeceraPresupuesto}>
                <Text  style={styles.calibril12}>
                  PRESUPUESTO{" "}
                  {moment(doc?.fecha?.toDate()).format("YYYY-MM-DD")}{" "}
                  {doc.espacio?.nombre}
                </Text>
              </View>
              <View style={styles.contenedorClientes}>
                <View style={styles.cabeceraClientes}>
                  <Text style={ [styles.calibril10,{ flex: 2 }]}>CLIENTES</Text>
                  <Text style={[styles.calibril10,{ flex: 2 }]}>FECHA</Text>
                  <Text style={[styles.calibril10,{ flex: 2 }]}>LUGAR</Text>
                  <Text style={[styles.calibril10,{ flex: 1 }]}>PAX MÍNIMO</Text>
                </View>
                <View style={styles.datosClientes}>
                  <Text style={[styles.calibril10,{ flex: 2 }]}>{doc?.cliente?.nombre}</Text>
                  <Text style={[styles.calibril10,{ flex: 2 }]}>
                    {moment(doc?.fecha?.toDate()).format("DD/MM/YYYY")}
                  </Text>
                  <Text style={[styles.calibril10,{ flex: 2 }]}>{doc?.espacio?.nombre}</Text>
                  <Text style={[styles.calibril10,{ flex: 1 }]}>{doc?.pax}</Text>
                </View>
              </View>
              <View style={styles.cabeceraContenedorMenu}>
                <View style={styles.textoCentroCabeceraMenu}>
                  <Text  style={styles.calibril10}>ARTICULOS</Text>
                </View>
              </View>
              {articulosOrdenados.map((articulos) => {
                return (
                  <View>
                    <View style={styles.datosContenedorMenu}>
                      <View style={styles.textoCentroCabeceraMenu}>
                        <Text   style={styles.calibril10}>{articulos.categoria}</Text>
                      </View>
                    </View>
                    {articulos.documentos.map((articulo) => {
                      console.log(articulo.nombre);
                      return (
                        <View style={styles.datosMenu}>
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.calibril9,{ marginRight: 10 }]}>•</Text>
                            <Text  style={styles.calibril9}>
                              {eliminarEspaciosEnBlanco(articulo.nombre)}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
              <View style={styles.lineaDivisoria}></View>
              <View style={styles.contenedorTotal}>
                <View style={styles.subcontenedorTotal}>
                  <View style={styles.datosTotal}>
                    <Text style={[styles.calibril12,{ color: "#d1920e" }]}>SUBTOTAL</Text>
                    <Text style={styles.calibril12}>
                      {result
                        ?.reduce((acumulador, iva) => acumulador + iva.base, 0)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}{" "}
                      €
                    </Text>
                  </View>

                  {result?.map((iva) => {
                    return (
                      <View style={styles.datosTotal}>
                        <Text style={[styles.calibril12,{ color: "#d1920e" }]}>{iva.iva}</Text>
                        <Text style={styles.calibril12}>
                          {iva.total.toFixed(2).toString().replace(".", ",")} €
                        </Text>
                      </View>
                    );
                  })}
                  <View style={[styles.datosTotal, { borderColor: "#dedede" }]}>
                    <Text style={[styles.calibril12,{ color: "#d1920e" }]}>TOTAL</Text>
                    <Text style={styles.calibril12}>
                      {doc.total.toFixed(2).toString().replace(".", ",")} €
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
          {doc?.opcionales?.length > 0 ? (
            <Page size="A4" {...props} style={styles.page}>
              <View style={styles.container}>
                <View style={styles.encabezado}>
                  <View style={styles.encabezadoIzquierdo}>
                  <Text style={styles.calibril13}>BAJOPLATO S.L.</Text>
                  <Text style={styles.calibril13}>C/ JOAQUIN COSTA 25</Text>
                  <Text style={styles.calibril13}>41002 SEVILLA</Text>
                  <Text style={styles.calibril13}>B90046434</Text>
                  </View>
                  <View style={styles.encabezadoDerecho}>
                    <Image
                      className="logo"
                      src={logo}
                      alt="logo"
                      style={styles.image}
                    />
                  </View>
                </View>
                <View style={styles.cabeceraContenedorMenu}>
                  <View style={styles.textoCentroCabeceraMenu2}>
                    <Text style={styles.calibril10}>Articulos opcionales I</Text>
                  </View>
                  <View style={styles.textoDerechaCabeceraMenu2}>
                    <Text style={styles.calibril10}>PRECIO</Text>
                    <Text style={styles.calibril10}>IVA</Text>
                    <Text style={styles.calibril10}>TOTAL</Text>
                  </View>
                </View>
                {doc.opcionales.map((articulo) => {
                  return (
                    <View key={articulo.id} style={styles.datosContenedorMenu}>
                      <View style={styles.textoCentroCabeceraMenu2}>
                        <Text style={styles.calibril10}>{articulo.nombre}</Text>
                      </View>
                      <View style={styles.textoDerechaCabeceraMenu2}>
                        <Text style={[styles.calibril10,{ flex: 1, textAlign: "right" }]}>
                          {articulo.precioVenta} €
                        </Text>
                        <Text style={[styles.calibril10,{ flex: 1, textAlign: "right" }]}>
                          {(articulo.precioVenta * articulo.iva.porcentaje) /
                            100}{" "}
                          €
                        </Text>
                        <Text style={[styles.calibril10,{ flex: 1, textAlign: "right" }]}>
                          {parseFloat(articulo.precioVenta) +
                            (articulo.precioVenta * articulo.iva.porcentaje) /
                              100}{" "}
                          €
                        </Text>
                      </View>
                    </View>
                  );
                })}
                <View style={styles.lineaDivisoria}></View>
              </View>
            </Page>
          ) : null}
        </Document>
      );
      setBlobPDF(myDoc);
    }
  }, [articulosOrdenados]);
  useEffect(() => {
    if (blobPDF) newFunction();
    async function newFunction() {
      const bloB = await pdf(blobPDF).toBlob();
      setBlob(bloB);
    }
  }, [blobPDF]);

  useEffect(() => {
    async function mergePDFs() {
      try {
        const unidos = await concatBlobs(blob, blobCondiciones);
        const pdfUrl = URL.createObjectURL(unidos); // Obtén la URL del Blob combinado
        setBlobUnido(pdfUrl);
      } catch (error) {
        console.log(error);
      }
    }
    if (blob && blobCondiciones) mergePDFs();
  }, [blob, blobCondiciones]);

  useEffect(() => {
    console.log("unidos", blobUnido);
  }, [blobUnido]);

  async function concatBlobs(blob1, blob2) {
    const pdfDoc1 = await PDFDocument.load(await blob1.arrayBuffer());
    const pdfDoc2 = await PDFDocument.load(await blob2.arrayBuffer());

    // Crear un nuevo documento PDF para almacenar las páginas combinadas
    const mergedPdf = await PDFDocument.create();
    mergedPdf.registerFontkit(calibril);

    // Agregar las páginas del primer documento al nuevo documento
    const pages1 = await mergedPdf.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
    pages1.forEach((page) => mergedPdf.addPage(page));

    // Agregar las páginas del segundo documento al nuevo documento
    const pages2 = await mergedPdf.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
    pages2.forEach((page) => mergedPdf.addPage(page));

    // Generar un nuevo Blob con el PDF combinado
    const mergedPdfBytes = await mergedPdf.save();
    return new Blob([mergedPdfBytes], { type: "application/pdf" });
  }

  async function downloadAndConvertToBlob() {
    const fileURL =
      "https://firebasestorage.googleapis.com/v0/b/cateringcampuzano-3cf1f.appspot.com/o/documentos%2Fpresupuesto?alt=media&token=e6b9ffd7-2b17-4a82-a277-f2e8d07ac823"; // Reemplaza con la URL del archivo en Firebase Storage.

    try {
      const response = await fetch(fileURL);
      if (!response.ok) {
        throw new Error("Error al descargar el archivo.");
      }
      const blob = await response.blob();
      setBlobCondiciones(blob);
      console.log("Blob del PDF:", blob);
    } catch (error) {
      console.error("Error al descargar y convertir el archivo:", error);
    }
  }

  const titulo =
    "Presupuesto " +
    moment(doc?.fecha?.toDate()).format("YYYY-MM-DD") +
    " " +
    doc.espacio?.nombre;
  return blobCondiciones ? (
    <object
      data={blobUnido}
      type="application/pdf"
      style={{ height: "100%", width: "100%" }}
    >
      Fichero PDF no cargado correctamente
    </object>
  ) : (
    <Cargando />
  );
  function totales() {
    doc?.articulos?.map((articulo) => {
      const iva = articulo.iva?.nombre;
      if (iva) {
        console.log(articulo, "iva");
        var precio = articulo.precioVenta;
        const descuento = precio * (articulo.descuento / 100);
        precio = precio - descuento;
        if (ivas[iva]) {
          ivas[iva] += (articulo.iva?.porcentaje * precio) / 100;
          preciosPorIva[iva] += precio;
        } else {
          ivas[iva] = (articulo.iva?.porcentaje * precio) / 100;
          preciosPorIva[iva] = precio;
        }
      }
    });
    for (const iva in ivas) {
      result.push({
        iva: iva,
        total: ivas[iva],
        base: preciosPorIva[iva],
      });
    }
    console.log(result, "resultado");
  }

  function ordenarArticulos(articulos) {
    const categoriasTemp = {};
    articulos.forEach((datos) => {
      const categoria = datos.categoria.nombre;
      if (categoriasTemp[categoria]) {
        categoriasTemp[categoria].push(datos);
      } else {
        categoriasTemp[categoria] = [datos];
      }
    });
    // Convertir el objeto de categorías en un array
    const categoriasArray = Object.entries(categoriasTemp).map(
      ([categoria, documentos]) => ({
        categoria,
        documentos,
      })
    );
    setArticulosOrdenados(categoriasArray);
  }
};

export default Presupuesto;
