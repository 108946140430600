import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select from "react-select";
//import Compra from "../pdfs/imprimirVenta";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Cargando from "../cargando/cargando";
import Modal from "react-modal";
import ReactDatePicker from "react-datepicker";
import Pago from "../pago/pago";
import FormProveedores from "../proveedores/FormProveedores";

export default function FormFacturaCompra() {
  Modal.setAppElement("#root");
  const [enable, setEnable] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [articulos, setArticulos] = useState(false);
  const [proveedorIsOpen, setProveedorIsOpen] = useState(false);
  const [modalIsOpenDoc, setIsOpenDoc] = useState(false);
  const location = useLocation();
  function closeProveedorModal() {
    setProveedorIsOpen(false);
  }
  function closeModalDoc() {
    setIsOpenDoc(false);
  }
  const [modalIsOpenPago, setIsOpenPago] = useState(false);
  function closeModalPago() {
    setIsOpenPago(false);
  }
  const { idCompra } = useParams();
  const ivas = {};
  const total = {};
  const navigate = useNavigate();
  const { insert, delDato, updateDato } = useAuth();
  const {
    loadPagos,
    loadProveedores,
    proveedoresStore,
    pagosStore,
    loadSeries,
    loadArticulos,
    loadCompras,
    articulosStore,
    setCompra,
    compraCurrent,
    cargarCompra,
    cargarProveedor,
    setPago,
    setProveedor,
    cargarPago,
    loadCombinados,
    combinadosStore,
    setUnsavedChanges
  } = useStore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      switch (e.target.value) {
        case "Añadir":
          if (
            compraCurrent.proveedor?.nombre &&
            compraCurrent.proveedor?.nombre !== null &&
            compraCurrent.proveedor?.nombre !== ""
          ) {
            setUnsavedChanges(false)
            const modifiedArticulos = compraCurrent.articulos.map((articulo) => {
              const precioVenta = parseFloat(articulo.precioVenta.toString().replace(',', '.'));
              const cantidad = parseFloat(articulo.cantidad?.toString().replace(',', '.'));
              const descuento = parseFloat(articulo.descuento?.toString().replace(',', '.'));
              return {
                ...articulo,
                precioVenta: isNaN(precioVenta) ? 0 : precioVenta,
                cantidad: isNaN(cantidad) ? 0 : cantidad,
                descuento: isNaN(descuento) ? 0 : descuento,
              };
            });
            const modifiedData = {
              ...compraCurrent,
              articulos: modifiedArticulos,
            };
          const id = await insert("compras", modifiedData);
          navigate(`/facturasCompras/listado/${id}`);
        } else {
          showToast("error", "Debes seleccionar al menos un cliente");
        }
          break;
        case "Agregar":
          var artiSelect = compraCurrent?.articulos;
          //artiSelect.push(articulo);
          setCompra({ ...compraCurrent, articulos: artiSelect });
          break;
        case "Calcular Precio":
          let total = 0;
          compraCurrent.articulos.map(
            // eslint-disable-next-line array-callback-return
            (articulo) => {
              total +=
                parseFloat(articulo.precioCompra) *
                parseFloat(articulo.cantidad);
            }
          );
          setCompra({ ...compraCurrent, precio: total.toFixed(2) });
          break;
        case "Eliminar":
          const confirmDelete = window.confirm(
            `¿Seguro que desea eliminar el presupuesto?`
          );
          if (confirmDelete) {
            setUnsavedChanges(false)
            await delDato("compras",idCompra);
            loadCompras();
            navigate(`/facturasCompras/listado`);
          }
          break;
        case "Modificar":
          const confirmUpdate = window.confirm(
            "¿Seguro que desea modificar la factura"
          );
          if (confirmUpdate) {
            setUnsavedChanges(false)
            const modifiedArticulos = compraCurrent.articulos.map((articulo) => {
              const precioVenta = parseFloat(articulo.precioVenta.toString().replace(',', '.'));
              const cantidad = parseFloat(articulo.cantidad?.toString().replace(',', '.'));
              const descuento = parseFloat(articulo.descuento?.toString().replace(',', '.'));
              return {
                ...articulo,
                precioVenta: isNaN(precioVenta) ? 0 : precioVenta,
                cantidad: isNaN(cantidad) ? 0 : cantidad,
                descuento: isNaN(descuento) ? 0 : descuento,
              };
            });
            const modifiedData = {
              ...compraCurrent,
              articulos: modifiedArticulos,
            };
            try {
              await updateDato("compras", idCompra, modifiedData);
              setEnable(false);
              navigate(`/facturasCompras/listado`);
            } catch (err) {
              console.log(err);
            }
          }
          break;
        case "Editar":
        setUnsavedChanges(true)
          setEnable(true);
          break;
        case "Imprimir":
          setIsOpenDoc(true);
          break;
        default:
          break;
      }
    } catch (err) {}
  };

  const handleChange = (e, i, campo) => {
    const valor = e.target.value.replace(/[^\d,]/g, "");
    const articulos = compraCurrent.articulos;
    var total = 0;
    var totalConIva = 0;
    if (campo === "cantidad") {
      articulos[i].cantidad = valor;
    } else if (campo === "precioCompra") {
      articulos[i].precioCompra = valor;
    } else {
      articulos[i].descuento = valor;
    }
    const precio = parseFloat(articulos[i].cantidad.toString().replace(',','.')) * parseFloat(articulos[i].precioCompra.toString().replace(',','.'));
    const descuento = (parseFloat(articulos[i].descuento.toString().replace(',','.')) / 100) * precio;
    articulos[i].total = parseFloat(precio - descuento)
    // eslint-disable-next-line array-callback-return
    articulos.map((articulo) => {
      total += parseFloat(articulo.total.toString().replace(',','.')).toFixed(2);
      totalConIva += parseFloat(
        (parseFloat(articulo.total.toString().replace(',','.')).toFixed(2) * (1 + articulo.iva.porcentaje / 100)).toFixed(2)
        
      );
    });
    setCompra({ ...compraCurrent, articulos, total:parseFloat(total), totalConIva });
  };
  async function datos() {
    await Promise.all([
      loadProveedores(),
      loadArticulos(),
      loadPagos(),
      loadSeries(),
      loadCombinados(),
    ]);
    if (idCompra) {
      await cargarCompra(idCompra);
      if (compraCurrent === null) {
        showToast("error", "El documento no existe");
        navigate("/articulos");
      }
    }
    setCargando(false);
  }
  useEffect(() => {
    return() => {
      setIsOpenDoc(false);
    };
  }, [location])
  useEffect(() => {
    datos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Combina los datos de articulosStore y combinadosStore en todosArticulosStore
    setArticulos([...articulosStore, ...combinadosStore]);
  }, [articulosStore, combinadosStore]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(compraCurrent.articulos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCompra({ ...compraCurrent, articulos: items });
  };
  const handlerEliminar = (indice) => {
    console.log(indice);
    // Crea una nueva lista sin el elemento en el índice dado
    var nuevaLista = compraCurrent.articulos.filter((_, i) => i !== indice);

    // Actualiza el estado de la lista con la nueva lista
    setCompra({
      ...compraCurrent,
      articulos: nuevaLista,
    });
  };

  function funcionTotales() {
    // eslint-disable-next-line array-callback-return
    compraCurrent?.articulos?.map((articulo) => {
      const iva = articulo.iva?.nombre;
      if (iva) {
        var precio = parseFloat(parseFloat(articulo.precioCompra?.toString().replace(',','.')) * parseFloat(articulo.cantidad?.toString().replace(',','.')))
        const descuento = precio * (parseFloat(articulo.descuento?.toString().replace(',','.') / 100));
        precio = precio - descuento;
        if (ivas[iva]) {
          ivas[iva] += (articulo.iva?.porcentaje * precio) / 100;
        } else {
          ivas[iva] = (articulo.iva?.porcentaje * precio) / 100;
        }
        if (total["Total sin iva"]) {
          total["Total sin iva"] += precio;
          total["Total con iva"] +=
            precio + (precio * articulo.iva?.porcentaje) / 100;
        } else {
          total["Total sin iva"] = precio;
          total["Total con iva"] =
            precio + (precio * articulo.iva?.porcentaje) / 100;
        }
      }
    });
    const result = [];
    for (const iva in ivas) {
      result.push({
        iva: iva,
        total: ivas[iva],
      });
    }
    const result2 = [];
    for (const iva in total) {
      result.push({
        iva: iva,
        total: total[iva],
      });
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          paddingRight: "40px",
          marginBottom: "20px",
        }}
      >
        {result.map((result) => {
          return (
            <div>
              <label htmlFor="" className="label">
                {result.iva}
              </label>
              <label htmlFor="" className="label">
                {result.total ? Number(result.total).toFixed(2).toString().replace('.', ',') : 0}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">
            {idCompra ? "Modificar " : "Añadir "}
            Factura
          </label>
          <div className="buttonMain">
            {idCompra ? (
              <>
                {enable ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <input
                    className="button main"
                    type="submit"
                    value="Editar"
                    onClick={handleSubmit}
                  />
                )}
              </>
            ) : (
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
        <div
          className={idCompra ? (enable ? "enabled" : "disabled") : "enabled"}
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Proveedor
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={compraCurrent?.proveedor?.id? proveedoresStore.find(
                      (proveedor)=>
                        proveedor.id===compraCurrent?.proveedor?.id
                        ) : null}
                    options={proveedoresStore}
                    getOptionLabel={(proveedor) => proveedor.nombre}
                    getOptionValue={(proveedor) => proveedor.id}
                    onChange={(val) =>
                      setCompra({
                        ...compraCurrent,
                        proveedor: { id: val?.id, nombre: val.nombre, direccion: val.direccion, 
                          cp: val.cp,localidad: val.localidad, provincia: val.provincia },
                        pago: val?.pago,
                      })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      if(compraCurrent?.ref||compraCurrent.ref===0){
                      setCompra({...compraCurrent, proveedor:null});
                      setProveedor({});
                      setProveedorIsOpen(true);
                      }else{
                        showToast("error","Debe introducir la referencia antes");
                      }
                    }}
                  />
                  <input
                      className="button"
                      type="submit"
                      value="Ver"
                      onClick={() => {
                        if (compraCurrent?.proveedor?.id) {
                          cargarProveedor(compraCurrent.proveedor?.id);
                          setProveedorIsOpen(true);
                        }
                      }}
                    />
                </div>
              </div>
              <div className="grupo">
                  <label htmlFor="" className="label">
                    Forma de pago
                  </label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="select"
                      isSearchable={true}
                      value={compraCurrent?.pago?.id? pagosStore.find((pago) => pago.id === compraCurrent?.pago?.id) : null}
                      options={pagosStore}
                      getOptionLabel={(pago) => pago.nombre}
                      getOptionValue={(pago) => pago.id}
                      onChange={(val) =>
                        setCompra({ ...compraCurrent, pago: val })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={() => {
                        setCompra({ ...compraCurrent, pago: {} });
                        setPago({});
                        setIsOpenPago(true);
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Ver"
                      onClick={() => {
                        cargarPago(compraCurrent.pago?.id);
                        setIsOpenPago(true);
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={() => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar la forma de pago?"
                        );
                        if (confirmDelete) {
                          delDato("pagos", compraCurrent.pago.id);
                          setCompra({ ...compraCurrent, pago: {} });
                          loadPagos();
                        }
                      }}
                    />
                  </div>
                </div>
            </div>

            <div className="columna">
              <div className="grupo">
                <label className="label">Numero Factura</label>
                <input
                  disabled
                  className="text"
                  type="text"
                  placeholder="Numero Factura"
                  value={compraCurrent?.numero?compraCurrent?.numero:""}
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label className="label">Referencia</label>
                <input
                  className="text"
                  type="text"
                  placeholder="Referencia"
                  name="ref"
                  id="ref"
                  value={compraCurrent?.ref?compraCurrent?.ref:0}
                  onChange={(ref) =>
                    setCompra({ ...compraCurrent, ref: ref.target.value })
                  }
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Fecha
                </label>
                <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Fecha factura"
                  selected={
                    compraCurrent?.fecha
                      ? typeof compraCurrent.fecha === "string" ||
                        compraCurrent.fecha instanceof Date
                        ? compraCurrent.fecha
                        : compraCurrent.fecha.toDate()
                      : null
                  }
                  onChange={(fecha) => setCompra({ ...compraCurrent, fecha })}
                />
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna segunda">
            <DragDropContext onDragEnd={handleDragEnd}>
              <table className="table">
                <thead className="thead">
                  <tr className="fila">
                    <th className="columna1">Producto</th>
                    <th className="columna2">Cantidad</th>
                    <th className="columna2">Precio</th>
                    <th className="columna2">Descuento</th>
                    <th className="columna2">Subtotal</th>
                    <th style={{ marginRight: "20px" }}></th>
                  </tr>
                </thead>
                <Droppable droppableId="filas">
                {(provided) => (
                          <tbody className="tbody"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                  {compraCurrent?.articulos?.map((articulo, i) => {
                    return (
                      <Draggable
                      key={articulo.id&i}
                      draggableId={articulo.id}
                      index={i}
                    >
                      {(provided) => (
                        <tr className="fila2"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <td className="columna1">
                            <label
                              className="label"
                              style={{
                                whiteSpace: "pre-line",
                                wordWrap: "break-word",
                              }}
                            >
                              {articulo.nombre}
                            </label>
                          </td>
                          <td className="columna2">
                            <input
                              key={i}
                              onChange={(event) =>
                                handleChange(event, i, "cantidad")
                              }
                              type="text"
                              className="text2"
                              value={articulo.cantidad
                                ? articulo.cantidad.toString().replace('.', ',')
                                : (articulo.cantidad = 0)}
                            />
                          </td>
                          <td className="columna2">
                            <input
                              key={i}
                              onChange={(event) =>
                                handleChange(event, i, "precioCompra")
                              }
                              className="text2"
                              type="text"
                              value={articulo.precioCompra
                                ? articulo.precioCompra.toString().replace('.', ',')
                                : (articulo.precioCompra = 0)}
                            />
                          </td>
                          <td className="columna2">
                            <input
                              key={i}
                              onChange={(event) =>
                                handleChange(event, i, "descuento")
                              }
                              className="text2"
                              type="text"
                              value={articulo.descuento
                                ? articulo.descuento.toString().replace('.', ',')
                                : (articulo.descuento = 0)}
                            />
                          </td>
                          <td className="columna2"
                          style={{ textAlign: "end" }}>
                            <label htmlFor="" className="label">
                              {articulo?.total ? articulo?.total?.toFixed(2).toString().replace('.', ',') : 0}
                            </label>
                          </td>
                          <td
                            onClick={() => {
                              handlerEliminar(0, i);
                            }}
                          >
                            <AiOutlineCloseCircle
                              style={{
                                marginRight: "20px",
                              }}
                              size={"1.5em"}
  color="#CB950E"
                            />
                          </td>
                        </tr>
                     )}
                     </Draggable>
                    );
                  })}
                  <tr className="fila">
                    <td className="columna1">
                      <Select
                        className="select"
                        classNamePrefix="selectClass"
                        isSearchable={true}
                        options={articulos}
                        getOptionLabel={(tipo) => tipo.nombre}
                        getOptionValue={(tipo) => tipo.id}
                        onChange={(val) => {
                          if (val.iva?.nombre) {
                            var articulo = val;
                            if (articulo.articulosSelected) {
                              // eslint-disable-next-line array-callback-return
                              articulo.articulosSelected.map((arti) => {
                                const nombre = `${articulo.nombre} \n \u00A0\u00A0\u00A0\u00A0 ${arti.cantidad} ${arti.nombre}`;
                                articulo = { ...articulo, nombre };
                              });
                            }
                            articulo = {
                              ...articulo,
                              cantidad: 0,
                              descuento: compraCurrent?.proveedor?.descuento
                                ? compraCurrent.proveedor.descuento
                                : 0,
                            };
                            if (compraCurrent?.articulos)
                              var artiSelect = compraCurrent.articulos;
                            else artiSelect = [];

                            artiSelect.push(articulo);
                            setCompra({
                              ...compraCurrent,
                              articulos: artiSelect,
                            });
                          } else {
                            showToast(
                              "error",
                              "Articulo con datos erroneos o incompleto"
                            );
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="iva">{funcionTotales()}</tr>
                </tbody>
              )}
              </Droppable>
            </table>
            </DragDropContext>
            </div>
          </div>
        </div>
      </form>

      <Modal
        isOpen={proveedorIsOpen}
        onRequestClose={closeProveedorModal}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
        style={{
          marginTop: "25px",
          height: "30px",
          width: "30px",
          position:"absolute",
          right:"20px"
        }}
        onClick={closeProveedorModal}
      />
        <FormProveedores
        cerrar={closeProveedorModal}
        idProveedorSelected={compraCurrent?.proveedor?.id}
        llamadoDesde={"facturaCompra"}
        />
      </Modal>

      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
      <Modal
          isOpen={modalIsOpenPago}
          onRequestClose={closeModalPago}
          style={{
            overlay: {
              border: "8px",
            },
            content: {
              border: "0px",
              background: 0,
            },
          }}
        >
          <AiOutlineCloseCircle
        style={{
          marginTop: "25px",
          height: "30px",
          width: "30px",
          position:"absolute",
          right:"20px"
        }}
        onClick={closeModalPago}
      />
          <Pago cerrar={closeModalPago} llamadoDesde={"compra"} />
        </Modal>
    </div>
  );
}