import React, { useEffect, useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";

export default function ListadoArticuloCompra() {
  const { loadArticulosCompra, articulosCompraStore } = useStore();
  const data = useMemo(() => articulosCompraStore, [articulosCompraStore]);

  const columns = useMemo(
    () => [
      { Header: "Id", accessor: "id" },
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Familia", accessor: "familia.nombre" },
      { Header: "Modelo", accessor: "modelo.nombre" },
      { Header: "Cantidad", accessor: "unidades" },
      { Header: "Precio unidad", accessor: d => { 
        return `${d.precio.toString().replace('.', ',')} €`;
      } },
      { Header: "Precio pack", accessor: d => { 
        return `${(d.precio*d.unidades).toFixed(2).toString().replace('.', ',')} €`;
      } },
    ],
    []
  );
  useEffect(() => {
    loadArticulosCompra();
  }, []);
  return <Table columns={columns} data={data} />;
}
