import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useStore } from "../../store/appStore";
import { showToast } from "../../utils/utils";

export default function FormTrabajadores(porps) {
  const {
    trabajadoresCurrent,
    loadTrabajadores,
    setUnsavedChanges,
    setTrabajadores,
    cargarTrabajador,
    loadTiposIdentificaciones,
    tiposIdentificacionesStore,
  } = useStore();
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();
  const { insert, delDato, updateDato, uploadFile} = useAuth();
  const { idTrabajador } = useParams();
  const [cargando, setCargando] = useState(true);

  const handleCheckboxChange = () => {
    trabajadoresCurrent.acceso=!trabajadoresCurrent.acceso
    trabajadoresCurrent.tieneAcceso=trabajadoresCurrent.acceso?"Tiene Acceso":"No tiene Acceso"
    setTrabajadores({
      ...trabajadoresCurrent,
      acceso: trabajadoresCurrent.acceso,
      tieneAcceso: trabajadoresCurrent.tieneAcceso
    })
  }

  const handleChange = ({ target: { value, name } }) => {
    setTrabajadores({ ...trabajadoresCurrent, [name]: value});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (trabajadoresCurrent.nombre) {
        try {
          setUnsavedChanges(true)
          const id = await insert("trabajadores", trabajadoresCurrent);
          navigate(`/trabajadores/listado/${id}`);
        } catch (err) {}
        }else{
          showToast("error", "Debes rellenar al menos el nombre trabajador");
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al trabajador?"
          );
        if (confirmDelete) {
          setUnsavedChanges(true)
          await delDato("trabajadores",idTrabajador);
          navigate("/trabajadores/listado");
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al trabajador?"
        );
        if(confirmUpdate && trabajadoresCurrent.nombre) {
          setUnsavedChanges(false)
          try {
            await updateDato("trabajadores", idTrabajador, trabajadoresCurrent);
            navigate("/trabajadores/listado");
          } catch (err) {}
        }else if(confirmUpdate){
          showToast("error", "Debes rellenar al menos el nombre del trabajador");
        }
        break;
      case "Editar":
        setUnsavedChanges(true)
        setEnable(true);
        break;
      default:
        break;
    }
  };
  async function datos() {
    await Promise.all([
      loadTrabajadores(),
      loadTiposIdentificaciones(),
    ]);
    if (idTrabajador) {
      await cargarTrabajador(idTrabajador);
      if (trabajadoresCurrent === null) {
        showToast("error", "El trabajador no existe");
        navigate("/trabajadores");
      }
    }
    setCargando(false);
  }
  useEffect(() => {
    datos();
  }, []);

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">{idTrabajador?"Modificar ":"Añadir "}Trabajador</label>
          <div className="buttonMain">
            {idTrabajador || trabajadoresCurrent.id ? (
              enable ? (
                <>
                  <input
                    className="button main"
                    type="submit"
                    value="Modificar"
                    onClick={handleSubmit}
                  />
                  <input
                    className="button main"
                    type="submit"
                    value="Eliminar"
                    onClick={handleSubmit}
                  />
                </>
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Editar"
                  onClick={handleSubmit}
                />
              )
            ) : (
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
        <div
          className={
            idTrabajador || trabajadoresCurrent.id
              ? enable
                ? "enabled"
                : "disabled"
              : "enabled"
          }
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Nombre
                </label>
                <input
                  className="text"
                  type="text"
                  placeholder="Nombre"
                  name="nombre"
                  id="nombre"
                  value={
                    trabajadoresCurrent.nombre ? trabajadoresCurrent.nombre : ""
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="grupo">
                <label className="label">Tipo de Identificación</label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={trabajadoresCurrent?.tiposIdentificacion?trabajadoresCurrent?.tiposIdentificacion:""}
                    options={tiposIdentificacionesStore}
                    getOptionLabel={(tipo) => tipo.nombre}
                    getOptionValue={(tipo) => tipo.id}
                    onChange={(val) =>
                      setTrabajadores({
                        ...trabajadoresCurrent,
                        tiposIdentificacion: val,
                      })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      const nombre = prompt("Inserte el nuevo tipo de cliente");
                      if (nombre !== null && nombre !== "") {
                        const id = await insert("tiposIdentificaciones", {
                          nombre,
                        });
                        setTrabajadores({
                          ...trabajadoresCurrent,
                          tiposIdentificacion: { id, nombre },
                        });
                        loadTiposIdentificaciones();
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el tipo de identificacion?"
                      );
                      if (confirmDelete) {
                        await delDato(
                          "tiposIdentificaciones",
                          trabajadoresCurrent.tiposIdentificacion.id
                        );
                      }
                      loadTiposIdentificaciones();
                      setTrabajadores({
                        ...trabajadoresCurrent,
                        tiposIdentificacion: null,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label className="label">Identificación Fiscal</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Identificación fiscal"
                  name="cifDni"
                  id="cifDni"
                  onChange={handleChange}
                  value={
                    trabajadoresCurrent?.cifDni
                      ? trabajadoresCurrent?.cifDni
                      : ""
                  }
                />
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label className="label">Telefono</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Telefono"
                  name="telefono"
                  id="telefono"
                  onChange={handleChange}
                  value={
                    trabajadoresCurrent?.telefono
                      ? trabajadoresCurrent?.telefono
                      : ""
                  }
                />
              </div>
              <div className="grupo">
                <label className="label">E-Mail</label>
                <input
                  type="text"
                  className="text"
                  placeholder="E-mail"
                  name="mail"
                  id="mail"
                  onChange={handleChange}
                  value={
                    trabajadoresCurrent?.mail
                      ? trabajadoresCurrent?.mail
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label className="label">Dirección</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Dirección"
                  name="direccion"
                  id="direccion"
                  onChange={handleChange}
                  value={
                    trabajadoresCurrent?.direccion
                      ? trabajadoresCurrent?.direccion
                      : ""
                  }
                />
              </div>
              <div className="grupo">
                <label className="label">Código Postal</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Código Postal"
                  name="cp"
                  id="cp"
                  onChange={handleChange}
                  value={trabajadoresCurrent?.cp ? trabajadoresCurrent?.cp : ""}
                />
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label className="label">Localidad</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Localidad"
                  name="localidad"
                  id="localidad"
                  onChange={handleChange}
                  value={
                    trabajadoresCurrent?.localidad
                      ? trabajadoresCurrent?.localidad
                      : ""
                  }
                />
              </div>
              <div className="grupo">
                <label className="label">Provincia</label>
                <input
                  type="text"
                  className="text"
                  placeholder="Provincia"
                  name="provincia"
                  id="provincia"
                  onChange={handleChange}
                  value={
                    trabajadoresCurrent?.provincia
                      ? trabajadoresCurrent?.provincia
                      : ""
                  }
                />
              </div>
              <div className="grupo">
                <label className="label">País</label>
                <input
                  type="text"
                  className="text"
                  placeholder="País"
                  name="pais"
                  id="pais"
                  onChange={handleChange}
                  value={
                    trabajadoresCurrent?.pais ? trabajadoresCurrent?.pais : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label className="label">Acceso</label>
                <div className="text switchText">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={trabajadoresCurrent.acceso}
                      defaultChecked={trabajadoresCurrent.acceso}
                      onChange={handleCheckboxChange}
                    />
                    <div className="slider round"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}