import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import { CardContactos } from "../contactos";
import Select from "react-select";
import Cargando from "../cargando/cargando";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Modal from "react-modal";
import FormProveedores from "../proveedores/FormProveedores";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
export default function FormAgente(props) {
  Modal.setAppElement("#root");
  const {
    clienteCurrent,
    presupuestoCurrent,
    loadTiposAgentes,
    loadProveedores,
    loadTiposComisiones,
    loadAgentes,
    cargarAgente,
    setCliente,
    agenteCurrent,
    setAgente,
    setPresupuesto,
    tiposComisionesStore,
    tiposComercialesStore,
    proveedoresStore,
    setProveedor,
    cargarProveedor,
    setUnsavedChanges
  } = useStore();
  const { cerrar, idAgenteSelected ,llamadoDesde } = props;
  const { insert, delDato, updateDato } = useAuth();
  const [cargando, setCargando] = useState(true);
  let { idAgente } = useParams();
  const navigate = useNavigate();
  const [enableAgente, setEnableAgente] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  async function datos() {
    await Promise.all([
      loadTiposAgentes(),
      loadProveedores(),
      loadTiposComisiones(),
    ]);
    if (idAgente) {
      await cargarAgente(idAgente);
      if (agenteCurrent === null) {
        showToast("error", "El agente no existe");
        navigate("/agentes");
      }
    }
    setCargando(false);
  }

  useEffect(() => {
    datos();
  }, []);
  const handleChange = ({ target: { value, name } }) => {
    if (
      name === "telefono"
    ) {
      value = value.replace(/[^\d,.]/g, "");
    }
    setAgente({ ...agenteCurrent, [name]: value });
  };

  const handleSubmit = async (e) => {
    if (!idAgente) idAgente = agenteCurrent.id;
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (
          agenteCurrent.nombre &&
          agenteCurrent.nombre !== "" &&
          agenteCurrent.nombre !== null
        ) {
          try {
            agenteCurrent.comision= parseFloat(agenteCurrent.comision?.toString().replace(',', '.'));
            setUnsavedChanges(false)
            const id = await insert("comerciales", agenteCurrent);
            if (presupuestoCurrent.pax) {
              setPresupuesto({
                ...presupuestoCurrent,
                comercial: {...agenteCurrent, id},
              });
              loadAgentes();
              cerrar();
            }
            if (clienteCurrent.nombre) {
              setCliente({
                ...clienteCurrent,
                comercial: {...agenteCurrent, id},
              });
              loadAgentes();
              cerrar();
            }
            if (!clienteCurrent.nombre && !presupuestoCurrent.pax)
              navigate(`/agentes/listado/${id}`);
          } catch (err) {}
        } else {
          showToast("error", "Debes al menos rellenar el nombre del comercial");
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al agente?"
        );
        if (confirmDelete) {
          try {
            setUnsavedChanges(false)
            switch (llamadoDesde) {
              case "clientes":
                await delDato("comerciales", idAgenteSelected);
                setCliente({ ...clienteCurrent, comercial: null });
                loadAgentes();
                cerrar();
                break;
                case "presupuestos":
                await delDato("comerciales", idAgenteSelected);
                setPresupuesto({ ...presupuestoCurrent, comercial: null });
                loadAgentes();
                cerrar();
                break;
              default:
                await delDato("comerciales", idAgente);
                navigate(`/agentes/listado`);
                break;
            }
          } catch (error) {console.log(error)}
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al agente?"
        );
        if (confirmUpdate && agenteCurrent?.nombre) {
          
          try {
            agenteCurrent.comision= parseFloat(agenteCurrent.comision?.toString().replace(',', '.'));
            setUnsavedChanges(false)
            switch (llamadoDesde) {
              case "clientes":
                console.log(clienteCurrent.comercial);
                await updateDato(
                  "comerciales",
                  clienteCurrent.comercial.id,
                  agenteCurrent
                );
                loadAgentes();
                setCliente({
                  ...clienteCurrent,
                  comercial: {
                    agenteCurrent,
                    id: clienteCurrent.comercial.id,
                  },
                });
                cerrar();
                break;
              case "presupuestos":
                console.log(presupuestoCurrent.comercial);
                await updateDato(
                  "comerciales",
                  presupuestoCurrent.comercial.id,
                  agenteCurrent
                );
                loadAgentes();
                setPresupuesto({
                  ...presupuestoCurrent,
                  comercial: {
                    agenteCurrent,
                    id: presupuestoCurrent.comercial.id,
                  },
                });
                cerrar();
                break;
              default:
                if (!agenteCurrent.comisiona) {
                  agenteCurrent.comision = 0;
                  agenteCurrent.tipoComision = "";
                }
                console.log("agente", idAgente, agenteCurrent.id);
    
                await updateDato("comerciales", idAgente, agenteCurrent);
                navigate("/agentes/listado");
            }
            
          } catch (err) {
            console.log(err);
          }
        }else if(confirmUpdate){
          showToast("error", "Debes al menos rellenar el nombre del comercial");
        }
        break;
      case "Editar":
        setUnsavedChanges(true)
        setEnableAgente(true);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="superior">
            <label className="tituloVentana">{idAgente?"Modificar Comercial":"Añadir Comercial"}</label>
            <div className="buttonMain">
              {idAgente || idAgenteSelected ? (
                enableAgente ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <input
                    className="button main"
                    type="submit"
                    value="Editar"
                    onClick={handleSubmit}
                  />
                )
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Añadir"
                  onClick={handleSubmit}
                  Tope
                />
              )}
            </div>
          </div>
          <div
            className={
              idAgente || agenteCurrent.id
                ? enableAgente
                  ? "enabled"
                  : "disabled"
                : "enabled"
            }
          >
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label className="label">Tipo de Comercial</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={agenteCurrent.tipo?agenteCurrent.tipo:""}
                      options={tiposComercialesStore}
                      getOptionLabel={(tipo) => tipo.nombre}
                      getOptionValue={(tipo) => tipo.id}
                      onChange={(val) =>
                        setAgente({ ...agenteCurrent, tipo: val })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={async () => {
                        const nombre = prompt(
                          "Inserte el nuevo tipo de comercial"
                        );
                        if (nombre !== "" && nombre !== null) {
                          const id = await insert("tiposComerciales", {
                            nombre,
                          });
                          loadTiposAgentes();
                          setAgente({ ...agenteCurrent, tipo: { id, nombre } });
                        }
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={async () => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar el tipo de agente?"
                        );
                        if (confirmDelete) {
                          await delDato(
                            "tiposComerciales",
                            agenteCurrent.tipo.id
                          );
                        }
                        loadTiposAgentes();
                        setAgente({ ...agenteCurrent, tipo: null });
                      }}
                    />
                  </div>
                </div>
                <div className="grupo">
                  <label className="label">Nombre</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="  Nombre"
                    name="nombre"
                    id="nombre"
                    onChange={handleChange}
                    value={agenteCurrent.nombre?agenteCurrent.nombre:""}
                  />
                </div>

                <div className="grupo">
                  <label className="label">telefono</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="  Telefono"
                    name="telefono"
                    id="telefono"
                    onChange={handleChange}
                    value={agenteCurrent.telefono?agenteCurrent.telefono:""}
                  />
                </div>
                <div className="grupo">
                  <label className="label">E-Mail</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="  E-Mail"
                    name="mail"
                    id="mail"
                    onChange={handleChange}
                    value={agenteCurrent.mail?agenteCurrent.mail:""}
                  />
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label className="label">Proveedor Asociado</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={proveedoresStore.find(
                        (proveedor) =>
                          proveedor.id === agenteCurrent?.proveedor?.id
                      )}
                      options={proveedoresStore}
                      getOptionLabel={(proveedor) => proveedor.nombre}
                      getOptionValue={(proveedor) => proveedor.id}
                      onChange={(val) =>
                        setAgente({
                          ...agenteCurrent,
                          proveedor: { id: val.id, nombre: val.nombre },
                        })
                      }
                    />
                  </div>

                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      if (agenteCurrent.nombre) {
                        setAgente({ ...agenteCurrent, proveedor: null });
                        setProveedor(null);
                        setIsOpen(true);
                      } else {
                        showToast(
                          "error",
                          "Al menos debes de rellenar el nombre del agente"
                        );
                      }
                    }}
                  />

                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      console.log(agenteCurrent);
                      if (agenteCurrent.proveedor?.id) {
                        cargarProveedor(agenteCurrent.proveedor?.id);
                        setIsOpen(true);
                      }
                    }}
                  />
                </div>
                <div className="grupo">
                  <label className="label">Comisiona</label>
                  <div className="text switchText">
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={agenteCurrent.comisiona}
                        checked={agenteCurrent.comisiona}
                        onClick={() => {
                          agenteCurrent.comisiona=!agenteCurrent.comisiona
                          setAgente({
                            ...agenteCurrent,
                            comisiona: agenteCurrent.comisiona,
                          });
                          console.log(agenteCurrent);
                        }}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                </div>
                <div
                  className={
                    agenteCurrent.comisiona ? "grupo visible" : "grupo hidden"
                  }
                >
                  <label className="label">Tipo de Comisión</label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={agenteCurrent.tipoComision?agenteCurrent.tipoComision:""}
                      options={tiposComisionesStore}
                      getOptionLabel={(tipo) => tipo.nombre}
                      getOptionValue={(tipo) => tipo.id}
                      onChange={(val) =>
                        setAgente({ ...agenteCurrent, tipoComision: val })
                      }
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Añadir"
                      onClick={async () => {
                        const nombre = prompt(
                          "Inserte el nuevo tipo de comercial"
                        );
                        if (nombre !== "" && nombre !== null) {
                          const id = await insert("tiposComiciones", {
                            nombre,
                          });
                          loadTiposComisiones();
                          setAgente({
                            ...agenteCurrent,
                            tipoComision: { id, nombre },
                          });
                        }
                      }}
                    />
                    <input
                      className="button"
                      type="submit"
                      value="Eliminar"
                      onClick={async () => {
                        const confirmDelete = window.confirm(
                          "¿Seguro que desea eliminar el tipo de comision?"
                        );
                        if (confirmDelete) {
                          await delDato(
                            "tiposComiciones",
                            agenteCurrent.tipoComision.id
                          );
                        }
                        loadTiposComisiones();
                        setAgente({ ...agenteCurrent, tipoComision: null });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={
                    agenteCurrent.comisiona ? "grupo visible" : "grupo hidden"
                  }
                >
                  <label className="label">Comisión</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="  Comisión"
                    name="comision"
                    id="comision"
                    onChange={handleChange}
                    value={agenteCurrent?.comision
                      ? agenteCurrent?.comision.toString().replace('.', ',')
                      : (agenteCurrent.comision = 0)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              border: "8px",
            },
            content: {
              border: "0px",
              background: 0,
            },
          }}
        ><AiOutlineCloseCircle
        size={"1.5em"}
  color="#CB950E"
        style={{
          marginTop: "25px",
          position:"absolute",
          right:"20px"
        }}
        onClick={closeModal}
      />
          <FormProveedores
            cerrar={closeModal}
            idProveedorSelected={agenteCurrent.proveedor?.id}
            llamadoDesde={"agentes"}
          />
        </Modal>
        <Modal isOpen={cargando}>
          <Cargando />
        </Modal>
      </div>
      <Tabs className="react-tabs">
        <TabList className="react-tabs__tab-list">
          <Tab className="react-tabs__tab">Contactos</Tab>

          <Tab className="react-tabs__tab">Presupuestos</Tab>
          <Tab className="react-tabs__tab">Facturas</Tab>
          <Tab className="react-tabs__tab">Ficheros</Tab>
        </TabList>
        <TabPanel className="react-tabs__tab-panel">
          <CardContactos
            llamado={"agente"}
            contactos={agenteCurrent.contactos}
          />
        </TabPanel>
        <TabPanel className="react-tabs__tab-panel">
          <h1>Presupuesto</h1>
        </TabPanel>
        <TabPanel className="react-tabs__tab-panel">
          <h1>Factura</h1>
        </TabPanel>
        <TabPanel className="react-tabs__tab-panel">
          <h1>Ficheros</h1>
        </TabPanel>
      </Tabs>
    </>
  );
}
