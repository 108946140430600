import React, { useEffect, useMemo } from "react";
import { Table } from "../table";
import { useStore } from "../../store/appStore";
import moment from "moment";




export default function ListadoFacturaVenta() {
  const { loadVentas, ventasStore } = useStore();
  const data = useMemo(() => ventasStore, [ventasStore]);

  const columns = useMemo(
    () => [
      { Header: "Id", accessor: "id" },
      { Header: "Serie", accessor: "serie.nombre" },
      { Header: "Numero", accessor: "numero" },
      { Header: "Referencia", accessor: "ref" },
      {
        Header: "Fecha", accessor: d => {
          return moment(d.fecha?.toDate())
            .local()
            .format("DD-MM-YYYY");
        }
      },
      { Header: "Nombre", accessor: "cliente.nombre" },
      { Header: "Total", accessor: d =>{
        return `${parseFloat(d.total).toFixed(2).toString().replace('.', ',')} €`
      }
     },
      { Header: "Total con IVA", accessor: d =>{
        return `${parseFloat(d.totalConIva).toFixed(2).toString().replace('.', ',')} €`
      } },
    ],
    []
  );
  useEffect(() => {
    loadVentas();
  }, []);
  return <Table columns={columns} data={data} />;
}