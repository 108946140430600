import "../css/topbar.scss";
import { useAuth } from "../context/authContext";
import { useEffect, useState } from "react";
import { useStore } from "../store/appStore";
import Modal from "react-modal";
import {
  AiOutlineCloseCircle,
  AiOutlineSave,
  AiOutlineSetting,
} from "react-icons/ai";
import { GiExitDoor } from "react-icons/gi";
import { showToast } from "../utils/utils";
import { NavLink } from "react-router-dom";
export const Topbar = (props) => {
  const { limpiarCurrent, unsavedChanges, setUnsavedChanges } = useStore();
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const [documentoPng, setDocumentoPng] = useState(null);
  const { user, updateCurrentProfile, logout, uploadFile } = useAuth();
  const [nombre, setNombre] = useState(user.displayName);
  const handleLogout = async () => {
    await logout();
  };
  const confirmNavigation = (e) => {
    if (unsavedChanges) {
      if (
        !window.confirm(
          "¿Estás seguro de que deseas continuar sin guardar los cambios?"
        )
      ) {
        e.preventDefault(); // Cancela la navegación si el usuario no confirma
      } else {
        limpiarCurrent();
        if (e.target.id === "nuevo") setUnsavedChanges(true);
      }
    } else {
      limpiarCurrent();
      if (e.target.id === "nuevo") setUnsavedChanges(true);
    }
  };
  const save = async () => {
    await uploadFile(documentoPng, `fotos/${user.uid}/perfil.png`).then(
      async (url) => {
        await updateCurrentProfile(nombre, url);
        closeProfile();
      }
    );
  };

  function closeProfile() {
    setVisible(false);
  }

  useEffect(() => {}, [unsavedChanges]);

  return (
    <div className="topbar shadow-md ">
      <div className="logo-user">
        <div className="logo" />
        <div className="user" onClick={() => setVisibleMenu(!visibleMenu)}>
          <img src={user.photoURL} alt="Logo" className="foto" />
          <span>{user.displayName}</span>
          {visibleMenu && (
            <div className="dropdown-menu">
              <ul>
                <li
                  onClick={() => {
                    setVisible(true);
                    setVisibleMenu(false);
                  }}
                >
                  <div className="item">
                    <div className="itemoicon">
                      <AiOutlineSetting className="icons" />
                    </div>
                    <div className="itemtext">Opciones</div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="itemoicon">
                      <GiExitDoor className="icons" onClick={handleLogout} />
                    </div>
                    <div className="itemtext">Logout</div>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={visible}
        onRequestClose={closeProfile}
        style={{
          overlay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "8px",
          },
          content: {
            border: "0px",
            background: "0",
            maxWidth: "100%",
          },
        }}
      >
        <AiOutlineCloseCircle
          size={"1.5em"}
          color="#CB950E"
          style={{
            marginTop: "25px",
            position: "absolute",
            right: "20px",
          }}
          onClick={closeProfile}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Centrar tanto horizontal como verticalmente
            justifyContent: "center", // Centrar tanto horizontal como verticalmente
            height: "100%",
            padding: "20px",
            border: "1px",
          }}
          className="datos columna"
        >
          <div
            className="grupo"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Modificar el perfil de usuario
          </div>
          <div className="grupo" style={{ width: "50%", display: "flex" }}>
            <label className="label" style={{ flex: 2 }}>
              Nombre del usuario
            </label>
            <input
              type="text"
              style={{ flex: 3, marginRight: "10px" }}
              className="text"
              placeholder="Nombre del Documento"
              name="nombre"
              id="nombre"
              onChange={(val) => setNombre(val.target.value)}
              value={nombre}
            />
          </div>
          <div
            className="grupo"
            style={{ width: "50%", display: "flex", alignItems: "center" }}
          >
            <input
              type="file"
              style={{ flex: 7 }}
              accept=".png"
              name="documentos"
              id="documentos"
              onChange={(e) => {
                if (
                  e.target.files[0].name.substring(
                    e.target.files[0].name.length - 4
                  ) === ".png"
                ) {
                  setDocumentoPng(e.target.files[0]);
                  console.log(e.target.files[0]);
                } else {
                  showToast("error", "Solo se pueden subir documentos png");
                  setDocumentoPng(null);
                }
              }}
            />
            <img
              src={
                documentoPng ? URL.createObjectURL(documentoPng) : user.photoURL
              }
              alt="Logo"
              style={{ maxWidth: "75px", marginLeft: "20px" }}
              className="foto"
            />
          </div>
          <button
            className="grupo"
            style={{ display: "flex" }}
            onClick={() => save()}
          >
            <label style={{ color: "#CB950E", marginRight: "20px" }}>
              Guardar
            </label>
            <AiOutlineSave size={"1.5em"} color="#CB950E" />
          </button>
        </div>
      </Modal>
      <div className="menu">
        <div className="allSeccion">
          {props.data !== "documentos" ? (
            <div className="menuSeccion">
              <NavLink
                className="itemSeccion"
                to={props.data + "/nuevo"}
                onClick={confirmNavigation}
                id="nuevo"
              >
                Nuevo
              </NavLink>
              <NavLink
                className="itemSeccion"
                to={props.data + "/listado"}
                onClick={confirmNavigation}
              >
                Listado
              </NavLink>
              {props.data==='eventos' ?
              <NavLink
                className="itemSeccion"
                to={props.data + "/calendario"}
                onClick={confirmNavigation}
              >
                Agenda
              </NavLink> : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
