import React, { useEffect} from 'react';
import { useStore } from "../../store/appStore";
import moment from 'moment';

export const CardCompra = (props) => {
  const {
    compraCurrent,
    setCompra,
    clienteCurrent,
    comprasStore,
    loadCompras,
  } = useStore();

  const cargarDatos = () => {
    setCompra(comprasStore)
  }

  useEffect(() => {
    loadCompras();
    cargarDatos();
  },[])

  const getClientesConMismoId = () =>{
    for(let i=0;i<=compraCurrent.length;i++) {
      if(props?.id===compraCurrent[i]?.proveedor?.id){
        return compraCurrent[i]
      }
    }
  }

  const datos = getClientesConMismoId();
  console.log(datos)
  const fecha = moment(datos?.fecha?.toDate()).format('DD/MM/YYYY');

  return (
    <div className="cardPresupuestos">
      <div className="divCardPresupuestos">
        <div className="">
          {datos && (
          <label>Nombre: {datos.proveedor.nombre}, fecha: {fecha}, ID: {datos.proveedor.id}</label>
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
};
