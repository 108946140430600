import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import Select from "react-select";
import { useStore } from "../../store/appStore";
import { AiOutlineCloseCircle } from "react-icons/ai";

import Modal from "react-modal";

import Cargando from "../cargando/cargando";
import Iva from "../iva/iva";

export default function ModificarCombinado() {
  const {
    combinadoCurrent,
    setCombinado,
    cargarCombinado,
    loadArticulos,
    articulosStore,
    loadTiposCombinados,
    tiposCombinadosStore,
    tiposIvasStore,
    loadTiposIvas,
    cargarIva,
    setIva,
    loadCategorias,
    categoriasStore,
    setUnsavedChanges
  } = useStore();

  const { delDato, updateDato, insert } = useAuth();
  const [articulo, setArticulo] = useState();
  const [modalIsOpenIva, setIsOpenIva] = useState(false);
  function closeModalIva() {
    setIsOpenIva(false);
  }
  const [enable, setEnable] = useState(false);
  const [cargando, setCargando] = useState(true);
  const { idCombinado } = useParams();
  const navigate = useNavigate();
  async function datos() {
    await Promise.all([
      loadArticulos(),
      loadTiposCombinados(),
      loadTiposIvas(),
      loadCategorias(),
    ]);
    if (idCombinado) {
      await cargarCombinado(idCombinado);
      if (combinadoCurrent === null) {
        showToast("error", "El articulo no existe");
        navigate("/articulos");
      }
    }
    setCargando(false);
  }
  useEffect(() => {
    datos();
  }, []);
  const handlerEliminar = (indice) => {
    // Crea una nueva lista sin el elemento en el índice dado
    var nuevaLista = combinadoCurrent.articulosSelected.filter(
      (_, i) => i !== indice
    );

    // Actualiza el estado de la lista con la nueva lista
    setCombinado({
      ...combinadoCurrent,
      articulosSelected: nuevaLista,
    });
  };
  const handleChange = (e, i, campo) => {
    const value = e.target.value.replace(/[^\d,]/g, "");
    const array = combinadoCurrent.articulosSelected;
    if (campo === "cantidad") {
      array[i].cantidad = value;
    } else {
      array[i].precioVenta = value;
    }
    setCombinado({ ...combinadoCurrent, articulosSelected: array });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        try {
          setUnsavedChanges(false)
          if (combinadoCurrent && combinadoCurrent.articulosSelected.length>0 &&combinadoCurrent.articulosSelected && combinadoCurrent.nombre) {
            const modifiedArticulos = combinadoCurrent.articulosSelected.map((articulo) => {
              const precioVenta = parseFloat(articulo.precioVenta.toString().replace(',', '.'));
              const cantidad = parseFloat(articulo.cantidad?.toString().replace(',', '.'));
              return {
                ...articulo,
                precioVenta: isNaN(precioVenta) ? 0 : precioVenta,
                cantidad: isNaN(cantidad) ? 0 : cantidad,
              };
            });
            const modifiedData = {
              ...combinadoCurrent,
              articulosSelected: modifiedArticulos,
            };
            const id = await insert("articulosCombinados", modifiedData);
            navigate(`/combinados/listado/${id}`);
          } else {
            showToast(
              "error",
              "Almenos debes de ponerle nombre y agregar un articulo"
            );
          }
        } catch (err) {}
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al articulo?"
        );
        if (confirmDelete) {
          await delDato("articulosCombinados", idCombinado);
          setUnsavedChanges(false)
          navigate(`/combinados/listado/`);
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al articulo combinado?"
        );
        if (confirmUpdate && combinadoCurrent && combinadoCurrent.articulosSelected && combinadoCurrent.articulosSelected.length>0 && combinadoCurrent.nombre) {
          try {
            setUnsavedChanges(false)
            combinadoCurrent.precioVenta = parseFloat(combinadoCurrent.precioVenta.toString().replace(',', '.'));
            const modifiedArticulos = combinadoCurrent.articulosSelected.map((articulo) => {
              const precioVenta = parseFloat(articulo.precioVenta.toString().replace(',', '.'));
              const cantidad = parseFloat(articulo.cantidad?.toString().replace(',', '.'));
              return {
                ...articulo,
                precioVenta: isNaN(precioVenta) ? 0 : precioVenta,
                cantidad: isNaN(cantidad) ? 0 : cantidad,
              };
            });
            const modifiedData = {
              ...combinadoCurrent,
              articulosSelected: modifiedArticulos,
            };
            await updateDato(
              "articulosCombinados",
              idCombinado,
              modifiedData
            );
            navigate("/combinados/listado");
            setEnable(false);
          } catch (err) {
            console.log(err);
          }
        }else if(confirmUpdate){
          showToast("error","Almenos debes de ponerle nombre y agregar un articulo");
        }
        break;
      case "Editar":
        setUnsavedChanges(true)
        setEnable(true);
        break;
      case "Calcular":
        let total = 0;

        combinadoCurrent.articulosSelected.map(
          (articulo) =>
            (total +=
              parseFloat(articulo.precioVenta) * parseFloat(articulo.cantidad))
        );
        console.log(total);
        setCombinado({ ...combinadoCurrent, precioVenta: total });
        break;
      case "Agregar":
        if (articulo) {
          let articulos = combinadoCurrent?.articulosSelected;
          if (!articulos) articulos = [];
          articulos.push(articulo);
          setCombinado({ ...combinadoCurrent, articulosSelected: articulos });
        }else{showToast('error','Debe seleccionar un articulo')}
        break;
      default:
        break;
    }
  };
  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="superior">
          <label className="tituloVentana">{idCombinado?"Modificar Articulo añadido":"Añadir Articulo Combinado"}</label>
          <div className="buttonMain">
            {idCombinado || combinadoCurrent.id ? (
              enable ? (
                <>
                  <input
                    className="button main"
                    type="submit"
                    value="Modificar"
                    onClick={handleSubmit}
                  />
                  <input
                    type="submit"
                    className="button main"
                    value="Eliminar"
                    onClick={handleSubmit}
                  />
                </>
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Editar"
                  onClick={handleSubmit}
                />
              )
            ) : (
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
        <div
          className={
            idCombinado || combinadoCurrent.id
              ? enable
                ? "enabled"
                : "disabled"
              : "enabled"
          }
        >
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="text"
                  onChange={(val) =>
                    setCombinado({
                      ...combinadoCurrent,
                      nombre: val.target.value,
                    })
                  }
                  value={combinadoCurrent.nombre?combinadoCurrent.nombre:""}
                />
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Tipo
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="selectClass"
                    isSearchable={true}
                    value={combinadoCurrent.tipo?combinadoCurrent.tipo:""}
                    options={tiposCombinadosStore}
                    getOptionLabel={(tipo) => tipo.nombre}
                    getOptionValue={(tipo) => tipo.id}
                    onChange={(val) =>
                      setCombinado({ ...combinadoCurrent, tipo: val })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      const nombre = prompt("Inserte el nuevo tipo de cliente");
                      if (nombre !== "" && nombre !== null) {
                        const id = await insert("tiposCombinados", { nombre });
                        loadTiposCombinados();
                        setCombinado({
                          ...combinadoCurrent,
                          tipo: { id, nombre },
                        });
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el tipo de articulo combinado?"
                      );
                      if (confirmDelete) {
                        await delDato(
                          "tiposCombinados",
                          combinadoCurrent.tipo.id
                        );
                      }
                      loadTiposCombinados();
                      setCombinado({ ...combinadoCurrent, tipo: null });
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label htmlFor="" className="label">
                  Grupo Impresión
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={
                      combinadoCurrent.categoria ? combinadoCurrent.categoria : ""
                    }
                    options={categoriasStore}
                    getOptionLabel={(categoria) => categoria.nombre}
                    getOptionValue={(categoria) => categoria.id}
                    onChange={(val) => {
                      console.log(val);
                      setCombinado({ ...combinadoCurrent, categoria: val });
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={async () => {
                      const nombre = prompt(
                        "Inserte el nuevo grupo de impresión"
                      );
                      if (nombre !== "" && nombre !== null) {
                        const id = await insert("categorias", { nombre });
                        loadCategorias();
                        setArticulo({
                          ...combinadoCurrent,
                          categoria: { id, nombre },
                        });
                      }
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={async () => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el almacen?"
                      );
                      if (confirmDelete) {
                        await delDato("almacenes", combinadoCurrent.almacen.id);
                      }
                      setArticulo({ ...combinadoCurrent, almacen: null });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="columna">
              <div className="grupo">
                <label htmlFor="" className="label">
                  Iva
                </label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={combinadoCurrent.iva?combinadoCurrent.iva:""}
                    options={tiposIvasStore}
                    getOptionLabel={(iva) => iva.nombre}
                    getOptionValue={(iva) => iva.id}
                    onChange={(val) =>
                      setCombinado({ ...combinadoCurrent, iva: val })
                    }
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Añadir"
                    onClick={() => {
                      setCombinado({ ...combinadoCurrent, iva: {} });
                      setIva({});
                      setIsOpenIva(true);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Ver"
                    onClick={() => {
                      cargarIva(combinadoCurrent.iva?.id);
                      setIsOpenIva(true);
                    }}
                  />
                  <input
                    className="button"
                    type="submit"
                    value="Eliminar"
                    onClick={() => {
                      const confirmDelete = window.confirm(
                        "¿Seguro que desea eliminar el tipo de iva?"
                      );
                      if (confirmDelete) {
                        console.log(combinadoCurrent);
                        delDato("tiposIvas", combinadoCurrent.iva.id);
                        setCombinado({ ...combinadoCurrent, iva: null });
                        loadTiposIvas();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="grupo">
                <label className="label">Precio</label>
                <input
                  className="text"
                  type="text"
                  name="precio"
                  value={combinadoCurrent?.precioVenta
                    ? combinadoCurrent?.precioVenta.toString().replace('.', ',')
                    : combinadoCurrent.precioVenta = 0}
                  onChange={(val) =>
                    setCombinado({
                      ...combinadoCurrent,
                      precioVenta: val.target.value,
                    })
                  }
                />

                <input
                  className="button"
                  type="submit"
                  value="Calcular"
                  id="calcular"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna">
              <div className="grupo">
                <label className="label">Añadir Articulo</label>
                <div className="grupoSelect">
                  <Select
                    className="select"
                    isSearchable={true}
                    options={articulosStore}
                    getOptionLabel={(articulo) => articulo.nombre}
                    getOptionValue={(articulo) => articulo.id}
                    onChange={(val) => {
                      var articulo = val;
                      articulo = { ...articulo, cantidad: 1 };
                      setArticulo(articulo);
                    }}
                  />
                </div>
                <input
                  className="button"
                  onClick={handleSubmit}
                  type="submit"
                  value="Agregar"
                />
              </div>
            </div>
            <div className="columna">
              <div className="grupo" style={{visibility:'hidden'}}>
                <label className="label">cantidad </label>
                <input
                  className="text"
                  type="text"
                  value={articulo?.cantidad?articulo?.cantidad:""}
                  name="cantidad"
                  id="cantidad"
                  onChange={(val) => {
                    const value = val.target.value.replace(/[^\d,]/g, "");
                    setArticulo({ ...articulo, cantidad: value });
                  }}
                />

                
              </div>
            </div>
          </div>
          <div className="datos">
            <div className="columna segunda">
              <table className="table">
                <thead>
                  <tr className="fila">
                    <th className="columna1">Articulo</th>
                    <th className="columna2" style={{visibility:'hidden'}}>Cantidad</th>
                    <th className="columna2">Precio</th>
                    <th className="columna2">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {combinadoCurrent.articulosSelected?.map((articulo, i) => {
                    return (
                      <tr className="fila" key={i}>
                        <td className="columna1">
                          <label>{articulo.nombre}</label>
                        </td>
                        <td className="columna2" style={{visibility:'hidden'}}>
                          <input
                            key={i}
                            onChange={(event) =>
                              handleChange(event, i, "cantidad")
                            }
                            type="text"
                            className="text"
                            value={articulo?.cantidad
                              ? articulo.cantidad.toString().replace('.', ',')
                              : (articulo.cantidad = 0)}
                          />
                        </td>
                        <td className="columna2">
                          <input
                            key={i}
                            onChange={(event) =>
                              handleChange(event, i, "precioVenta")
                            }
                            className="text"
                            type="text"
                            value={articulo?.precioVenta
                              ? articulo.precioVenta.toString().replace('.', ',')
                              : (articulo.precioVenta = 0)}
                          />
                        </td>
                        <td className="columna3">
                          <input
                            id={i}
                            className="button"
                            type="submit"
                            value="Eliminar"
                            onClick={() => handlerEliminar(i)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={modalIsOpenIva}
        onRequestClose={closeModalIva}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
        style={{
          marginTop: "25px",
          position:"absolute",
          right:"20px"
        }}
        size={"1.5em"}
  color="#CB950E"
        onClick={closeModalIva}
      />
        <Iva cerrar={closeModalIva} llamadoDesde={"combinado"} />
      </Modal>
      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
    </div>
  );
}
