import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/authContext";
import Cargando from "./cargando/cargando";

export function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();

  if (loading)
    return (
      <Cargando/>
    );

  if (!user) return <Navigate to="/login" />;

  return <Outlet />;
}
