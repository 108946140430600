import {StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
    page: {
      padding: 30,
      paddingTop:0,
      fontSize: 7,
    },
    container: {
      flex: 1,
      flexDirection: 'column',
      '@media max-width: 400': {
        flexDirection: 'column',
      },
    },
    image: {
      width:177,
      height:76.2,
      marginTop:23.8,
      marginBottom: 10,
    },
    encabezado: {
      width:"100%",
      flexDirection:"row"
    },
    encabezadoIzquierdo: {
      width:"45%",
      marginRight:20,
    },
    encabezadoDerecho: {
      width:"55%",
      border:1,
      borderColor:"#cea753",
      borderRadius:5,
      marginTop:50,
      marginBottom:40,
      marginRight:20,
      padding: 15,
    },
    contenedorCabeceraDatosDocumento: {
      backgroundColor:"#bf8d18",
      color:"white",
      flexDirection:"row",
      border:1,
      borderColor:"#8e7100",
      borderRadius:5,
      marginTop:10,
      paddingBottom:4,
      paddingTop:4,
      textAlign:"center",
    },
    cabeceraDatosDocumento: {
      flex:1,
      textAlign:"center",
      paddingLeft:7,
      paddingRight:7,
    },
    contenedorDatosDocumento: {
      flexDirection:"row",
    },
    datosDocumento: {
      flex:1,
      border:1,
      borderColor:"#c8c8c8",
      borderRadius:5,
      backgroundColor:"#dfdfdf",
      marginTop:3,
      marginRight:3,
      paddingLeft:10,
      paddingRight:10,
      paddingTop:4,
      paddingBottom:4,
      textAlign:"center",
    },
    contenedorCabeceraReferencia:{
      flexDirection:"row",
      border:1,
      borderColor:"#8e7100",
      backgroundColor:"#bf8d18",
      borderRadius:5,
      color:"white",
      marginTop:3,
      paddingTop:4,
      paddingBottom:4,
      paddingLeft:4,
    },
    cabeceraFormaPago: {
      width:"40%",
    },
    cabeceraTextoReferencia:{
      width:"60%",
    },
    contenedorDatosFormaDePago:{
      flexDirection:"row",
      borderRadius:5,
      marginTop:4,
    },
    datosReferencia: {
      backgroundColor:"#dfdfdf",
      border:1,
      borderColor:"#c8c8c8",
      borderRadius:5,
      paddingTop:4,
      paddingBottom:4,
      paddingLeft:5,
      width:"59%",
      marginRight:3,
    },
    datosFormaDePago:{
      backgroundColor:"#dfdfdf",
      border:1,
      borderColor:"#c8c8c8",
      borderRadius:5,
      paddingTop:4,
      paddingBottom:4,
      paddingLeft:5,
      width:"41%",
    },
    contenedorCabeceraArticulos:{
      flexDirection:"row",
      marginTop:6,
      backgroundColor:"#bf8d18",
      color:"white",
      paddingTop:4,
      paddingBottom:4,
      paddingLeft:15,
      paddingRight:15,
      border:1,
      borderColor:"#8e7100",
      borderRadius:5,
    },
    cabeceraArticulos:{
      width:"10%",
      textAlign:"center",
    },
    cabeceraDescripcion:{
      width:"40%",
      textAlign:"center",
    },
    contenedorArticulos:{
      flexDirection:"row",
    },
    datosArticulos:{
      display:"flex",
      flexDirection:"column",
      textAlign:"center",
      width:"10%",
      height:370,
      backgroundColor:"#efefef",
      borderRadius:5,
      border:1,
      borderColor:"#c8c8c8",
      marginTop:3,
      marginRight:3,
      paddingTop:10,
      position:"relative",
    },
    datosDescripcion:{
      flexDirection:"column",
      textAlign:"center",
      width:"40%",
      height:370,
      backgroundColor:"#efefef",
      borderRadius:5,
      border:1,
      borderColor:"#c8c8c8",
      marginTop:3,
      marginRight:3,
      paddingTop:10,
      position:"relative",
    },
    textoAbsoluto:{
      width:"100%",
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      position:"absolute",
    },
    textoFila:{
      width:"100%",
      display:"flex",
      flexDirection:"row",
    },
    textoArticulo:{
      width:"10%",
      top:10,
      marginTop:3,
      paddingHorizontal:5,
    },
    textoDescripcion:{
      width:"40%",
      top:10,
      marginTop:3,
      paddingHorizontal:20,
    },
    contenedorCabeceraPago:{
      width:"55%",
      backgroundColor:"#bf8d18",
      border:1,
      borderColor:"#8e7100",
      borderRadius:5,
      display:"flex",
      flexDirection:"row",
      color:"white",
      paddingVertical:4,
      marginTop:5,
      marginRight:5,
    },
    contenedorDatosPago:{
      width:"100%",
      height:60,
      flexDirection:"row",
    },
    contenedorDatosTipo:{
      display:"flex",
      border:1,
      borderColor:"#bfbfbf",
      borderRadius:5,
      flexDirection:"row",
      width:"30%",
      height:60,
      marginTop:5,
      marginRight:5,
      position:"relative",
    },
    contenedorDatosBase:{
      display:"flex",
      border:1,
      borderColor:"#bfbfbf",
      borderRadius:5,
      flexDirection:"row",
      width:"35%",
      height:60,
      marginTop:5,
      marginRight:5,
      position:"relative",
    },
    tipoFila:{
      width:"100%",
      display:"flex",
      flexDirection:"row",
    },
    cabeceraDatosTipo:{
      marginLeft:5,
      width:"30%",
    },
    cabeceraDatosPago:{
      marginLeft:5,
      width:"40%",
    },
    datosPago:{
      width:"35%",
      marginTop:10,
      marginLeft:5,
    },
    datosTipo:{
      width:"30%",
      marginTop:10,
      marginLeft:5,
    },
    datosAbsolutos:{
      width:"100%",
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      alignItems:"center",
      position:"absolute",
    },
    contenedorCabeceraObservaciones:{
      flexDirection:"row",
      color:"white",
      marginTop:5,
    },
    datosCabeceraObservaciones:{
      width:"70%",
      backgroundColor:"#bf8a17",
      border:1,
      borderColor:"#8e7100",
      borderRadius:5,
      marginRight:4,
      paddingTop:4,
      paddingBottom:4,
      paddingLeft:12,
    },
    datosCabeceraTotal:{
      width:"30%",
      backgroundColor:"#bf8a17",
      border:1,
      borderColor:"#8e7100",
      borderRadius:5,
      paddingTop:4,
      paddingBottom:4,
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-between",
    },
    datosObservaciones:{
      border:1,
      borderColor:"#bfbfbf",
      height:45,
      borderRadius:5,
      marginTop:5,
    },
    contenedorCabeceraBanco:{
      width:"45%",
      marginTop:5,
      border:1,
      borderColor:"#8e7100",
      borderRadius:5,
      backgroundColor:"#bf8a17",
      color:"white",
      paddingTop:4,
      paddingBottom:4,
      flexDirection:"row",
    },
    datosCabeceraVencimiento:{
      width:"30%",
      marginLeft:5,
    },
    datosCabeceraBanco:{
      width:"70%",
      marginLeft:5,
    },
    contenedorBanco:{
      flexDirection:"row",
      width:"44.6%",
    },
    datosVencimiento:{
      marginTop:5,
      marginRight:5,
      paddingTop:5,
      paddingLeft:5,
      width:"30%",
      height:60,
      border:1,
      borderRadius:5,
      borderColor:"#bfbfbf",
    },
    datosBanco:{
      marginTop:5,
      paddingTop:5,
      paddingLeft:5,
      width:"70%",
      height:60,
      border:1,
      borderRadius:5,
      borderColor:"#bfbfbf",
    },
  });
  export default  styles;