import React, { useEffect, useState } from "react";
import { useStore } from "../../store/appStore";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const CardEventos = (props) => {
  const [datos, setDatos] = useState();
  const navigate = useNavigate();
  const { clienteCurrent, loadEventosFiltrados } = useStore();

  useEffect(() => {
    loadEventosFiltrados(clienteCurrent.cifDni)
      .then((presupuestos) => {
        setDatos(presupuestos);
      })
      .catch((error) => {
        console.error("Error al cargar presupuestos:", error);
      });
  }, []);
  if (datos)
    return (
      <div className="cardPresupuestos">
        <div className="divCardPresupuestos">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <th style={{ textAlign: "left" }}>Fecha del evento</th>
                <th style={{ textAlign: "left" }}>Nombre del evento</th>
              </tr>
            </thead>
            <tbody>
              {datos.map((dato, index) => (
                <tr
                  key={dato.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "#f2f2f2",
                  }}
                  onDoubleClick={() => navigate(`/eventos/listado/${dato.id}`)}
                >
                  <td>{moment(dato?.fecha?.toDate()).format("DD/MM/YYYY")}</td>
                  <td>{dato?.nombre}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
};
