import { useState } from "react";
import { showToast } from "../../utils/utils";
import { useAuth } from "../../context/authContext";
import { useEffect } from "react";
import {
  AiOutlineCloseCircle,
  AiOutlineEye,
  AiOutlineFileAdd,
} from "react-icons/ai";
import Modal from "react-modal";
import Cargando from "../cargando/cargando";

const Documentos = ({ enable, idEvento, cargando, setCargando }) => {
  const { uploadFile, listFiles, deleteFile } = useAuth();
  const [documentoPdf, setDocumentoPdf] = useState();
  const [esDocumentoPdf, setesDocumentoPdf] = useState(false);
  const [listado, setListado] = useState("");
  const [nombre, setNombre] = useState("");
  const [modalIsOpenDoc, setIsOpenDoc] = useState(false);
  const [fileAbrir, setFileAbrir] = useState("parte");
  function closeModalDoc() {
    setIsOpenDoc(false);
  }

  async function datos() {
    const listado = await listFiles(idEvento);
    setListado(listado);
  }
  useEffect(() => {
    datos();
  }, [cargando]);

  useEffect(() => {
    console.log("listado", listado);
  }, [listado]);

  return (
    <div className={idEvento ? (enable ? "enabled" : "disabled") : "enabled"}>
      <div style={{border:'none'}} className="datos columna">
        <div className="grupo" style={{width:'100%', display:'flex'}}>
          <label className="label" style={{ flex: 2 }}>
            Nombre del Documento
          </label>
          <input
            type="text"
            style={{ flex: 3, marginRight: "10px" }}
            className="text"
            placeholder="Nombre del Documento"
            name="nombre"
            id="nombre"
            onChange={(val) => setNombre(val.target.value)}
            value={nombre}
          />

          <input
            type="file"
            style={{ flex: 7 }}
            accept=".pdf"
            name="documentos"
            id="documentos"
            onChange={(e) => {
              if (
                e.target.files[0].name.substring(
                  e.target.files[0].name.length - 4
                ) === ".pdf"
              ) {
                setDocumentoPdf(e.target.files[0]);
                setesDocumentoPdf(true);
              } else {
                showToast("error", "Solo se pueden subir documentos pdf");
              }
            }}
          />
          <AiOutlineFileAdd
            onClick={async () => {
              let nombreFichero;
              setCargando(true);
              if (nombre === "" || nombre === null) {
                nombreFichero = documentoPdf.name.substring(
                  0,
                  documentoPdf.name.length - 4
                );
              } else {
                nombreFichero = nombre;
              }

              if (esDocumentoPdf && nombreFichero != "" && idEvento) {
                await uploadFile(
                  documentoPdf,
                  `documentos/${idEvento}/${nombreFichero}.pdf`
                ).then(() => {
                  showToast("success", "Fichero subido");
                  setCargando(false);
                  setNombre("");
                });
              } else {
                showToast("error", "Error al subir");
                setCargando(false);
              }
            }}
            size={"1.5em"}
            color="#CB950E"
          />
        </div>
      </div>
      {listado?.length > 0 ? 
        <div className="datos">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              margin: "20px",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  display: "flex",
                  marginRight: "25px",
                }}
              >
                <th style={{ textAlign: "left", flex: 12 }}>Documento</th>
                <th style={{ textAlign: "left", flex: 1 }}>Ver</th>
                <th style={{ textAlign: "left", flex: 1 }}>Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {listado
                ? listado?.map((dato, index) => (
                    <tr
                      key={dato.id}
                      style={{
                        display: "flex",
                        marginRight: "25px",
                        backgroundColor: index % 2 === 0 ? "white" : "#f2f2f2",
                      }}
                    >
                      <td style={{ flex: 12 }}>{dato.name}</td>
                      <td style={{ flex: 1 }}>
                        {
                          <AiOutlineEye
                            size={"1.5em"}
                            color="#CB950E"
                            style={{ pointerEvents: "all" }}
                            onClick={async () => {
                              setCargando(true);
                              setFileAbrir(dato.url);
                              setIsOpenDoc(true);
                            }}
                          />
                        }
                      </td>
                      <td style={{ flex: 1 }}>
                        {
                          <AiOutlineCloseCircle
                            size={"1.5em"}
                            color="#CB950E"
                            onClick={async () => {
                              const confirmDelete = window.confirm(
                                `¿Seguro que desea eliminar el documento?`
                              );
                              if (confirmDelete) {
                                setCargando(true);
                                await deleteFile(dato.url);
                                setCargando(false);
                              }
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
       : 
        null
      }
      <Modal
        isOpen={modalIsOpenDoc}
        onRequestClose={closeModalDoc}
        style={{
          overlay: {
            border: "8px",
          },
          content: {
            border: "0px",
            background: 0,
          },
        }}
      >
        <AiOutlineCloseCircle
          style={{
            marginTop: "30px",

            position: "absolute",
            right: "30px",
          }}
          size={"1.5em"}
          color="#CB950E"
          onClick={closeModalDoc}
        />
        <object
          data={fileAbrir}
          style={{ height: "100%", width: "100%" }}
          onLoad={() => setCargando(false)}
          alt="mensaje"
        >
          Fichero PDF no cargado correctamente
        </object>
      </Modal>

      <Modal isOpen={cargando}>
        <Cargando />
      </Modal>
    </div>
  );
};

export default Documentos;
